import { TextField, IconButton, TextFieldProps } from '@mui/material';
import { useDebounce } from 'common/hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { MagnifyingGlass } from '@phosphor-icons/react';

export interface SearchFieldProps {
  value: string;
  setValue: StateSetter<string>;
  debounce?: number;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  suppressMarginBottom?: boolean;
}

export function SearchField({
  value,
  setValue,
  debounce = 500,
  disabled,
  textFieldProps,
  suppressMarginBottom,
}: SearchFieldProps) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);

  const debouncedValue = useDebounce(inputValue, debounce);

  useEffect(() => {
    setValue(debouncedValue?.trim());
  }, [debouncedValue, setValue]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  return (
    <TextField
      size="small"
      margin="normal"
      label={t('common.place_holders.search')}
      id="standard-bare"
      sx={{
        backgroundColor: themes?.default?.accordionWhiteBg,
        marginBottom: !suppressMarginBottom ? '0 !important' : undefined,
        '& .MuiInputBase-root': {
          height: '24px',
          fontSize: '0.85rem',
          fontWeight: 400,
        },
        ...textFieldProps?.sx,
      }}
      onChange={onChange}
      value={inputValue}
      InputProps={{
        startAdornment: (
          <IconButton
            sx={{ marginLeft: '-10px', padding: '4px' }}
            disableRipple
            disabled={disabled || false}
          >
            <MagnifyingGlass size={themes?.default?.iconSize} />
          </IconButton>
        ),
      }}
      disabled={disabled ?? false}
      {...textFieldProps}
    />
  );
}

export default SearchField;
