export function getProcessedWarehousesFunction(warehousesData) {
  return warehousesData?.map((warehouse) => {
    return {
      id: warehouse._id,
      name: warehouse?.name,
      initials: {
        cellData: {
          label: warehouse?.shortName,
          color: warehouse?.color,
        },
      },
      location: {
        cellData: {
          location: warehouse.location,
        },
      },
      capacity: warehouse.capacity,
      manager: warehouse.manager,
      contact: warehouse.phone,
      operating_hours: {
        cellData: {
          openingHours: warehouse.openingHours,
        },
      },
      status: warehouse.status,
      __actions: null,
    };
  });
}
