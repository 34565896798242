/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { TableViewPopover } from '../../../../styles/components/muitable/TableViewPopover';
import { GRID, MAP, LIST, CARD } from '../../TableComponent/constants';
import { themes } from '../../../../styles/theme/themes';
import { MapPin, Table, SquaresFour } from '@phosphor-icons/react';
import { Table as TableIcon } from '@phosphor-icons/react/dist/csr/Table';
import { useTranslation } from 'react-i18next';
import { IGridContext } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';

interface ViewType {
  type: string;
  icon: JSX.Element | null;
  label: string;
}

function ManagedViewToggler() {
  const { t } = useTranslation();
  const {
    viewMode,
    mapComponent,
    listComponent,
    cardComponent,
    updateDataWithFunction,
  } = useManagedContext<IGridContext>('grid');

  const views = useMemo(() => {
    const result: ViewType[] = [
      {
        type: GRID,
        icon: <TableIcon size={16} color={themes?.default?.iconColor} />,
        label: t('grid'),
      },
    ];
    if (!!mapComponent) {
      result?.push({
        type: MAP,
        icon: <MapPin size={16} color={themes?.default?.iconColor} />,
        label: t('map'),
      });
    }
    if (!!listComponent) {
      result?.push({
        type: LIST,
        icon: <Table size={16} color={themes?.default?.iconColor} />,
        label: t('view.list'),
      });
    }
    if (!!cardComponent) {
      result?.push({
        type: CARD,
        icon: <SquaresFour size={16} color={themes?.default?.iconColor} />,
        label: t('view.card'),
      });
    }
    return result;
  }, [mapComponent, listComponent, cardComponent]);

  return (
    <TableViewPopover
      views={views}
      tableType={viewMode}
      handleAction={(e, action) => {
        updateDataWithFunction((prev) => {
          // @ts-ignore
          prev.viewMode = action;
          prev.queryParams = {
            page: 1,
            order: '',
            orderBy: '',
            status: '',
            filters: {},
            search: '',
            rowsPerPage: action === MAP ? 200 : 30,
          };
        });
      }}
    />
  );
}

export default ManagedViewToggler;
