import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { themes } from 'styles/theme/themes';
import { Icon } from '../Icon';
import { DotsThree } from '@phosphor-icons/react';
import { StateSetter } from 'types';
interface ActionsProps {
  seperator?: boolean;
  action: string;
  typeNotEdit?: any;
  element?: JSX.Element | null;
  onClick?: Function;
}
interface Props {
  handleAction?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string,
    id: string | number,
  ) => void;
  id: string | number;
  anchorOriginVertical?: 'center' | 'top' | 'bottom' | number;
  anchorOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginVertical?: 'center' | 'top' | 'bottom' | number;
  allowTouchEvents?: boolean;
  actions: ActionsProps[];
  iconAction?: any;
  label?: string;
  handleActionclick?: Function;
  styling?: any;
  iconSize?: number;
  hover?: any;
  setMenuOpen?: StateSetter<any>;
}
export const ActionPopover: React.FC<Props> = ({
  iconAction,
  id,
  allowTouchEvents,
  handleAction,
  actions,
  label,
  handleActionclick,
  anchorOriginVertical,
  anchorOriginHorizontal,
  transformOriginHorizontal,
  transformOriginVertical,
  styling,
  iconSize,
  hover,
  setMenuOpen,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const handleClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    if (event.type === 'touchend' && !allowTouchEvents) return;

    setAnchorEl(event.currentTarget);
    if (setMenuOpen) setMenuOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (setMenuOpen) setMenuOpen(false);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        style={{
          ...styling,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: open && label ? themes?.default?.solitude : '',
        }}
        onMouseEnter={(e) => {
          if (hover) {
            handleClick(e);
          }
        }}
        onClick={(e) => {
          if (!hover) {
            handleClick(e);
          }
        }}
      >
        <PopoverEl redStatus={themes.default.chipRedBg}>{label}</PopoverEl>
        <IconWrapper>
          {iconAction || (
            <Icon
              icon={<DotsThree />}
              weight="bold"
              size={iconSize || themes.default?.dotsIconSize}
            />
          )}
        </IconWrapper>
      </div>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorOriginVertical ?? 'bottom',
          horizontal: anchorOriginHorizontal ?? 'right',
        }}
        transformOrigin={{
          vertical: transformOriginVertical ?? 'top',
          horizontal: transformOriginHorizontal ?? 'right',
        }}
        sx={{ overflow: 'hidden', borderRadius: '8px' }}
      >
        <Typography
          sx={{ padding: '6px 0' }}
          padding="12px 12px 12px 16px"
          minWidth={123}
        >
          <div
            onMouseLeave={() => {
              if (hover) {
                handleClose();
              }
            }}
          >
            {actions.map((action, index) => {
              if (action?.element) {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      action.onClick && action.onClick();
                    }}
                  >
                    {action?.typeNotEdit === false ? null : (
                      <PopOverElement
                        primaryActiveColor={themes?.default?.primary}
                        className={
                          action?.seperator
                            ? 'seperator'
                            : action.action === 'delete'
                              ? 'delete'
                              : ''
                        }
                        style={{ height: action?.seperator ? 'auto' : '' }}
                        onClick={(e) => {
                          if (handleAction) handleAction(e, action.action, id);
                          if (handleActionclick)
                            handleActionclick(e, handleClose, action.action);
                          // if (action.functionCall) action.functionCall(id);

                          setAnchorEl(null);
                        }}
                      >
                        <PopOverElWrapper>{action.element}</PopOverElWrapper>
                      </PopOverElement>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </Typography>
      </Popover>
    </>
  );
};
export const PopOverElement = styled.div<{ primaryActiveColor: string }>`
  min-width: 195px;
  display: flex;
  gap: 14px;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: ${(props) =>
      props?.className === 'seperator'
        ? ''
        : props?.className === 'delete'
          ? `${themes?.default?.chipRedFont}80`
          : themes?.default?.grey};
    & svg {
      color: ${(props) => props.primaryActiveColor};
    }
  }
  padding: ${(props) => (props?.className === 'seperator' ? '0 8px' : '')};
`;

export const PopoverEl = styled.div<{ redStatus: string }>`
  font-size: 0.8125rem;
  letter-spacing: 0.17000000178813934px;

  color: ${themes.default.textColorPrimary};

  .seperator {
    height: 0.5px;
    background: ${themes?.default?.gainsboro2};
    width: 100%;
  }
  .delete {
    color: ${(props) => props.redStatus};
  }
  .css-cl1s8s-MuiButtonBase-root-MuiButton-root:hover {
    background-color: inherit;
  }
  .popover-item {
    padding-left: ${(props) => props.theme?.popoverLabelPadding};
    font-size: 0.85rem;
    display: flex;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;
