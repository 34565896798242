import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
  Stack,
  InputAdornment,
} from '@mui/material';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useEffect, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  useGetCustomersBasicQuery,
  useLazyGetCustomerQuery,
} from 'common/services/customerApi';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { TemplatesCards } from 'app/components/TemplatesCards';
import { filterItems } from 'functions/searchItemInList';
import { useLazyGetTemplatesOrderQuery } from 'common/services/templates';
import {
  useCreateOrderMutation,
  useLazyGetOrdersQuery,
  useUpdateCustomerOrderMutation,
} from 'common/services/orderApi';
import { ToastMessage } from 'app/components/Toast';
import { useNavigate } from 'react-router-dom';
import { PickersDay } from '@mui/x-date-pickers';
import { useGetOrdersQuery } from 'common/services/orderApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';
import { usePatchCallStatusMutation } from 'common/services/calls';
import { CustomerStatus } from 'common/types/Customer';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { ChipWithRadio } from 'app/pages/Customers/components/ChipWithRadio';
import { UpdateOrderFormData } from 'app/pages/Ordoria/SalesOrderDetail';
import { themes } from 'styles/theme/themes';
import { CalendarBlank, User, Info } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useDeleteProductsFromCartMutation } from 'common/services/cartApi';
import { generateParamsString } from 'app/pages/Ordoria/cart/components/DeleteBtn';
import { useDispatch } from 'react-redux';
import { deleteProductsFromSelectedObject } from 'common/store/cart';

interface CreateOrderDrawerSalesProps {
  open: boolean;
  closeDrawer: () => void;
  defaultDeliveryDate?: any;
  selectedCustomer?: any;
  selectedProducts?: any[];
  type?: string;
  callId?: string;
  statusOrderedCallId?: string;
}

export function CreateOrderDrawerSales({
  open = false,
  closeDrawer,
  defaultDeliveryDate,
  selectedCustomer,
  selectedProducts,
  type,
  callId,
  statusOrderedCallId,
}: CreateOrderDrawerSalesProps) {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>([]);
  const [internalOrderCode, setInternalOrderCode] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const [orderDeck, setOrderDeck] = useState<Array<any>>([
    { _id: '', deliveryDays: [] },
  ]);
  const [isNotTypicalDate, setIsNotTypicalDate] = useState<boolean>(false);
  const [selectedIdCustomer, setSelectedIdCustomer] = useState<any>(
    selectedCustomer || '',
  );
  const [radioValue, setRadioValue] = useState<string>('delivery');
  const [dateValue, setDateValue] = useState<any>(defaultDeliveryDate);

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedIdCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const navigate = useNavigate();

  const [triggergetCustomer, { data }] =
    useLazyGetCustomerQuery(selectedIdCustomer);

  const [customersSearch, setCustomersSearch] = useState('');

  const debouncedCustomersSearch = useDebounce(customersSearch, 500);

  const commonCustomersQueryParams = {
    limit: 10000,
    ordoria: true,
    status: Object.values(CustomerStatus).filter((s) => {
      return s !== CustomerStatus.ARCHIVED;
    }),
  };

  const customersQueryParams = useQueryParams({
    ...commonCustomersQueryParams,
    text: debouncedCustomersSearch,
  });

  const {
    data: customersData,
    isLoading: isLoadingCustomers,
    isFetching: isFetchingCustomers,
  } = useGetCustomersBasicQuery(customersQueryParams.slice(1), {
    skip: !open,
  });
  const decks = data?.decks;

  const dispatch = useDispatch();

  const [deleteProductsFromCart] = useDeleteProductsFromCartMutation();

  const [dataCustomers, setDataCustomers] = useState<any>(null);

  useEffect(() => {
    if (!customersData) return;
    setDataCustomers(null);
    setTimeout(() => {
      setDataCustomers(customersData);
    }, 200);
  }, [customersData]);

  const decksId = decks?.map((deck) => deck._id) || [];

  const handleDateValueChange = (newValue) => {
    setDateValue(newValue);
  };

  const handleSearchTextChange = (e) => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleTemplateClick = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const [triggerGetOrder] = useLazyGetOrdersQuery();
  const [orderOnSameDate, setOrderOnSameDate] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedBranch?._id) return;
    const formattedDateValue = moment(dateValue?.$d).format('YYYY/MM/DD');

    const orderQuery = `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&status=${[
      'pending',
      'pending-updates',
      'pending-customer',
      'validated',
      'confirmed',
      'prepared',
    ]}&customerDeck=${selectedBranch?._id}`;

    if (selectedIdCustomer && dateValue && selectedBranch?._id) {
      triggerGetOrder(orderQuery).then((res) => {
        if (res?.data.docs.length > 0) {
          setOrderOnSameDate(true);
        } else {
          setOrderOnSameDate(false);
        }
      });
    }
  }, [dateValue, selectedBranch]);

  useEffect(() => {
    if (selectedBranch?._id?.length && dateValue) {
      const weekDayName = new Date(dateValue).toLocaleDateString('en-US', {
        weekday: 'long',
      });

      const deliveryDaysLower = selectedBranch.deliveryDays
        ? selectedBranch.deliveryDays.map((e: string) => e.toLowerCase())
        : [];

      setIsNotTypicalDate(
        !deliveryDaysLower.includes(weekDayName.toLowerCase()),
      );
    } else {
      setIsNotTypicalDate(false);
    }
  }, [orderDeck, dateValue, selectedBranch]);

  useEffect(() => {
    setIsNotTypicalDate(false);
    setSelectedBranch(null);
  }, [selectedIdCustomer]);

  useEffect(() => {
    if (decks?.length === 1) {
      setSelectedBranch(decks[0]);
    }
  }, [decks]);
  const [trigger, { data: templates }] = useLazyGetTemplatesOrderQuery();

  const commonTemplateQueryParams = {
    _customer: selectedIdCustomer || '',
  };
  const templateFetchQueryParams = useQueryParams({
    ...commonTemplateQueryParams,
  });

  useEffect(() => {
    if (selectedIdCustomer) {
      trigger(templateFetchQueryParams);
      triggergetCustomer(selectedIdCustomer);
    }
  }, [selectedIdCustomer]);

  const filteredTemplates = useMemo(() => {
    return filterItems(templates?.templates, searchText.trim(), ['name']);
  }, [templates, searchText]);

  const { t } = useTranslation();

  const [, setSnackPack] = useState<readonly ToastMessage[]>([]);
  const toast = (message: string, severity: ToastMessage['severity']) => {
    setSnackPack((prev) => [
      ...prev,
      { message, key: new Date().getTime(), severity },
    ]);
  };

  const [createOrderBtnDisabled, setCreateOrderBtnDisabled] =
    useState<boolean>(true);

  const handlecreateOrderBtnDisable = () => {
    if (
      dateValue === null ||
      radioValue === '' ||
      !selectedBranch ||
      selectedIdCustomer === ''
    ) {
      setCreateOrderBtnDisabled(true);
    } else {
      setCreateOrderBtnDisabled(false);
    }
  };

  useEffect(() => {
    handlecreateOrderBtnDisable();
  }, [dateValue, radioValue, selectedBranch]);

  const [createOrder] = useCreateOrderMutation();
  const [updateOrder] = useUpdateCustomerOrderMutation();
  const [changeStatus] = usePatchCallStatusMutation();

  const handleCreateOrder = async () => {
    if (
      dateValue === null ||
      radioValue === '' ||
      selectedIdCustomer === '' ||
      !selectedBranch
    ) {
      setOpenAlert(true);
    } else {
      try {
        const result = await createOrder({
          deliveryDate: dateValue,
          type: radioValue,
          deck: selectedBranch._id,
          templateId: selectedTemplate === null ? null : selectedTemplate,
          products: [],
          linkedDecks: [selectedBranch._id],
          customerId: selectedIdCustomer,
          internalOrderCode: internalOrderCode,
        }).unwrap();

        if (selectedProducts) {
          const formData: UpdateOrderFormData = {
            products: selectedProducts,
            discount: 0,
            customerId: selectedIdCustomer,
          };

          await updateOrder({
            id: result.createdOrder.id,
            formData,
          });

          const selectedProductIds = selectedProducts.map(
            (prod) => prod._product,
          );

          const paramsString = generateParamsString(selectedProductIds);
          dispatch(deleteProductsFromSelectedObject(selectedProductIds));

          await deleteProductsFromCart(paramsString);
        }

        toast(t('Added'), 'success');

        if (type === 'callplan') {
          navigate(`/ordoria/orders/${result.createdOrder.id}?type=callplan`);
          patchStatusCall(result.createdOrder.id);
        } else {
          navigate(`/ordoria/orders/${result.createdOrder.id}`);
        }
      } catch (err) {
        console.error(err);
        toast(t('Something went wrong!'), 'error');
      }
    }
  };

  /**
   * Patch status call with orderId for Ordered status
   * @param orderId - orderId to patch status call
   */
  function patchStatusCall(orderId: string) {
    if (callId && statusOrderedCallId) {
      changeStatus({
        id: callId,
        orderId: orderId,
        statusId: statusOrderedCallId,
        recallBeginTime: null,
        recallEndTime: null,
      }).unwrap();
    }
  }

  ///Multiples Branches
  const [triggerActiveOrder] = useLazyGetOrdersQuery();

  const [orderStatusArray, setOrderStatusArray] = useState<
    { branchId: string; isActive: boolean }[]
  >([]);

  const filters = {
    end: null,
  };
  const debouncedFilters = useDebounce(filters, 1000);
  const commonQueryParams = {
    end: debouncedFilters.end || '',
  };
  const fetchQueryParams = useQueryParams({
    ...commonQueryParams,
  });
  const order_ = useGetOrders(fetchQueryParams, !open);
  const [deliveryDates, updateDeliveryDates] = useState<string[]>([]);
  const orderDocs = order_?.docs || [];

  useEffect(() => {
    Promise.all(
      orderDocs.map(async (orderPromise) => {
        const order = await orderPromise;
        return order?.deliveryDate;
      }),
    )
      .then((newDeliveryDates) => {
        const reformattedDates = newDeliveryDates.map((date) =>
          date.substr(0, 10),
        );
        updateDeliveryDates((prevDeliveryDates) => [
          ...prevDeliveryDates,
          ...reformattedDates,
        ]);
      })
      .catch((error) => {
        console.error('Error fetching delivery dates:', error);
      });
  }, [order_, dateValue]);

  ///Has active order
  useEffect(() => {
    const formattedDate = moment(dateValue?.$d).format('YYYY/MM/DD');

    if (selectedIdCustomer && dateValue) {
      const updatedOrderStatusArray: { branchId: string; isActive: boolean }[] =
        [];

      decksId.forEach((id) => {
        if (!id) return;
        triggerActiveOrder(
          `?&beginAt=${formattedDate}&endAt=${formattedDate}&status=${[
            'pending',
            'pending-updates',
            'pending-customer',
            'validated',
            'confirmed',
            'prepared',
          ]}&customerDeck=${id}`,
        )
          .then((res) => {
            updatedOrderStatusArray.push({
              branchId: id,
              isActive: res.data.docs.length > 0,
            });

            setOrderStatusArray(updatedOrderStatusArray);
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
          });
      });
    }
  }, [dateValue, selectedIdCustomer]);

  const customersFilterOptions = (options, state) => {
    const inputValueLowerCase = state?.inputValue?.toLowerCase();
    return options?.filter(
      (option) =>
        option?.legalName?.toLowerCase()?.includes(inputValueLowerCase) ||
        option?.code?.toLowerCase()?.includes(inputValueLowerCase),
    );
  };

  const branchesFilterOptions = (options, state) => {
    const inputValueLowerCase = state?.inputValue?.toLowerCase();
    return options?.filter(
      (option) =>
        option?.location.shortAddress
          ?.toLowerCase()
          ?.includes(inputValueLowerCase) ||
        option?.location.name?.toLowerCase()?.includes(inputValueLowerCase) ||
        option?.code?.toLowerCase()?.includes(inputValueLowerCase),
    );
  };

  return (
    <DrawerComponent
      open={open}
      handleClose={closeDrawer}
      title={t('orders.create_orders')}
    >
      <Wrapper>
        {openAlert ? (
          <Snackbar
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            autoHideDuration={9000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              boxShadow:
                '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
            }}
          >
            <Alert
              severity="warning"
              sx={{ width: '506px' }}
              onClose={() => setOpenAlert(false)}
            >
              <AlertTitle>{t('order.typicalDate.title')}</AlertTitle>
              {t('order.typicalDate.subtitle')}
            </Alert>
          </Snackbar>
        ) : null}

        <ContentWrapper>
          <div className="orderType" style={{ paddingRight: '24px' }}>
            <div className="orderType">
              <Typography fontWeight="500" fontSize="0.9375rem">
                {t('clients.orderDetails.orderType')}
              </Typography>
              <FormControl sx={{ margin: '0 !important' }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  defaultValue="delivery"
                  onChange={handleChange}
                >
                  <Grid
                    container
                    sx={{
                      rowGap: '12px',
                      padding: '8px 0px',
                    }}
                  >
                    <FormControlLabel
                      value="delivery"
                      label=""
                      control={
                        <ChipWithRadio
                          value="delivery"
                          label={t('common.delivery')}
                          selectedValue={radioValue}
                        />
                      }
                    />
                    <FormControlLabel
                      value="collection"
                      label=""
                      control={
                        <ChipWithRadio
                          value="collection"
                          label={t('roundtrips.tooltip.collection')}
                          selectedValue={radioValue}
                        />
                      }
                    />
                    <FormControlLabel
                      value="express"
                      label=""
                      control={
                        <ChipWithRadio
                          value="express"
                          label={t('common.express')}
                          selectedValue={radioValue}
                        />
                      }
                    />
                    <FormControlLabel
                      value="repair"
                      label=""
                      control={
                        <ChipWithRadio
                          value="repair"
                          label={t('repair')}
                          selectedValue={radioValue}
                        />
                      }
                      // sx={{ ml: '8px' }}
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <Stack className="clientAndBranch" gap="8px" paddingRight="24px">
            <Typography
              fontSize="0.9375rem"
              lineHeight="1.5rem"
              fontWeight="500"
              letterSpacing="0.15px"
              color={themes.default.textBlack}
            >
              {t('info-main-details')}
            </Typography>
            <Grid container flexDirection="column" gap="12px">
              <InputsContainer>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    options={
                      !dataCustomers?.docs
                        ? [{ legalName: 'Loading...', id: 0 }]
                        : dataCustomers?.docs
                    }
                    value={data?.legalName ? data : null}
                    onChange={(
                      e,
                      v: { _id: string; legalName: string } | null,
                    ) => {
                      setSelectedIdCustomer(v?._id);
                    }}
                    getOptionLabel={(option) => option.legalName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          setCustomersSearch(e?.target?.value);
                        }}
                        size="small"
                        label={t('common.customer')}
                      />
                    )}
                    filterOptions={customersFilterOptions}
                    renderOption={(props, option) => (
                      <MenuItem
                        component="li"
                        sx={{}}
                        {...props}
                        key={option?._id}
                      >
                        {option?.legalName}
                      </MenuItem>
                    )}
                    noOptionsText={
                      <>
                        {isLoadingCustomers || isFetchingCustomers ? (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <CircularProgress size={20} color="primary" />
                          </div>
                        ) : (
                          t('no_options')
                        )}
                      </>
                    }
                    sx={{
                      '.MuiFormControl-root': {
                        margin: '0 !important',
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    options={decks || []}
                    value={selectedBranch}
                    onChange={(event, value) => {
                      setSelectedBranch(value);
                      setOrderDeck(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('orders.branch')}
                        size="small"
                      />
                    )}
                    filterOptions={branchesFilterOptions}
                    getOptionLabel={(option) =>
                      option?.location.shortAddress || option?.location.name
                    }
                    renderOption={(props, option) => (
                      <MenuItem
                        // key={option._id}
                        value={option}
                        disabled={orderStatusArray.some(
                          (status) =>
                            status.branchId === option._id && status.isActive,
                        )}
                        {...props}
                      >
                        <Typography
                          fontSize="1rem"
                          fontWeight="400"
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {option?.location.shortAddress ||
                            option?.location.name}
                        </Typography>
                        {selectedBranch?._id === option._id &&
                        !orderStatusArray.some(
                          (status) =>
                            status.branchId === option._id && status.isActive,
                        ) ? (
                          <IconButton
                            key={selectedBranch?._id}
                            sx={{
                              p: 0,
                              ml: 'auto',
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : null}
                        {orderStatusArray.some(
                          (status) =>
                            status.branchId === option._id && status.isActive,
                        ) && (
                          <Typography
                            fontSize="0.75rem"
                            fontWeight={400}
                            sx={{
                              color: '#E65100',
                              fontStyle: 'italic',
                              ml: 'auto',
                            }}
                          >
                            Has an active order
                          </Typography>
                        )}
                      </MenuItem>
                    )}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      '.MuiFormControl-root': {
                        margin: '0 !important',
                      },
                    }}
                    renderTags={(values) => {
                      return values.map((value, index) => (
                        <Typography
                          key={index}
                          fontSize="1rem"
                          lineHeight="1.5rem"
                          letterSpacing="0.0094rem"
                          paddingLeft="6px"
                          sx={{
                            flexWrap: 'nowrap !important',
                            textWrap: 'nowrap !important',
                          }}
                        >
                          {value?.location.shortAddress || value?.location.name}
                          ,
                        </Typography>
                      ));
                    }}
                  />
                </FormControl>
              </InputsContainer>
              <InputsContainer>
                <LocalizationProvider
                  sx={{
                    marginBottom: '0px !important',
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    label={t('clients.orderDetails.deliveryDate')}
                    inputFormat="MMM DD, YYYY"
                    minDate={moment().format('YYYY/MM/DD')}
                    components={{
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    value={dateValue}
                    open={datePickerOpen}
                    onClose={() => setDatePickerOpen(false)}
                    onOpen={() => setDatePickerOpen(true)}
                    onChange={handleDateValueChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon icon={<CalendarBlank />} size={24} />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t('clients.orderDetails.deliveryDate')}
                        onClick={() => setDatePickerOpen(true)}
                        focused={datePickerOpen}
                        size="small"
                        sx={{
                          flex: '1',
                        }}
                        {...params}
                      />
                    )}
                    renderDay={(date, _, DayComponentProps) => {
                      let date_: any;
                      const selectedDate = dateValue?.$d?.getTime();
                      date_ = date;
                      const formattedDate_ = `${date_?.$y}-${(date_?.$M + 1)
                        .toString()
                        .padStart(2, '0')}-${date_?.$D
                        .toString()
                        .padStart(2, '0')}`;
                      date_ = date_?.$d?.getTime();
                      const datesAreMatching = date_ === selectedDate;
                      const isDateInDeliveryDates =
                        deliveryDates.includes(formattedDate_);

                      return (
                        <CalendarDayContainer>
                          <PickersDay
                            {...DayComponentProps}
                            outsideCurrentMonth={false}
                            selected={datesAreMatching}
                          />
                          {isDateInDeliveryDates ? (
                            <CalenderDayMark
                              color={datesAreMatching ? 'white' : ''}
                            />
                          ) : (
                            ''
                          )}
                        </CalendarDayContainer>
                      );
                    }}
                  />
                </LocalizationProvider>
                <TextField
                  label={t('internal_order_code')}
                  size="small"
                  value={internalOrderCode}
                  onChange={(e) => setInternalOrderCode(e.target.value || '')}
                  sx={{ flex: '1' }}
                />
              </InputsContainer>
              <Stack flexDirection="row" gap="10px" flexWrap="wrap">
                <SalesPersonBadge>
                  <Icon icon={<User />} size={14} />
                  <Typography
                    fontSize="0.75rem"
                    lineHeight="1.5rem"
                    letterSpacing="0.0094rem"
                    fontWeight="300"
                    color={themes.default.textBlack}
                  >
                    {t('sale_person')}{' '}
                    <span style={{ fontWeight: '500' }}>
                      {data?.cache?._salesman?.fullName || ''}
                    </span>
                  </Typography>
                </SalesPersonBadge>
                {isNotTypicalDate && !orderOnSameDate && (
                  <NotTypicalDateBadge>
                    <Icon
                      icon={<Info />}
                      size={14}
                      color={themes.default.warningMain}
                    />
                    <Typography
                      fontSize="0.75rem"
                      lineHeight="1.5rem"
                      fontWeight="500"
                      letterSpacing="0.025rem"
                      color={themes.default.warningMain}
                    >
                      {t('orders.create_orders.not_typical_date_client')}
                    </Typography>
                  </NotTypicalDateBadge>
                )}
                {orderOnSameDate && (
                  <NotTypicalDateBadge>
                    <Icon
                      icon={<Info />}
                      size={14}
                      color={themes.default.warningMain}
                    />
                    <Typography
                      fontSize="0.75rem"
                      lineHeight="1.5rem"
                      fontWeight="500"
                      letterSpacing="0.025rem"
                      color={themes.default.warningMain}
                    >
                      {t('orders.create_orders.same_date_orders')}
                    </Typography>
                  </NotTypicalDateBadge>
                )}
              </Stack>
            </Grid>
          </Stack>

          <div className="template">
            <Grid container alignItems="center" paddingRight="24px">
              <Typography
                fontSize="0.9375rem"
                fontWeight="500"
                sx={{
                  mb: 2,
                }}
              >
                {t('orders.title.chooseTemplate')}
              </Typography>
              <TextField
                size="small"
                label={t('orders.search_templates')}
                value={searchText}
                onChange={handleSearchTextChange}
                sx={{
                  width: '276px',
                  ml: 'auto',
                }}
                InputProps={{
                  startAdornment: <SearchIcon color="action" />,
                }}
              />
            </Grid>
            <Grid
              container
              paddingRight="24px"
              sx={{
                overflow: 'auto',
                maxHeight: 'calc(100% - 55px)',
              }}
            >
              <Card
                sx={{
                  width: '170px',
                  height: '208px',
                  mr: '13px',
                  border:
                    selectedTemplate === null
                      ? '1px solid #B682FF'
                      : '1px solid transparent',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleTemplateClick(null)}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F5EEFF',
                    height: '77px',
                    margin: '0',
                    padding: '0',
                  }}
                >
                  <AddIcon
                    sx={{
                      height: '46px',
                      width: '46px',
                      color: '#6600F4',
                    }}
                  />
                </CardContent>
                <CardContent>
                  <Typography fontSize="1rem" fontWeight="500">
                    {t('orders.blank')}
                  </Typography>
                  <Typography
                    fontSize="0.857rem"
                    fontWeight="400"
                    sx={{
                      color: 'grey',
                      mt: '1px',
                    }}
                  >
                    {t('orders.subtitle.create_order_groundUp')}
                  </Typography>
                </CardContent>
              </Card>

              {filteredTemplates?.map((template) => (
                <div key={template?._id}>
                  <TemplatesCards
                    name={template?.name}
                    description={template?.description}
                    weekDays={template?.weekDays}
                    productsNumber={template?.products.length}
                    isSelected={selectedTemplate === template?._id}
                    select={() => handleTemplateClick(template?._id)}
                    createdBy={template?._createdBy?.fullName}
                    type="orders"
                  />
                </div>
              ))}
            </Grid>
          </div>
        </ContentWrapper>
        <Grid
          sx={{
            height: '62px',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            borderTop: 'solid 1px rgba(1, 1, 1, 0.12)',
            background: 'white',
            zIndex: '99',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px',
              float: 'right',
            }}
          >
            <Button sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={closeDrawer}>
              {t('common.buttons.cancel')}
            </Button>
            {orderOnSameDate ? (
              <Button
                color="primary"
                variant="contained"
                disabled
                sx={{
                  borderRadius: '42px',
                  ml: '12px',
                }}
              >
                {t('common.buttons.proceed')}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled={createOrderBtnDisabled}
                onClick={handleCreateOrder}
                sx={{
                  borderRadius: '42px',
                  ml: '12px',
                }}
              >
                {t('common.buttons.proceed')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    </DrawerComponent>
  );
}

function useGetOrders(queryParams, skip = false) {
  const { data } = useGetOrdersQuery(`${queryParams}&page=${1}&limit=${360}`, {
    skip,
  });
  return data;
}

const Wrapper = styled.div`
  overflow: hidden !important;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 20px 0px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 44px - 62px);

  .template {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 236px);
  }

  @media screen and (max-width: 1260px) {
    .template {
      max-height: calc(100% - 394px);
    }
  }
`;

const CalendarDayContainer = styled.div`
  display: flex;
  position: relative;
`;

const CalenderDayMark = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) =>
    props.color ? props.color : props.theme.primary};
`;

export const InputsContainer = styled.div`
  flex: 1;
  height: fit-content;
  display: flex;
  gap: 16px;

  .MuiFormControl-root {
    margin: 0 !important;
  }

  @media screen and (max-width: 1260px) {
    flex-direction: column;
  }
`;

export const SalesPersonBadge = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px 3px 4px;
  border-radius: 4px;
  background: ${themes.default.lightGrayBg};
`;

export const NotTypicalDateBadge = styled.div`
  height: 1.5rem;
  padding: 0px 10px;
  border-radius: 4px;
  background: ${themes.default.beige};
  border: 1px solid ${themes.default.whisper};
  display: flex;
  align-items: center;
  gap: 4px;
`;
