import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from '../../../../components/CustomMenu';
import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectInactiveDecks } from '../../../../../common/store/organization/selectors';
import { MenuOption } from '../../../../components/CustomMenu/types';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { useGetWarehousesQuery } from 'common/services/organizationApi';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

function AddMapping() {
  const [menuAnchor, setMenuAcnhor] = useState<null | HTMLElement>(null);
  const roundtripMappingsContext = useManagedContext(
    'RoundtripMappingsContext',
  );
  const open = Boolean(menuAnchor);
  const mappingCan = usePermission('mappings')(Permission.EDIT);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAcnhor(event.currentTarget);
  }, []);
  const { data: warehouseData } = useGetWarehousesQuery('');

  const closeMenu = useCallback(() => {
    setMenuAcnhor(null);
  }, []);
  const inactiveDecks = useSelector(selectInactiveDecks);
  const { t } = useTranslation();
  const existingWarehousesMappings =
    roundtripMappingsContext.roundtripMappings.map((w) => w.id);
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  useEffect(() => {
    if (warehouseData?.warehouses) {
      const updatedMenuOptions = warehouseData.warehouses
        .filter((w) => !existingWarehousesMappings.includes(w._id))
        .map((w) => ({
          label: w.name as string,
          status: w.status as string,
          color: w.color as string,
          shortName: w.shortName as string,
          action: () => {
            roundtripMappingsContext.updateDataWithFunction((prev) => {
              prev.roundtripMappings.push({
                id: w._id as string,
                name: w.name as string,
                nbOfRoundtrips: 0,
                color: w.color as string,
                shortName: w.shortName as string,
                status: w.status as string,
                roundtrips: [],
              });
            });
          },
        }));
      console.log('sdjuhsduhsd', updatedMenuOptions);
      setMenuOptions(updatedMenuOptions);
    }
  }, [warehouseData, inactiveDecks, roundtripMappingsContext]);
  return (
    <>
      {menuAnchor && (
        <Menu
          options={menuOptions}
          open={open}
          anchorEl={menuAnchor}
          onClose={closeMenu}
          sx={{
            marginLeft: '30px',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right', // Change from 'center' to 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right', // Change from 'center' to 'right'
          }}
        />
      )}
      {menuOptions.length === 0
        ? ''
        : mappingCan && (
            <Button
              startIcon={<Plus />}
              variant="text"
              color="primary"
              disableRipple
              sx={{
                width: 'fit-content',
                height: '30px',
                py: 0,
                pr: 1.3,
                gap: 1.3,
                borderRadius: '4px',
                marginTop: '20px',
              }}
              onClick={(e) => openMenu(e)}
            >
              {t('add_warehouse')}
            </Button>
          )}
    </>
  );
}

export default AddMapping;
