import { useSelector } from 'react-redux';
import { selectAuthUser } from '../store/auth/selectors';
import { checkUserAcl, checkUserRole } from '../helpers/acl';

export function useGetUserAcl() {
  const authUser = useSelector(selectAuthUser);

  const isDriver = () => {
    return (
      checkUserRole(authUser, ['driver']) &&
      checkUserAcl(authUser, ['manageRoundtrip']) &&
      !checkUserAcl(authUser, ['manageRoundtripsCluster'])
    );
  };

  const isSales = () => {
    return (
      checkUserAcl(authUser, ['manageOrders']) &&
      !checkUserAcl(authUser, ['manageRoundtripsCluster'])
    );
  };

  const isManager = () => {
    return checkUserAcl(authUser, ['manageRoundtripsCluster']);
  };

  const isDocker = () => {
    return (
      checkUserRole(authUser, ['docker']) &&
      checkUserAcl(authUser, ['manageRoundtrip']) &&
      checkUserAcl(authUser, ['viewOrders']) &&
      checkUserAcl(authUser, ['viewCustomers'])
    );
  };

  const isCustomer = () => {
    return checkUserRole(authUser, ['Customer']);
  };

  return {
    isDriver,
    isSales,
    isManager,
    isDocker,
    isCustomer,
  };
}
