import * as React from 'react';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DocumentRow from '../DocumentRow';

export default function DocumentsTable({
  rows,
  onOpenDocument,
  paymentEnable,
}) {
  return (
    <Paper sx={{ width: '100%', marginBottom: '2em' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={70}>Type</TableCell>
              <TableCell width={350}>Document</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Date d'échéance</TableCell>
              <TableCell align="center">Montant</TableCell>
              <TableCell align="center">Statut</TableCell>
              <TableCell align="center">Restant</TableCell>
              <TableCell width={160} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <DocumentRow
                key={row._id}
                row={row}
                paymentEnable={paymentEnable}
                onOpenDocument={onOpenDocument}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
