import { selectAuthUser } from 'app/slices/auth/selectors';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { useSelector } from 'react-redux';
import { CreateOrderDrawer } from '../CreateOrderDrawer';
import { CreateOrderDrawerSales } from '../CreateOrderDrawerSales';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedCustomer?: any;
  selectedProducts?: any;
  defaultDeliveryDate?: any;
  type?: string;
  callId?: string;
  statusOrderedCallId?: string;
}

export const ManagedCreateOrderDrawer = ({
  open,
  onClose,
  selectedCustomer,
  selectedProducts,
  defaultDeliveryDate,
  type,
  callId,
  statusOrderedCallId,
}: Props) => {
  const { isCustomer } = useGetUserAcl();
  const authUser = useSelector(selectAuthUser);
  const authUserRole = authUser?.currentAgent?._role?.key;

  return (
    <>
      {isCustomer() ? (
        <CreateOrderDrawer
          open={open}
          closeDrawer={onClose}
          selectedProducts={selectedProducts}
        />
      ) : authUserRole === 'sales' || authUserRole === 'admin' ? (
        <CreateOrderDrawerSales
          open={open}
          closeDrawer={onClose}
          selectedCustomer={selectedCustomer}
          selectedProducts={selectedProducts}
          defaultDeliveryDate={defaultDeliveryDate}
          type={type}
          callId={callId}
          statusOrderedCallId={statusOrderedCallId}
        />
      ) : null}
    </>
  );
};
