import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useUpdateRoundtripMappingMutation } from '../../../../../../common/services/roundtripMappings';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '../../index';

function AvailableInternalCodeAutoComplete({
  field,
  warehouseId,
  roundtrip,
  disabled,
}) {
  const [updateRoundtripMappings] = useUpdateRoundtripMappingMutation();
  const { roundtripCodes, updateDataWithFunction } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  const handleBlur = async (selection) => {
    try {
      if (
        !roundtrip.id.includes('New') &&
        roundtrip.roundtripCode !== selection.value
      ) {
        updateDataWithFunction((prev) => {
          prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
            if (warehouse.id === warehouseId) warehouse.loading = true;
            return warehouse;
          });
        });
        await updateRoundtripMappings({
          id: roundtrip.id,
          body: {
            _roundtripGescom: selection.value,
            _deck: warehouseId,
          },
        }).then(() => {
          updateDataWithFunction((prev) => {
            prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
              if (warehouse.id === warehouseId) warehouse.loading = false;
              return warehouse;
            });
          });
        });
      }
    } catch (error) {
      console.error('Error updating roundtrip mappings:', error);
      updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
          if (warehouse.id === warehouseId) warehouse.loading = false;
          return warehouse;
        });
      });
    }
  };

  return (
    <Stack>
      <Autocomplete
        disabled={disabled}
        {...field}
        options={
          roundtripCodes?.map((element) => ({
            label: element,
            value: element,
          })) || []
        }
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        defaultValue={{
          label: '205',
          value: '205',
        }}
        value={
          field.value || {
            label: '',
            value: '',
          }
        }
        onChange={(
          event,
          newValue: {
            label: string;
            value: string;
          },
        ) => {
          event.stopPropagation();
          field.onChange(newValue);
          handleBlur(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Internal Code"
            margin="normal"
            size="small"
            variant="outlined"
            onClick={(e) => e.stopPropagation()}
            onFocus={() => {
              updateDataWithFunction((prev) => {
                prev.currentWarehouse = warehouseId;
              });
            }}
            defaultValue={field.value?.value || ''}
            value={field.value?.value || ''}
            label="Internal Code"
            error={!field.value?.value}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Stack>
  );
}

export default AvailableInternalCodeAutoComplete;
