import React from 'react';

interface imageProps {
  imageName: string;
  width: string;
}

export const Image = ({ imageName, width }: imageProps) => {
  const IMAGE_FROM_PUBLIC_FOLDER = `${process.env.PUBLIC_URL}/${imageName}`;

  return <img src={IMAGE_FROM_PUBLIC_FOLDER} width={width} />;
};
