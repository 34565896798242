import { FuelDetails } from 'app/components/FuelDetails';
import { OrderDetails } from 'app/components/OrderDetails';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { RoundtripStopType } from 'common/utils/enum';

import { isEmpty } from 'lodash';

import styled from 'styled-components';

interface SwipeableDrowerOrderProps {
  selectedOrder: any;
  handleCloseOrderDrawer: () => void;
  onUpdate?: () => void;
  orderChangeStatus?: (status: string) => void;
  triggerRoundtripDetails?: Function;
  viewMode?: boolean;
  handleDisplayMap?: (orderId: string) => void;
  defaultTab?: number;
}

export function SwipeableDrowerOrder({
  selectedOrder,
  handleCloseOrderDrawer,
  onUpdate,
  triggerRoundtripDetails,
  handleDisplayMap = () => {},
  defaultTab = 0,
}: SwipeableDrowerOrderProps) {
  // hooks

  if (selectedOrder?.type === RoundtripStopType.FUEL) {
    return (
      <TwoViewModal
        onOpen={!isEmpty(selectedOrder)}
        onClose={() => handleCloseOrderDrawer()}
        title=" "
        width="auto"
        scrollY={false}
      >
        <DrawerContent>
          <FuelDetails
            order={selectedOrder}
            onCloseDrawer={handleCloseOrderDrawer}
          />
        </DrawerContent>
      </TwoViewModal>
    );
  }

  return (
    <OrderDetails
      open={!!selectedOrder}
      onClose={handleCloseOrderDrawer}
      orderId={selectedOrder?._id}
      refreshRoundtrip={(roundtripId) => {
        if (!!roundtripId) {
          triggerRoundtripDetails?.({ roundtripId });
        }
        onUpdate?.();
      }}
      defaultTabIndex={defaultTab}
      handleDisplayMap={handleDisplayMap}
    />
  );
}

const DrawerContent = styled.div`
  height: calc(100vh - 60px);
  max-width: 100%;
  margin: auto;
`;
