import { ButtonBase, IconButton, Stack, Tooltip } from '@mui/material';
import { Selector } from '../../../Catalog/components/Category/Selector/index';
import { themes } from 'styles/theme/themes';
import FlagFilledGray from 'assets/img/ORDORIA/Catalog/IconsProduct/FlagFilledGray.svg';
import FlagFilledGreen from 'assets/img/ORDORIA/Catalog/IconsProduct/FlagFilledGreen.svg';
import FavoriteDefault from 'assets/img/ORDORIA/Catalog/FavoriteDefault.svg';
import FavoriteFilled from 'assets/img/ORDORIA/Catalog/FavoriteFilled.svg';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { Icon } from 'app/components/Icon';
import { getImgCdn } from 'common/helpers/cdn';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { styled as MUIStyled } from '@mui/material';
import {
  deleteProductsFromSelectedObject,
  removeSelectedProduct,
  setNewSelectedProduct,
} from 'common/store/cart';
import { useSelector } from 'react-redux';
import { getProductSelectionState } from 'common/store/cart/selectors';
import If from 'app/components/If';
import { useState } from 'react';
import { Grid3x3Outlined } from '@mui/icons-material';
import { Star, TrashSimple } from '@phosphor-icons/react';
import EllipsisText from 'app/components/EllipsisText';
import {
  ItemCode,
  NewChip,
  ProdName,
} from 'app/pages/Ordoria/Catalog/components/Cells/NameCell';
import { useTranslation } from 'react-i18next';
import { UCCell } from 'app/pages/Ordoria/Catalog/components/Cells/UCCell';
import { WeightCell } from 'app/pages/Ordoria/Catalog/components/Cells/WeightCell';
import { PriceCell } from 'app/pages/Ordoria/Catalog/components/Cells/PriceCell';
import { QuantityInputCell } from 'app/pages/Ordoria/Catalog/components/Cells/QuantityInputCell';
import { toTitleCase } from 'common/helpers/stringFormatter';
import { useDeleteProductsFromCartMutation } from 'common/services/cartApi';
import { useToaster } from 'hooks/useToaster';

interface CartItemProps {
  content: Record<string, any>;
}

export const CartItem = ({ content }: CartItemProps) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const selectedProducts = useSelector(getProductSelectionState);
  const ActionPayload = { productId: content?._id?._id };
  const isSelected = selectedProducts.products[content._id._id] || false;
  const [deleteProductsFromCart] = useDeleteProductsFromCartMutation();
  const toast = useToaster();

  const handleDeleteProduct = (productId) => {
    try {
      deleteProductsFromCart(`ids[]=${productId}`);
      dispatch(deleteProductsFromSelectedObject([productId]));
      toast(3000, 'success', t('cart.product_deleted'));
    } catch (err) {
      toast(3000, 'error', t('cart.product_not_deleted'));
    }
  };

  return (
    <Wrapper>
      <Stack flexDirection="row" justifyContent="center" gap="8px">
        <Selector
          parent="item"
          action={() =>
            isSelected
              ? dispatch(removeSelectedProduct(ActionPayload))
              : dispatch(setNewSelectedProduct(ActionPayload))
          }
          active={isSelected}
          styles={{
            minWidth: '16px',
            border: !isSelected ? `1px solid ${themes?.default?.silver}` : '',
          }}
        />

        <Stack flex="1" gap="10px">
          <ProductInfoTag>
            <Stack position="relative">
              <Img
                src={
                  content._id?.pictures?.length > 0 &&
                  content?._id.pictures[0]?.key
                    ? getImgCdn(content._id?.pictures[0]?.key, 64)
                    : NoPhoto
                }
              />
              <FavoriteButton onClick={() => setIsFavorite(!isFavorite)}>
                <Icon
                  icon={
                    <img
                      height="12px"
                      width="14px"
                      src={isFavorite ? FavoriteFilled : FavoriteDefault}
                      alt=""
                    />
                  }
                  color={themes?.default?.iconColor}
                />
              </FavoriteButton>
            </Stack>
            <Stack
              sx={{
                flex: 1,
              }}
            >
              <Stack
                gap="2px"
                sx={{
                  flex: 1,
                }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    flex: 1,
                    maxHeight: '18px',
                  }}
                >
                  <Stack flexDirection="row" gap="8px" alignItems="center">
                    <ItemCode style={{ height: '18px' }}>
                      <span>
                        <Icon
                          icon={
                            <Grid3x3Outlined
                              sx={{
                                width: '16px',
                                color: `${themes?.default?.black}8A`,
                              }}
                            />
                          }
                        />
                      </span>
                      {content._id.code}
                    </ItemCode>
                    <If condition={content?._id?.bkData?.isNew}>
                      <NewChip>
                        <Icon
                          icon={<Star weight="fill" />}
                          size={10}
                          color={themes?.default?.accordionWhiteBg}
                        />
                        {t('new')}
                      </NewChip>
                    </If>
                  </Stack>
                  <Tooltip title={t('common.remove')}>
                    <IconButton
                      className="deleteItemButton"
                      sx={{ opacity: 0, transition: 'all 0.1s' }}
                      onClick={() => handleDeleteProduct(content._id._id)}
                    >
                      <Icon icon={<TrashSimple />} size={16} />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <ProdName>
                  <EllipsisText
                    width={260}
                    text={toTitleCase(content?._id?.bkData?.name)}
                  />
                </ProdName>
              </Stack>
              <Stack flexDirection="row" gap="4px" alignItems="center">
                <UCCell
                  value={{ cellData: content?._id?.bkData?.boxing_unit }}
                />
                <WeightCell
                  value={{
                    cellData: parseInt(
                      content?._id?.bkData?.weight || 0,
                    ).toFixed(2),
                  }}
                />
              </Stack>
            </Stack>
          </ProductInfoTag>

          {/* footer */}
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack flexDirection="row" alignItems="center" gap="6px">
              {/* FLAG */}
              <If
                condition={!!content._id.bkData.frequentlyOrdered}
                otherwise={
                  <Icon
                    icon={
                      <img
                        src={FlagFilledGray}
                        style={{
                          height: '16px',
                          width: '16px',
                        }}
                        alt=""
                      />
                    }
                  />
                }
              >
                <Icon
                  icon={<img src={FlagFilledGreen} alt="" />}
                  tooltip={t('order.filter.options.frequentlyOrdered')}
                  size={16}
                />
              </If>

              <PriceCell
                value={{
                  cellData: {
                    price: content?._id?.bkData?.box_price,
                    discount: content?._id?.discount,
                  },
                }}
              />
            </Stack>

            <QuantityInputCell
              value={{ cellData: { id: content?._id?._id } }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 8px 0px;
  border-bottom: solid 1px ${themes.ordoria.lightGrey3};

  :hover {
    .deleteItemButton {
      opacity: 1;
    }
  }

  :last-of-type {
    border-bottom: none;
  }
`;

const ProductInfoTag = styled.div`
  display: flex;
  gap: 8px;
`;

const FavoriteButton = MUIStyled(ButtonBase)({
  cursor: 'pointer',
  position: 'absolute',
  top: '4px',
  left: '4px',
  borderRadius: '50px',
  height: '16px',
  width: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Img = styled.img`
  width: 58px;
  height: 58px;
  object-fit: cover;
  background-color: white;
  border-radius: 4px;
`;
