import { Stack } from '@mui/material';
import If from 'app/components/If';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const PriceCell = (params) => {
  const formatCurrency = useFormatCurrency();

  return (
    <Stack
      flexDirection={
        params?.value?.cellData?.inverseValues ? 'row-reverse' : 'row'
      }
      gap="8px"
      alignItems="center"
    >
      <If condition={params?.value?.cellData?.discount}>
        <DiscountChip>{`- ${params.value.cellData.discount} %`}</DiscountChip>
      </If>
      <Price>
        {formatCurrency(parseFloat(params?.value?.cellData?.price || 0))}
      </Price>
    </Stack>
  );
};

const DiscountChip = styled.div`
  min-width: 40px;
  height: 18px;
  padding: 2px 6px;
  border-radius: 100px;
  background: ${themes?.default?.lightYellow};

  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  color: ${themes?.default?.textBlack};
`;

const Price = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0.15px;
  text-align: left;
`;
