import { CustomCellRendererProps } from '@ag-grid-community/react';
import { A } from '../../../../../../components/A';
import React, { useMemo, useState } from 'react';
import { useManagedContext } from '../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { AddressIcon } from '../../../../../../components/AddressIcon';
import EllipsisText from '../../../../../../components/EllipsisText';
import { getAddressLabel } from '../../../../../../helpers/helpers';
import { themes } from '../../../../../../../styles/theme/themes';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useToaster } from '../../../../../../../hooks/useToaster';
import { usePatchWarehouseMutation } from '../../../../../../../common/services/organizationApi';
import { GridActionMenu } from '../../../../../../components/Grid/components/GridActionMenu';

export const WarehouseNameCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext('grid');
  return (
    <A
      role="button"
      className="link"
      onClick={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.selectedRow = gridContext.responseData?.find(
            (w) => w._id === params.data.id,
          );
          prev.openDrawer = true;
        });
      }}
    >
      {params.value}
    </A>
  );
};

export const WarehouseInitialsCell = (params: CustomCellRendererProps) => {
  if (!params.value.cellData.label) return null;
  return (
    <Chip
      label={params.value.cellData.label}
      sx={{
        borderRadius: '4px',
        fontSize: '0.875rem',
        height: '20px',
        width: 'auto',
        minWidth: '45px',
        backgroundColor: params.value.cellData.color || themes?.default?.grey,
      }}
    />
  );
};

export const WarehouseLocationCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext('grid');
  if (!params.value.cellData.location) return null;
  return (
    <div
      className="locationWrapper"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.extraData.mapCoordinates =
            params.value.cellData.location?.geometry?.coordinates;
          // prev.selectedWarehouse = params.data.id;
        });
      }}
    >
      <AddressIcon />
      <EllipsisText
        width={200}
        text={getAddressLabel(params.value.cellData.location!)}
      />
    </div>
  );
};

export const WarehouseCapacityCell = (params: CustomCellRendererProps) => {
  return (
    <>
      {params.value ? params.value : 0} m<sup>3</sup>
    </>
  );
};

export const WarehouseOperatingHoursCell = (
  params: CustomCellRendererProps,
) => {
  return (
    <>
      {params.value.cellData?.openingHours?.[0] ? (
        <>
          {params.value.cellData?.openingHours[0].openingTime}
          <span> - </span>
          {params.value.cellData?.openingHours[0].closingTime}
        </>
      ) : null}
    </>
  );
};

export const WarehouseStatusCell = (params: CustomCellRendererProps) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={params.value === 'active' ? t('active') : t('inactive')}
      sx={{
        backgroundColor:
          params.value === 'active'
            ? themes?.default?.success
            : themes?.default?.errorBackground,
        color:
          params.value === 'active'
            ? themes?.default?.greenMain
            : themes?.default?.errorMain,
        fontSize: '0.875rem',
        textTransform: 'capitalize',
        height: '30px',
      }}
    />
  );
};

export const WarehouseActionsCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext('grid');
  const toast = useToaster();
  const { t } = useTranslation();
  const [patchWarehouse] = usePatchWarehouseMutation();
  const [status, setStatus] = useState(params.data.status);

  const handleChangeStatus = async (warehouseId: any, status: string) => {
    try {
      await patchWarehouse({
        deckId: warehouseId,
        status,
      }).unwrap();
      setStatus(status);
      if (status === 'active') {
        toast(3000, 'success', 'messages.warehouse.status.activated_success');
      } else {
        toast(3000, 'success', 'messages.warehouse.status.inactivated_success');
      }
    } catch (error) {
      toast(3000, 'error', 'messages.warehouse.status.error');
    }
  };

  const options = useMemo(() => {
    const allowedOptions = [
      {
        label: gridContext.canEdit ? t('edit') : t('view'),
        action: () => {
          gridContext.updateDataWithFunction((prev) => {
            prev.selectedWarehouse = params.data.id;
            prev.openDrawer = true;
            prev.editMode = true;
          });
        },
      },
    ];

    if (gridContext.canEdit) {
      allowedOptions.push({
        action: () => {
          handleChangeStatus(
            params.data.id,
            status !== 'active' ? 'active' : 'inactive',
          );
        },
        label: t(
          status !== 'active' ? 'common.buttons.activate' : 'inactivate',
        ),
      });
    }
    return allowedOptions;
  }, [gridContext, handleChangeStatus, params.data.id, status, t]);
  return <GridActionMenu options={options} />;
};
