import React, { useEffect, useMemo, useRef } from 'react';
import emptyDriverIcon from 'assets/img/users/employees.svg';
import { useGetWarehousesQuery } from '../../../../../../../common/services/organizationApi';
import { IGridContext } from '../../../../../../components/ManagedGrid/types';
import { useManagedContext } from '../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import ManagedGrid from '../../../../../../components/ManagedGrid';
import { getProcessedWarehousesFunction } from '../helpers';
import WarehousesDrawer from './WarehousesDrawer';
import { useQueryParams } from '../../../../../../../hooks/useQueryParams';
import MapPopover from '../../../../../../components/MapPopover';
import { createPortal } from 'react-dom';
import ManagedToolbar from 'app/components/ManagedGrid/components/ManagedToolbar';

function FunctionalWarehouses() {
  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    queryParams: { order, orderBy, page, search, rowsPerPage },
  } = gridContext;

  const fetchQueryParams = {
    page,
    limit: rowsPerPage,
    search,
    order,
    orderBy,
  };

  const {
    data: warehousesResponse,
    isLoading,
    isFetching,
  } = useGetWarehousesQuery(useQueryParams(fetchQueryParams));

  useEffect(() => {
    if (isLoading || isFetching) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetching || isLoading;
      });
      return;
    }
    // if (!employeesData || !employeesData.docs) return;
    if (!warehousesResponse || !warehousesResponse?.warehouses) return;
    gridContext.updateDataWithFunction((prev) => {
      // const processedWarehouses = getProcessedWarehousesFunction(org)(
      //   employeesData.docs,
      // );
      prev.totalRows = warehousesResponse.totalDocs;
      prev.rowData = getProcessedWarehousesFunction(
        warehousesResponse?.warehouses!,
      );
      prev.loading = false;
      prev.responseData = warehousesResponse?.warehouses!;
    });
  }, [warehousesResponse, isLoading, isFetching]);

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      onAddClick: () => {
        gridContext.updateData('openDrawer', true);
      },
      wrapperStyle: { padding: 0 },
    }),
    [gridContext],
  );

  const noDataConfig = useMemo(() => {
    return {
      icon: emptyDriverIcon,
      onClick: () => {
        gridContext.updateData('openDrawer', true);
      },
      text: 'no_warehouses_added_yet',
      textButton: `add`,
    };
  }, [gridContext.menuIsOpen]);

  const toolBarRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    toolBarRef.current = document.getElementById('toolbar');
  }, []);
  return (
    <>
      {toolBarRef.current &&
        createPortal(
          <ManagedToolbar toolbarConfig={toolbarConfig} />,
          toolBarRef.current,
        )}
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      {gridContext.openDrawer ? <WarehousesDrawer /> : null}
      <MapPopover
        open={!!gridContext.extraData.mapCoordinates}
        handleClose={() => {
          gridContext.updateDataWithFunction((prev) => {
            prev.extraData.mapCoordinates = undefined;
            // prev.extraData.selectedWarehouse = null;
          });
        }}
        latitude={gridContext.extraData.mapCoordinates?.[1]}
        longitude={gridContext.extraData.mapCoordinates?.[0]}
      />
    </>
  );
}

export default FunctionalWarehouses;
