import React from 'react';
import {
  Box,
  SxProps,
  TableCell,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ReOptimizeHeader } from './reoptimizeHeader';
import { FixedTableHead } from 'app/components/TableComponent/helpers';
import {
  checkedTable,
  indeterminateTable,
} from 'app/components/TableComponent/helpers/functions';
import { TimeLineComponent } from 'app/components/TableComponent/helpers/Components/TimeLineComponent';
import {
  EXCEL,
  GRID,
  REGULAR,
  REOPTIMIZE,
  TRACK,
} from 'app/components/TableComponent/constants';
import { TableCellComponent } from 'app/components/TableComponent/helpers/Components/TableCellComponent';
import { ExelExist } from 'app/components/TableComponent/helpers/Components/ExelExist';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'app/components/CustomCheckbox';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectColumn?: any;
  setSelected?: any;
  order: any;
  orderBy: string;
  rowCount: number;
  type?: string;
  headCells: any;
  selected: any;
  rows: any;
  group: string;
  isSelected: any;
  selectedRowIndex: number;
  selectedResult?: number;
  setSelectedResult?: React.Dispatch<React.SetStateAction<number>>;
  selectedRoundTrip?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}
export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    headCells,
    selected,
    rows,
    type,
    onSelectColumn,
    setSelected,
    group,
    isSelected,
    selectedRowIndex,
    selectedResult,
    selectedRoundTrip,
    setSelectedResult,
    onRequestSort,
  } = props;
  //hooks
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  //state & variables
  let stickyCheckbox: SxProps<Theme> | undefined = {};
  // logic
  stickyCheckbox = {
    zIndex: 1,
    backgroundColor: theme?.gridStickyBackGround,
    left: '78px',
    position: 'sticky',
  };
  if (type === TRACK) {
    stickyCheckbox = {
      ...stickyCheckbox,
      backgroundColor: theme.primaryLightO,
    };
  }
  if (type === REOPTIMIZE) {
    return (
      <ReOptimizeHeader
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
        rows={rows}
        selectedRoundTrip={selectedRoundTrip}
      />
    );
  }
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <FixedTableHead
      sx={{
        backgroundColor:
          type === GRID || type === EXCEL
            ? theme.roundtripsGrayBackGround
            : theme.primaryLightO,
      }}
    >
      <TableRow
        sx={{
          display:
            type === TRACK || type === GRID || type === EXCEL
              ? 'flex'
              : 'table-row',
          marginRight: '17px',
        }}
      >
        {type === EXCEL && rows.length && (
          <TableCellComponent
            sx={{
              ...stickyCheckbox,
              left: '120px',
            }}
          />
        )}
        <ExelExist
          type={type}
          EXCEL={EXCEL}
          rows={rows}
          selectedRowIndex={selectedRowIndex}
          isSelected={isSelected}
          group={group}
          orderBy={orderBy}
          order={order}
          onSelectColumn={onSelectColumn}
          selected={selected}
          setSelected={setSelected}
        />
        <Thead>
          {type !== EXCEL &&
            headCells.map((headCell, headCellIndex) => {
              if (headCell.disableRow) return null;
              let styles: {};
              styles = { textTransform: 'uppercase' };
              if (headCell.center) {
                styles = {
                  ...styles,
                  display: 'flex',
                  justifyContent: 'center',
                };
              }
              if (type === GRID && !headCellIndex) {
                styles = {
                  ...styles,
                  ...stickyCheckbox,
                  left: '138px',
                  borderBottom: 'none',
                  position: 'sticky',
                };
              }
              if (headCell?.isSticky) {
                styles = {
                  ...styles,
                  position: 'sticky',
                  left: headCell?.stickyLeftPosition,
                  right: headCell?.stickyRightPosition,
                  backgroundColor: theme?.gridStickyBackGround,
                  marginLeft: headCell?.marginLeft,
                };
              }
              return (
                <React.Fragment key={headCellIndex}>
                  {headCellIndex === 0 && (
                    <>
                      <TableCell
                        sx={{
                          ...stickyCheckbox,
                          left: '0',
                          width: '78px',
                        }}
                      />
                      <TableCell
                        padding="checkbox"
                        sx={{
                          display:
                            type === TRACK || type === GRID || type === EXCEL
                              ? 'flex'
                              : 'table-cell',
                          alignItems: 'center',
                          minWidth: '60px',
                          marginTop: '6px',
                          ...stickyCheckbox,
                        }}
                      >
                        <Checkbox
                          color="primary"
                          indeterminate={
                            // type === REGULAR
                            //   ? numSelected > 0 && numSelected < rowCount
                            //   : indeterminateTable(selected, rows)
                            indeterminateTable(selected, rows)
                          }
                          checked={
                            type === REGULAR
                              ? rowCount > 0 && numSelected === rowCount
                              : checkedTable(selected, rows)
                          }
                          onChange={onSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          sx={{
                            height: 'fit-content',
                          }}
                        />
                      </TableCell>
                    </>
                  )}
                  <TableCell
                    key={headCell.id}
                    align="left"
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={
                      orderBy === headCell.sortField ? order : false
                    }
                    sx={{
                      flex: 1,
                      minWidth: headCell.minWidth || 0,
                      maxWidth: headCell.maxWidth || 'unset',
                      overflow: 'hidden',
                      display: 'flex',
                      ...styles,
                    }}
                  >
                    {headCell.disableSort ? (
                      <span style={{ fontWeight: 500 }}>
                        {t(headCell.label)}
                      </span>
                    ) : (
                      <TableSortLabel
                        active={true}
                        direction={
                          orderBy === headCell.sortField
                            ? order === '-'
                              ? 'desc'
                              : 'asc'
                            : 'asc'
                        }
                        IconComponent={() => (
                          <CustomSortIconWrapper>
                            <div>
                              <Icon
                                icon={<CaretUp />}
                                weight="bold"
                                size={8}
                                color={
                                  orderBy === headCell.sortField && order === ''
                                    ? themes?.default?.black
                                    : `${themes?.default?.iconColor}99`
                                }
                              />
                            </div>
                            <div>
                              <Icon
                                icon={<CaretDown />}
                                weight="bold"
                                size={8}
                                color={
                                  orderBy === headCell.sortField &&
                                  order === '-'
                                    ? themes?.default?.black
                                    : `${themes?.default?.iconColor}99`
                                }
                              />
                            </div>
                          </CustomSortIconWrapper>
                        )}
                        onClick={createSortHandler(headCell.sortField)}
                      >
                        <span style={{ fontWeight: 500 }}>
                          {t(headCell.label)}
                        </span>
                        {orderBy === headCell.sortField ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === '-'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                </React.Fragment>
              );
            })}
        </Thead>
        {type === TRACK && <TimeLineComponent />}
      </TableRow>
    </FixedTableHead>
  );
}
const Thead = styled.div`
  display: flex;
  justify-content: flex-start;
  th {
    border-bottom: none;
  }
  flex-grow: 1;
`;

const CustomSortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;

  div {
    height: 6px;
    position: absolute;
    left: 0;
  }

  div:first-child {
    top: -7px;
  }

  div:last-child {
    bottom: 11px;
  }
`;
