import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';

export const UCCell = (params) => {
  const { t } = useTranslation();
  return (
    <UnitsPerBoxChip>
      <If condition={!!params?.value?.cellData} otherwise={<span>__</span>}>
        {`${params?.value?.cellData}`}
        <span>{t('item_per_box')}</span>
      </If>
    </UnitsPerBoxChip>
  );
};

const UnitsPerBoxChip = styled.div`
  height: 23px;
  padding: 3px 4px;
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  background: ${themes?.default?.smokeGray2};

  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.1rem;
  letter-spacing: 0.17px;
  color: ${themes?.default?.textColorDashboard};

  span {
    font-weight: 400;
  }
`;
