import React, { useEffect } from 'react';
import ManagedToolbar from './components/ManagedToolbar';
import DataGrid from './DataGrid';
import ManagedStatsCards from './components/ManagedStatsCards';
import { useManagedContext } from '../../../common/UtilityComponents/ManagedContext/useManagedContext';
import {
  IGridContext,
  INoDataConfig,
  ITabsContext,
  IToolbarConfig,
} from './types';
import { Stack, TableContainer } from '@mui/material';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { ManagedGridPagination } from './components/ManagedGridPagination';
import { FilterChips } from '../FilterChips';
import { GRID, MAP, LIST, CARD } from '../TableComponent/constants';
import If from '../If';
import NoResults from '../NoData/NoResults';
import { themes } from '../../../styles/theme/themes';
import ManagedNoData from './components/ManagedNoData';
import { MenuOption } from '../CustomMenu/types';
import { pagePreferencesActions } from '../../../common/store/pagePreferences';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { Alert } from '@mui/material';

function ManagedGrid<T>({
  toolbarConfig,
  noDataConfig,
  tabsConfig,
  callout,
}: {
  toolbarConfig?: IToolbarConfig;
  noDataConfig: INoDataConfig;
  tabsConfig?: {
    tabOptions?: (tab) => MenuOption[];
    onInputBlur?: (label: string, id: string) => void;
    onAddIconOptions?: Record<string, unknown>;
  };
  callout?: {
    severity: 'success' | 'info' | 'warning';
    text: string;
  };
}) {
  const gridContext = useManagedContext<IGridContext<T>>('grid');
  const tabsContext = useManagedContext<ITabsContext>('tabs');
  /**
   * setting the tabs config in the tabs context
   * */
  useEffect(() => {
    tabsContext.updateDataWithFunction((prev) => {
      prev.menuActions = tabsConfig?.tabOptions;
      prev.editable = !!tabsConfig?.tabOptions;
      prev.api.onInputBlur = tabsConfig?.onInputBlur;
      prev.onAddIconOptions = tabsConfig?.onAddIconOptions;
    });
  }, [tabsConfig?.tabOptions]);

  /**
   * Persisting the columns order in the local storage on unmount
   * */
  const currentRoute = useLocation().pathname.split('/').join('-');
  const dispatch = useDispatch();
  useEffect(() => {
    const currentColDefs = gridContext.api.getCurrentColumns();
    if (!currentColDefs?.length) return;
    const handleBeforeUnload = () => {
      const currentOrder = currentColDefs.map((t) => {
        const visibilty = t.hide ? 'hide' : 'show';
        return t.field + '#' + t.pinned?.toString() + '#' + visibilty;
      });

      dispatch(
        pagePreferencesActions.setColumnsOrder({
          key: currentRoute,
          order: currentOrder,
        }),
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleBeforeUnload();
    };
  }, [gridContext.columnDefs]);

  /**
   * Persisting the filters in the local storage on unmount
   * */
  useEffect(() => {
    const handleBeforeUnload = () => {
      const currentFilters = {
        ...gridContext.queryParams.filters,
        persistStatus: gridContext.queryParams.status,
      };
      dispatch(
        pagePreferencesActions.setFilters({
          key: currentRoute,
          filters: currentFilters,
        }),
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleBeforeUnload();
    };
  }, [
    currentRoute,
    gridContext.columnDefs,
    gridContext.queryParams.status,
    gridContext.queryParams.filters,
  ]);
  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      gap={gridContext.viewMode === GRID ? '3px' : 0}
      position="relative"
      overflow="hidden"
    >
      <If condition={!gridContext.suppressToolbar}>
        <ManagedToolbar toolbarConfig={toolbarConfig} />
      </If>
      <If condition={!!callout}>
        <Alert
          sx={{
            width: '100%',
            '&.MuiPaper-root .MuiAlert-icon': { paddingTop: '4px' },
            '&.MuiPaper-root  .MuiAlert-message': {
              marginLeft: '-8px',
              fontWeight: '500',
            },
          }}
          severity={callout?.severity || 'info'}
        >
          {callout?.text || ''}
        </Alert>
      </If>
      {gridContext.withStatsCards && (
        <Stack
          gap="10px"
          direction="row"
          // width="100%"
          padding={gridContext.viewMode === GRID ? '10px 0 .3rem 0' : 0}
          position="relative"
          zIndex="2"
        >
          <Stack gap="10px" width="100%">
            <StatsHolder viewMode={gridContext.viewMode}>
              <ManagedStatsCards />
            </StatsHolder>
          </Stack>
        </Stack>
      )}
      <Stack paddingLeft="12px">
        <FilterChips
          filterInit={gridContext.queryParams.filterInit}
          filters={gridContext.queryParams.filters}
          setFilters={(func) => {
            gridContext.updateDataWithFunction((prev) => {
              prev.queryParams.filters = func(prev.queryParams.filters);
            });
          }}
        />
      </Stack>
      <If
        condition={
          gridContext.viewMode === GRID &&
          gridContext.rowData.length === 0 &&
          !gridContext.queryParams.search &&
          gridContext.filtersCount === 0 &&
          !gridContext.loading &&
          !gridContext.extraData?.showCustomLoading
        }
      >
        <EmptyCenter>
          <ManagedNoData {...noDataConfig} />
        </EmptyCenter>
      </If>
      <If
        condition={
          gridContext.viewMode === GRID &&
          gridContext.rowData.length === 0 &&
          !gridContext.loading &&
          !gridContext.extraData?.showCustomLoading &&
          (!!gridContext.queryParams.search || !!gridContext.filtersCount)
        }
      >
        <EmptyCenter>
          <NoResults />
        </EmptyCenter>
      </If>
      <If
        condition={
          gridContext.viewMode === GRID &&
          (!!gridContext.rowData.length ||
            gridContext.extraData?.showCustomLoading)
        }
      >
        <TableWrapper>
          <TableContainer sx={{ flex: 1 }}>
            {tabsContext.tabs && gridContext.extraData?.showCustomLoading ? (
              <LoadingIndicator
                text={gridContext.extraData?.customLoadingText}
              />
            ) : (
              <DataGrid />
            )}
          </TableContainer>
          <If condition={!gridContext.suppressPagination}>
            <ManagedGridPagination />
          </If>
        </TableWrapper>
      </If>
      <If condition={gridContext.viewMode === MAP}>
        {gridContext.mapComponent && <gridContext.mapComponent />}
      </If>
      <If condition={gridContext.viewMode === LIST}>
        {gridContext.listComponent && <gridContext.listComponent />}
      </If>
      <If condition={gridContext.viewMode === CARD}>
        {gridContext.cardComponent && <gridContext.cardComponent />}
      </If>
    </Stack>
  );
}
const TableWrapper = styled(Paper)({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  boxShadow: 'none !important',
  padding: '0 2px',
  background: 'transparent',
});
const StatsHolder = styled.div<{ viewMode: string }>`
  position: ${(props) => (props.viewMode === GRID ? 'relative' : 'absolute')};
  width: 100%;
  padding-inline: 14px;
  padding-top: ${(props) => (props.viewMode === MAP ? '10px' : 0)};
`;
const EmptyCenter = styled.div<{ top?: string }>`
  position: absolute;
  top: ${(props) =>
    props.top ? props.top : `calc(50% + ${themes.default.toolBarHeight})`};
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
export default ManagedGrid;
