import * as React from 'react';

import { EXCEL, GRID, TRACK } from 'app/components/TableComponent/constants';

import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useTranslation } from 'react-i18next';
import {
  DriverCode,
  StopDataWrapper,
} from 'styles/components/roundTrips/roundTripsData';
import StopStation from 'assets/img/roundTrips/stop_station.svg';
import { ExcelData } from 'app/pages/AddRoundTrips/data/RoundTripData/ExcelData';
import { GridData } from 'app/pages/AddRoundTrips/data/RoundTripData/GridData';
import { TrackData } from 'app/pages/AddRoundTrips/data/RoundTripData/TrackData';
import { StopDataGas } from 'app/components/RoundTrips/components/StopDataGas';
import { formatDeliveryHours } from 'common/helpers/place';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';

interface FormatDataRoundTripsProps {
  roundTrips?: any;
  isLoading: boolean;
  setRows: any;
  type: any;
  roundTripCopy: any;
  vehicles: any;
  usersData: any;
  functionRoundTripStatus: (
    status: any,
    id: any,
    roundTripStatus: any,
  ) => Promise<void>;
  updateRoundtripData: any;
  updateFuction: any;
  updateOrderFunction: any;
  handleClickOpen: any;
  handleClickOpenDelete: any;
  setRoundtripEdit: any;
  onDisplayNotes?: Function;
  onDisplayOrder?: Function;
  onDisplayCustomer?: Function;
  driverAgentId: any;
  ripperAgentId: any;
  onDisplayMap?: Function;
  mapDisplayedStop?: any;
  triggerRoundtripDetails?: any;
  setSelectedStop?: React.Dispatch<React.SetStateAction<any>>;
  schedule?: Function;
  clusterStatus?: string;
  queryParams: string;
  isDragged?: boolean;
  updateOrderCache?: Function;
  triggerRoundtripMap?: Function;
  handleOpenDeleteModal?: Function;
  salesAgentId: string;
  userContext: AgentCategories;
  setDefaultTab?: Function;
  selectedRoundtrips?: {
    key: string;
  }[];
}
export const useFormatAddRoundTripsData = ({
  isLoading,
  setRows,
  type,
  roundTripCopy,
  vehicles,
  usersData,
  functionRoundTripStatus,
  updateRoundtripData,
  updateFuction,
  updateOrderFunction,
  handleClickOpen,
  handleClickOpenDelete,
  setRoundtripEdit,
  onDisplayNotes,
  onDisplayOrder,
  onDisplayCustomer,
  driverAgentId,
  ripperAgentId,
  onDisplayMap,
  mapDisplayedStop,
  triggerRoundtripDetails,
  setSelectedStop,
  clusterStatus,
  schedule,
  queryParams,
  isDragged,
  updateOrderCache,
  triggerRoundtripMap,
  salesAgentId,
  userContext,
  setDefaultTab,
  selectedRoundtrips,
}: FormatDataRoundTripsProps) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const arrayUniqueByKey = roundTripCopy?.filter(
    (element, index) =>
      roundTripCopy.findIndex(
        (obj) =>
          obj.value?._startPlace?.['_id'] ===
          element.value?._startPlace?.['_id'],
      ) === index,
  );
  // const arrayUniqueByKey =roundTripCopy.
  React.useEffect(() => {
    if (isLoading) return;

    const rowsData: any = [];
    for (let j = 0; j < arrayUniqueByKey?.length; j++) {
      rowsData.push({
        key: arrayUniqueByKey[j]?._startPlace?.name,
        Roundtrip: arrayUniqueByKey[j],
        data: [],
        location: {
          lat: arrayUniqueByKey[j]?._startPlace?.location?.geometry
            ?.coordinates[0],
          lng: arrayUniqueByKey[j]?._startPlace?.location?.geometry
            ?.coordinates[1],
        },
      });
      for (let i = 0; i < roundTripCopy.length; i++) {
        const roundtrip = roundTripCopy[i];
        // let roundTripObj: RoundTripsData = roundTrips[0]?.data[0];

        const stops: JSX.Element[] = [];
        roundtrip?.stops?.map((stop) => {
          if (stop?.type === 'delivery') {
            stops.push(
              <StopDataWrapper key={1} borderRight={true}>
                <div className="delivery-wrapper">
                  <div className="time">
                    {formatDeliveryHours(stop?._place?.deliveryHours)}
                  </div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <DriverCode>{stop._order?.code}-</DriverCode>{' '}
                      {stop._order?._customer?.publicName}
                    </div>
                    <div>{stop._place?.location?.name}</div>
                  </div>
                </div>
              </StopDataWrapper>,
            );
          } else if (stop?.type === 'gas') {
            stops.push(<StopDataGas stop={stop} StopStation={StopStation} />);
          } else if (stop?.type === 'service') {
            stops.push(<StopDataGas stop={stop} StopStation={StopStation} />);
          }
        });

        if (type === EXCEL) {
          rowsData[j] = ExcelData({
            stops,
            roundTripCopy,
            rowData: rowsData[j],
            theme,
            i,
          });
        }
        if (type === GRID) {
          rowsData[j] = GridData({
            setRoundtripEdit,
            handleClickOpenDelete,
            handleClickOpen,
            updateOrderFunction,
            updateFuction,
            vehicles,
            roundtrip,
            rowData: rowsData[j],
            theme,
            usersData,
            functionRoundTripStatus,
            onDisplayNotes,
            onDisplayOrder,
            onDisplayCustomer,
            updateRoundtripData,
            driverAgentId,
            ripperAgentId,
            onDisplayMap,
            mapDisplayedStop,
            triggerRoundtripDetails,
            setSelectedStop,
            schedule,
            clusterStatus,
            queryParams,
            isDragged,
            updateOrderCache,
            triggerRoundtripMap,
            salesAgentId,
            userContext,
            setDefaultTab,
            isSelected: selectedRoundtrips?.includes(roundtrip.id),
          });
        }

        if (type === TRACK) {
          rowsData[j] = TrackData({
            rowData: rowsData[j],
            theme,
            roundTripCopy,
            t,
            i,
          });
        }
      }
    }
    setRows(rowsData);
  }, [
    roundTripCopy,
    type,
    vehicles,
    usersData,
    mapDisplayedStop,
    isDragged,
    selectedRoundtrips?.length,
  ]);
};
