import { isNaN } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function useFormatCurrency(options = {}, locale = '') {
  const { i18n } = useTranslation();

  const formatter = new Intl.NumberFormat(locale || i18n.language || 'fr-FR', {
    currency: 'EUR',
    style: 'currency',
    ...options,
  });

  return function formatCurrency(value: number) {
    if (isNaN(value)) {
      return formatter.format(0);
    }
    return formatter.format(value);
  };
}
