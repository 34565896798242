import { Stack } from '@mui/material';
import { Card } from 'app/components/KpiCard';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { IGridContext, IStatsConfig } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { CardSkeleton } from 'app/components/KpiCard/skeleton';

export function ManagedStatsCards<T>() {
  const { t } = useTranslation();
  const gridContext = useManagedContext<IGridContext<T>>('grid');
  const statsConfig = useManagedContext<IStatsConfig>('statsConfig');
  const { facet, title, colors, grandTotal, calculateStatusMetrics, statuses } =
    statsConfig;

  const isLoading = !!gridContext.loading;

  if (isLoading) {
    return (
      <Stack direction="row" gap="10px" position="relative" zIndex="2">
        <CardSkeleton key={Math.random()} withCircle={false} />

        {Object.entries(colors).map(() => (
          <CardSkeleton key={Math.random()} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="10px" position="relative" zIndex="2">
      <Card
        displayType="numbers"
        cardTitle={t('common.all')}
        grandTotal={grandTotal || 0}
        onClick={() => {
          gridContext.updateDataWithFunction((prev) => {
            prev.queryParams.status = '';
          });
        }}
        backgroundColor={themes.default?.indigo50}
        thumbColor={themes.default?.primaryActiveColor}
        railColor={themes.default?.indigo100}
        isActive={!gridContext.queryParams.status}
        tooltip={t('common.all')}
      />
      {Object.entries(colors).map(([key, values]) => {
        return (
          <Card
            key={key}
            displayType="chart"
            cardTitle={t(`${title.toLowerCase()}.${key.toLowerCase()}`)}
            grandTotal={
              facet
                ? calculateStatusMetrics(statuses[key], facet)?.total
                : undefined
            }
            percentage={
              facet
                ? calculateStatusMetrics(statuses[key], facet)?.percentage
                : undefined
            }
            thumbColor={values.thumbColor}
            backgroundColor={values.backgroundColor}
            railColor={values.railColor}
            onClick={() => {
              gridContext.updateDataWithFunction((prev) => {
                prev.queryParams.status = statuses[key].toLowerCase();
                prev.queryParams.page = 1;
              });
            }}
            isActive={
              gridContext.queryParams.status.toLowerCase() ===
              statuses[key].toLowerCase()
            }
            tooltip={values.tooltip ? t(values.tooltip) : undefined}
          />
        );
      })}
    </Stack>
  );
}

export default ManagedStatsCards;
