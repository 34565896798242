import React, { useState, useEffect, useRef, useMemo } from 'react';
import { BranchButton } from '../../../BranchButton';
import styled from 'styled-components';
import { Form, FormWrapper, FormActions } from 'app/components/Form/styles';
import { useGetCarriersQuery } from '../../../../../../../common/services/carrierApi';
import { themes } from 'styles/theme/themes';
import { useParams } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { PencilSimpleLine, Flag, Trash, Plus } from '@phosphor-icons/react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  TextField,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material';
import { FieldComponent } from 'app/components/FieldComponent';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { InputAdornment } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useModal } from 'app/components/Dialog/hooks';
import MultiSelectAutocomplete from 'app/components/FilterComponents/components/MultiSelect/components/Autocomplete';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';
import { StatusCustomer } from 'app/components/StatusCustomer';
import TimePickerForm from 'app/components/Form/TimePickerForm';
import { ClientMap } from 'app/components/ClientMap';
import moment from 'moment';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import If from 'app/components/If';
import { Days, daysOfWeek, daysOfWeekPhone, weekDays } from '../../data/data';
import {
  capitalizeFirstLetter,
  formatTime,
  formatTimeSlotFrom,
  formatTimeSlotTo,
  omitEmptyValues,
} from '../../helper/helpers';
import { usePlaceContext } from '../../hooks/usePlaceContext';
import {
  useAddCustomerBranchMutation,
  usePatchCustomerDeckMutation,
} from 'common/services/customerApi';
import { Branch } from '../Type';
import { useToaster } from 'hooks/useToaster';
import { AddPhoneForm } from './AddPhoneForm';
import { CustomerStatus } from 'common/types/Customer';
import FormTextField from 'app/components/Form/TextField';

interface Props {}
export interface Suggestion {
  name: string;
  coordinates: [number, number];
}
export interface Position {
  coords: {
    latitude: number;
    longitude: number;
    altitude: number | null;
    accuracy: number;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
}

interface Props {
  onClose: () => void;
  open: boolean;
  onOk: () => void;
  selectedBranch: Branch | null;
}

export const BranchForm: React.FC<Props> = ({
  onClose,
  onOk,
  selectedBranch,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<Record<any, any>>();

  const toast = useToaster();

  const DEFAULT_LATITUDE = 49.029045;
  const DEFAULT_LONGITUDE = 2.8033632;

  //usestates
  const [isThirdPartyCollectorChecked, setIsThirdPartyCollectorChecked] =
    useState(Boolean(selectedBranch?.thirdPartyCollector || false));

  const { selectedBranchId, canEdit } = usePlaceContext();

  const [fullNames, setFullNames] = useState<string[]>(
    selectedBranch?.contacts?.map((contact) => contact.name) ?? [],
  );

  const carrierData = useGetCarriersQuery();

  const carriers = carrierData.data
    ? carrierData.data.data.map((item) => {
        return { label: item.publicName, value: item._id };
      })
    : [];

  const [phones, setPhones] = useState<string[]>(
    selectedBranch?.contacts?.map((contact) => contact.phone) ?? [],
  );
  const [jobRoles, setJobRoles] = useState<string[]>(
    selectedBranch?.contacts?.map((contact) => contact.role) ?? [],
  );
  const [jobTypes, setJobTypes] = useState<string[]>(
    selectedBranch?.contacts?.map((contact) => contact.jobType) ?? [],
  );
  const initialMainState =
    selectedBranch?.contacts?.map((contact) => contact.main) ??
    Array(fullNames.length).fill(false);
  const [main, setMain] = useState(initialMainState);

  const defaultCollectionDays =
    selectedBranch?.deliveryDays?.map((day: string) => ({
      label: day.charAt(0).toUpperCase() + day.slice(1, 3),
      value: day,
    })) || [];

  const [collectionDaysValue, setCollectionDaysValue] = useState<
    { label: string; value: string }[]
  >(defaultCollectionDays || []);

  const [carrierValue, setCarrierValue] = useState<{
    label: string;
    value: string;
  } | null>(() => {
    const selectedCarrier = carrierData?.data?.data.find(
      (item: any) => item._id === selectedBranch?.carrier,
    );
    return selectedCarrier
      ? { label: selectedCarrier.publicName, value: selectedCarrier._id }
      : null;
  });

  const [openPopover, setOpenPopover] = useState(false);
  const [openCallPopover, setOpenCallPopover] = useState(false);
  const [roundTripCode, setRoundTripCode] = useState<string[]>([]);
  const [prefferedSupportUnit, setPrefferedSupportUnits] = useState<string[]>(
    [],
  );

  const [selectedSupportUnit, setSelectedSupportUnit] = useState<string[]>(
    selectedBranch?.requestedSupport || [],
  );
  const [deliveryTypes, setDeliveryTypes] = useState<string[]>(
    selectedBranch?.deliveryTypes || [],
  );
  const [mainlocationValue, setLocationvalue] = useState<{
    name: string;
    city: string;
    department: string;
    region: string;
    continent: string;
    zipCode: string;
    formattedAddress: string;
    shortAddress: string;
    countryCode: string;
    geometry: {
      type: string;
      coordinates: number[];
    };
  }>({
    name: selectedBranch?.location?.name || '',
    city: selectedBranch?.location?.city || '',
    department: selectedBranch?.location?.department || '',
    region: selectedBranch?.location?.region || '',
    continent: selectedBranch?.location?.continent || '',
    zipCode: selectedBranch?.location?.zipCode || '',
    formattedAddress: selectedBranch?.location?.formattedAddress || '',
    shortAddress: selectedBranch?.location?.shortAddress || '',
    countryCode: selectedBranch?.location?.countryCode || '',
    geometry: {
      type: 'Point',
      coordinates: selectedBranch?.location?.geometry?.coordinates || [],
    },
  });
  const configuration = useSelector(selectConfiguration);
  //usestates end

  //popover
  const [clickedCellIndex, setClickedCellIndex] = useState(null);
  const [clickedCallCellIndex, setClickedCallCellIndex] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handleClick = (event, index) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: rect.bottom, left: rect.left });
    setOpenPopover(true);
    setClickedCellIndex(index);
  };

  const handleCallClick = (event, index) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopoverPosition({ top: rect.bottom, left: rect.left });
    setOpenCallPopover(true);
    setClickedCallCellIndex(index);
  };

  const handleAdd = () => {
    if (clickedCellIndex !== null && selectedValue) {
      const newCellValues = [...cellValues];
      newCellValues[clickedCellIndex] = selectedValue;
      setCellValues(newCellValues);
      handleClosePop();
    }
  };

  const handleAddCall = () => {
    if (clickedCallCellIndex !== null && selectedCallValue) {
      const newCallCellValues = [...cellCallValues];
      newCallCellValues[clickedCallCellIndex] =
        moment(selectedCallValue?.first?.$d).format('HH:mm') +
        ' - ' +
        moment(selectedCallValue?.second?.$d).format('HH:mm');
      setCallCellValues(newCallCellValues);
      handleClosePop();
    }
  };

  const handleClose = () => {
    onClose();
  };
  const { openModal, closeModal } = useModal();

  //popover ends
  const handleClosePop = () => {
    setSelectedCallValue({ first: null, second: null });
    setOpenPopover(false);
    setOpenCallPopover(false);
    setClickedCellIndex(null);
  };

  const handleCallClosePop = () => {
    setOpenCallPopover(false);
    setClickedCallCellIndex(null);
    setSelectedCallValue({ first: null, second: null });
  };

  const contacts = useMemo(() => {
    return fullNames.map((name, index) => ({
      name: name,
      phone: phones[index] ? `${phones[index]}` : '',
      role: jobRoles[index],
      main: main[index],
      jobType: jobTypes[index],
    }));
  }, [fullNames, phones, jobRoles, main, jobTypes]);

  const groupedContacts = useMemo(() => {
    const grouped = {};

    contacts.forEach((contact) => {
      const { jobType } = contact;
      if (!grouped[jobType]) {
        grouped[jobType] = [];
      }
      grouped[jobType].push(contact);
    });

    return grouped;
  }, [contacts]);

  const handleOpenAddNew = () => {
    const handleFormSubmit = (data) => {
      const { fullName, jobRole, phone, jobType, main: isMain } = data;

      setMain((prevMain) => {
        const newMain = [...prevMain];

        if (isMain) {
          for (let i = 0; i < jobTypes.length; i++) {
            if (jobTypes[i] === jobType && newMain[i] === true) {
              newMain[i] = false;
            }
          }
          newMain.push(true);
        } else {
          newMain.push(false);
        }

        return newMain;
      });

      setFullNames((prevFullNames) => [...prevFullNames, fullName]);
      setPhones((prevPhones) => [...prevPhones, phone]);
      setJobRoles((prevJobRoles) => [...prevJobRoles, jobRole]);
      setJobTypes((prevJobTypes) => [...prevJobTypes, jobType]);

      closeModal();
    };

    openModal({
      title: t('customer.contact.add_new'),
      cancel: false,
      content: (
        <AddPhoneForm
          onSubmit={handleFormSubmit}
          renderFooter={true}
          closeModal={closeModal}
        />
      ),
    });
  };

  const handleDeleteEmailClick = (contact) => {
    const index = contacts.findIndex((c) => c.name === contact.name);

    if (index !== -1) {
      setFullNames((prevFullNames) =>
        prevFullNames.filter((_, i) => i !== index),
      );
      setPhones((prevPhones) => prevPhones.filter((_, i) => i !== index));
      setJobRoles((prevJobRoles) => prevJobRoles.filter((_, i) => i !== index));
      setJobTypes((prevJobTypes) => prevJobTypes.filter((_, i) => i !== index));
      setMain((prevMain) => prevMain.filter((_, i) => i !== index));
    }

    closeModal();
  };
  const handleOpenDeleteContact = (contact) => {
    openModal({
      title: t('contact.delete.confirm'),
      cancel: true,
      deleteModal: true,
      action: [
        {
          actionCallback: () => handleDeleteEmailClick(contact),
          actionText: t('supply_units.menu_item.delete'),
        },
      ],
      content: <span>{t('customer.addBranch.contact.deleteContent')}</span>,
    });
  };

  const methods = useForm({
    defaultValues: {
      shortName: selectedBranch?.shortName || '',
      legalIdAlt: '',
      Branchname: '',
      myTimeField: '',
      preferredTimeSlotFrom:
        formatTimeSlotFrom(selectedBranch?.preferredTimeSlotFrom) || '',
      preferredTimeSlotTo:
        formatTimeSlotTo(selectedBranch?.preferredTimeSlotTo) || '',
      availableTimeSlotTo:
        formatTimeSlotTo(selectedBranch?.availableTimeSlotTo) || '',
      availableTimeSlotFrom:
        formatTimeSlotFrom(selectedBranch?.availableTimeSlotFrom) || '',
      status: selectedBranch?.status || '',
      mainLocation: selectedBranch?.location || '',
      vatNumber: selectedBranch?.vatNumber || '',
      apeCode: selectedBranch?.apeCode || '',
      code: selectedBranch?.code || '',
      delivery: false,
      collection: false,
      deliveryTypes: [],
      thirdPartyCollector: selectedBranch?.thirdPartyCollector || false,
      carrier: selectedBranch?.carrier || '',
      markAsMain: selectedBranch?.markAsMain || false,
      collectiondays: '',
      pl: selectedBranch?.vehicleCondition?.includes('pl') || false,
      vl: selectedBranch?.vehicleCondition?.includes('vl') || false,
      minimumWeight: selectedBranch?.minOrderWeight || 0,
      minOrderPrice: selectedBranch?.minOrderPrice || 0,
      minOrderBoxes: selectedBranch?.minOrderBoxes || 0,
      showClientOnCallList: selectedBranch?.callEnable || false,
      siretNumber: selectedBranch?.siretNumber || '',
      days: '',
    },
  });

  //watch
  const {
    control,
    watch,
    formState: { isValid },
  } = methods;
  const siretNumber = watch('siretNumber');
  const shortName = watch('shortName');
  const vatNumber = watch('vatNumber');
  const apeCode = watch('apeCode');
  const code = watch('code');
  const vehicleCondition = watch(['pl', 'vl']);
  const minOrderWeight = watch('minimumWeight');
  const minOrderPrice = watch('minOrderPrice');
  const minOrderBoxes = watch('minOrderBoxes');
  const preferredTimeSlotFrom = watch('preferredTimeSlotFrom');
  const preferredTimeSlotTo = watch('preferredTimeSlotTo');
  const availableTimeSlotTo = watch('availableTimeSlotTo');
  const availableTimeSlotFrom = watch('availableTimeSlotFrom');
  const vehicleConditionNames = ['pl', 'vl'];
  const thirdPartyCollector = watch('thirdPartyCollector');
  const status = watch('status');
  const markAsMain = watch('markAsMain');
  const showClientOnCallList = watch('showClientOnCallList');

  const selectedVehicleCondition = vehicleConditionNames.filter(
    (type, index) => vehicleCondition[index],
  );

  const customerStatus: any = [];
  Object.values(CustomerStatus).forEach((status) => {
    customerStatus.push({
      label: t(`customer.status.${status}`),
      value: status,
      render: () => <StatusCustomer status={status} />,
    });
  });

  const StyledTableCell = styled(TableCell)({
    padding: '4px 8px',
    fontSize: '0.75rem',
    border: '1px solid #EFEFEF',
    cursor: 'pointer',
    width: '120px',
    height: '25.55px',
    '&:hover': {
      backgroundColor: themes.bianta.lightGrey,
    },
  });

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        displayName: data.display_name,
        city:
          data.address.city || data.address.town || data.address.village || '',
        district: data.address.suburb || data.address.neighbourhood || '',
        region: data.address.state || '',
        countryCode: data.address.country_code || '',
        zipCode: data.address.postcode || '',
      };
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedBranch === null) {
      reverseGeocode(DEFAULT_LATITUDE, DEFAULT_LONGITUDE).then((data) => {
        if (data !== null) {
          setLocationvalue({
            ...mainlocationValue,
            name: data.displayName,
            city: data.city,
            department: data.district,
            region: data.region,
            continent: '',
            zipCode: data.zipCode,
            formattedAddress: '',
            shortAddress: '',
            countryCode: data.countryCode,
            geometry: {
              type: 'Point',
              coordinates: [DEFAULT_LONGITUDE, DEFAULT_LATITUDE],
            },
          });
        }
      });
    }
  }, []);

  const timePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (timePickerRef.current) {
      timePickerRef.current.focus();
    }
  }, []);

  const handleConfiguration = useMemo(() => {
    return () => {
      setRoundTripCode(configuration?.roundtripCodes);
      const supportUnits = (configuration?.supportUnits as any)?.map(
        (unit) => unit.key,
      );
      setPrefferedSupportUnits(supportUnits);
    };
  }, [configuration]);

  const handlePrefferedSupportUnitChange = (
    value: string,
    checked: boolean,
  ) => {
    if (checked) {
      setSelectedSupportUnit((prevState) => [...prevState, value]);
    } else {
      setSelectedSupportUnit((prevState) =>
        prevState.filter((unit) => unit !== value),
      );
    }
  };

  useEffect(() => {
    handleConfiguration();
  }, [handleConfiguration]);

  const [addBranch] = useAddCustomerBranchMutation();

  const onSubmit = async () => {
    const payload = {
      _customer: id,
      customerId: id,
      siretNumber: siretNumber,
      shortName: shortName,
      status: status ?? null,
      location: mainlocationValue,
      markAsMain,
      vatNumber,
      apeCode,
      contacts,
      code,
      thirdPartyCollector,
      callEnable: showClientOnCallList,
      carrier: carrierValue?.value,
      deliveryTypes: deliveryTypes,
      requestedSupport: selectedSupportUnit,
      vehicleCondition: selectedVehicleCondition,
      preferredTimeSlotFrom: formatTime(preferredTimeSlotFrom),
      preferredTimeSlotTo: formatTime(preferredTimeSlotTo),
      availableTimeSlotTo: formatTime(availableTimeSlotTo),
      availableTimeSlotFrom: formatTime(availableTimeSlotFrom),
      minOrderWeight: Number(minOrderWeight),
      minOrderPrice: Number(minOrderPrice),
      minOrderBoxes: Number(minOrderBoxes),
      deliveryDays: collectionDaysValue.map((day) => day.value),
      deliveryDaysHours: formatCallDays(),
      roundtripCodes: cellValues.filter((value) => value !== ''),
      roundtripDays: formatRoundtripDays(),
    };

    const cleanPayload = omitEmptyValues(payload);

    await addBranch({ body: cleanPayload })
      .unwrap()
      .then(() => {
        toast(5000, 'success', t('branch.add.toast'));
        onOk();
        onClose();
      })
      .catch((e) => {
        toast(5000, 'error', t(`${e.data.message}`));
      });
  };

  const [editBranch] = usePatchCustomerDeckMutation();

  async function handleEdit() {
    const payload = {
      _customer: id,
      customerId: id,
      siretNumber: siretNumber,
      shortName: shortName,
      status: status ?? null,
      location: mainlocationValue,
      markAsMain,
      vatNumber,
      apeCode,
      contacts,
      code,
      thirdPartyCollector,
      callEnable: showClientOnCallList,
      carrier: carrierValue?.value,
      deliveryTypes: deliveryTypes,
      requestedSupport: selectedSupportUnit,
      vehicleCondition: selectedVehicleCondition,
      preferredTimeSlotFrom: formatTime(preferredTimeSlotFrom),
      preferredTimeSlotTo: formatTime(preferredTimeSlotTo),
      availableTimeSlotTo: formatTime(availableTimeSlotTo),
      availableTimeSlotFrom: formatTime(availableTimeSlotFrom),
      minOrderWeight: Number(minOrderWeight),
      minOrderPrice: Number(minOrderPrice),
      minOrderBoxes: Number(minOrderBoxes),
      deliveryDays: collectionDaysValue.map((day) => day.value),
      deliveryDaysHours: formatCallDays(),
      roundtripCodes: cellValues.filter((value) => value !== ''),
      roundtripDays: formatRoundtripDays(),
    };

    const cleanPayload = omitEmptyValues(payload, [
      'contacts',
      'deliveryDaysHours',
    ]);

    await editBranch({
      customerId: id,
      deckId: selectedBranchId,
      body: cleanPayload,
    })
      .unwrap()
      .then(() => {
        toast(5000, 'success', t('branch.edit.toast'));
        onOk();
        onClose();
      });
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  //table
  const StyledTableRow = styled(TableRow)({});
  const FormSection = styled('div')({});

  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedCallValue, setSelectedCallValue] = useState<{
    first: any | null;
    second: any | null;
  }>({ first: null, second: null });

  const [cellValues, setCellValues] = useState(
    Array(daysOfWeek.length).fill(''),
  );

  const [cellCallValues, setCallCellValues] = useState(
    Array(daysOfWeekPhone.length).fill(''),
  );

  function handleDeleteCellValue(index: number, type: string) {
    if (type === 'timeslot') {
      const newCellValues = [...cellCallValues];
      newCellValues[index] = '';
      setCallCellValues(newCellValues);
    } else if (type === 'roundtrip') {
      const newCellValues = [...cellValues];
      newCellValues[index] = '';
      setCellValues(newCellValues);
    }
  }

  function handleClickOnMainFlag(contact) {
    const index = contacts.findIndex(
      (c) => c.name === contact.name && c.phone === contact.phone,
    );

    if (index === -1) {
      return;
    }

    const jobTypeOfClicked = contact.jobType;

    setMain((prevMain) => {
      const newMain = [...prevMain];

      for (let i = 0; i < jobTypes.length; i++) {
        if (jobTypes[i] === jobTypeOfClicked) {
          newMain[i] = false;
        }
      }

      newMain[index] = !prevMain[index];

      return newMain;
    });
  }

  function handleEditContact(contact) {
    const index = contacts.findIndex((c) => c.phone === contact.phone);

    const handleFormSubmit = (data) => {
      const { fullName, jobRole, phone, jobType, main } = data;

      setFullNames((prevFullNames) => {
        const newFullNames = [...prevFullNames];
        newFullNames[index] = fullName;
        return newFullNames;
      });

      setPhones((prevPhones) => {
        const newPhones = [...prevPhones];
        newPhones[index] = phone;
        return newPhones;
      });

      setJobRoles((prevJobRoles) => {
        const newJobRoles = [...prevJobRoles];
        newJobRoles[index] = jobRole;
        return newJobRoles;
      });

      setJobTypes((prevJobTypes) => {
        const newJobTypes = [...prevJobTypes];
        newJobTypes[index] = jobType;
        return newJobTypes;
      });

      setMain((prevMain) => {
        const newMain = [...prevMain];
        newMain[index] = main;
        return newMain;
      });

      closeModal();
    };

    const handleAddButtonClick = () => {
      const form = document.getElementById('add-new-form');
      form?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      );
    };

    openModal({
      title: t('edit.contact'),
      cancel: true,
      action: {
        actionCallback: handleAddButtonClick,
        actionText: t('common.buttons.save'),
      },
      content: (
        <AddPhoneForm
          onSubmit={handleFormSubmit}
          data={contact}
          renderFooter={false}
        />
      ),
    });
  }

  const handleCheckboxChange = (value) => {
    setDeliveryTypes((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value],
    );
  };

  const validateAtLeastOneSelected = () => {
    return (
      deliveryTypes.length > 0 &&
      selectedSupportUnit.length > 0 &&
      selectedVehicleCondition.length > 0
    );
  };

  function formatRoundtripDays() {
    const roundtripDays = {};

    weekDays.forEach((day, index) => {
      roundtripDays[day] = cellValues[index] !== '' ? cellValues[index] : null;
    });

    return roundtripDays;
  }

  ///Handle defaults values of roundtrip days and delivery days
  useEffect(() => {
    if (selectedBranch?.roundtripDays) {
      const newCellValues = Array(daysOfWeek.length).fill('');

      Days.forEach((day, index) => {
        const value = selectedBranch.roundtripDays[day?.value];
        newCellValues[index] =
          value !== null && value !== undefined ? value : '';
      });

      setCellValues(newCellValues);
    }
  }, [selectedBranch?.roundtripDays]);

  useEffect(() => {
    if (selectedBranch?.deliveryDaysHours) {
      const newCellValues = Array(daysOfWeekPhone.length).fill('');

      weekDays.forEach((day, index) => {
        const value = selectedBranch.deliveryDaysHours.find(
          (item) => item.day === day,
        );

        newCellValues[index] =
          value !== null && value !== undefined ? value.value : '';
      });

      setCallCellValues(newCellValues);
    }
  }, [selectedBranch?.deliveryDaysHours]);
  ////

  function formatCallDays() {
    const deliveryDaysHours: any = [];

    weekDays.forEach((day, index) => {
      if (cellCallValues[index] !== '') {
        deliveryDaysHours.push({
          day: day,
          value: cellCallValues[index],
        });
      }
    });

    return deliveryDaysHours;
  }

  const areAllCallCellsEmpty = cellCallValues.every((value) => value === '');
  const areAllRoundtripCellsEmpty = cellValues.every((value) => value === '');

  const disableBtn = !(
    isValid &&
    mainlocationValue.name !== '' &&
    !areAllCallCellsEmpty &&
    !areAllRoundtripCellsEmpty
  );

  const disableSaveBtn = useMemo(() => {
    return (
      !siretNumber ||
      !code ||
      !shortName ||
      !status ||
      !vatNumber ||
      !apeCode ||
      (!thirdPartyCollector && !deliveryTypes.includes('collection') && !deliveryTypes.includes('delivery')) ||
      !selectedSupportUnit.length ||
      !selectedVehicleCondition.length ||
      cellValues.every((value) => !value) ||
      cellCallValues.every((value) => !value)
    );
  }, [siretNumber, code, shortName, status, vatNumber, apeCode, thirdPartyCollector, deliveryTypes, selectedSupportUnit, selectedVehicleCondition, cellValues, cellCallValues]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <FormWrapper
        style={{
          height: '450px',
          minHeight: 'calc(100vh - 120px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '20px 24px',
        }}
      >
        <div className="title-field">
          <div className="title-field" style={{ position: 'relative' }}>
            <Grid
              container
              alignItems="center"
              gap="20px"
              style={{
                width: '100%',
              }}
            >
              <FieldComponent
                style={{ width: '100%', marginBottom: '8px' }}
                error={!siretNumber}
                name="siretNumber"
                control={control}
                size="small"
                label={t('clientModule.fields.siret')}
                placeholder={t('Add_Client_Enter_SIRET_number')}
                disabled={false}
                variant="outlined"
                rules={{ required: true }}
                helperText={!siretNumber ?`${t('clientModule.fields.siret')} ${t('is_required')} `: ''}
                required
                InputProps={{
                  disabled: !canEdit,
                }}
              />
            </Grid>

            <Typography
              fontWeight="500"
              sx={{
                mb: '8px',
              }}
            >
              {t('common.details')}
            </Typography>
          </div>
        </div>
        <Grid
          container
          alignItems="center"
          gap="8px"
          sx={{
            width: '100%',
            mb: '8px',
          }}
        >
          <FieldComponent
            style={{ width: '20%' }}
            error={!code}
            name="code"
            control={control}
            size="small"
            label={t('Code')}
            placeholder={t('Enter Branch Code')}
            disabled={false}
            variant="outlined"
            rules={{ required: true }}
            helperText={!code ?`${t('Code')} ${t('is_required')} `: ''}
            required
            InputProps={{
              disabled: !canEdit,
            }}
          />
          <FieldComponent
            style={{ width: 'calc(60% - 16px)' }}
            name="shortName"
            control={control}
            size="small"
            label={t('warehouse.heads.name')}
            placeholder={t('Enter Branch Name')}
            disabled={false}
            variant="outlined"
            rules={{ required: true }}
            error={!shortName}
            helperText={!shortName ?`${t('warehouse.heads.name')} ${t('is_required')} `: ''}
            required
            InputProps={{
              disabled: !canEdit,
            }}
          />

          <FieldComponent
            control={control}
            name="status"
            label={t('common.labels.status')}
            placeholder={t('Add_Client_Select_Status')}
            style={{ width: '20%' }}
            select
            required
            error={!status}
            helperText={!status ?`${t('common.labels.status')} ${t('is_required')} `: ''}
            rules={{ required: true }}
            InputProps={{
              disabled: !canEdit,
            }}
          >
            {customerStatus?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.render()}
              </MenuItem>
            ))}
          </FieldComponent>
        </Grid>

        <ClientMap
          latitude={
            selectedBranch === null
              ? DEFAULT_LATITUDE
              : selectedBranch?.location?.geometry?.coordinates[1]
          }
          longitude={
            selectedBranch === null
              ? DEFAULT_LONGITUDE
              : selectedBranch?.location?.geometry?.coordinates[0]
          }
          mapHeight="164px"
          mapWidth="100%"
          defaultFieldValue={mainlocationValue}
          title={t('Add_Client_Address')}
          setLocationValue={setLocationvalue}
          control={control}
          mainOption={true}
        />

        <div style={{ marginTop: '24px' }}>
          <Typography
            fontWeight="500"
            sx={{
              mb: '10px',
            }}
          >
            {t('Business Info')}
          </Typography>
        </div>
        <Grid
          container
          alignItems="center"
          gap="20px"
          sx={{
            width: '100%',
            mb: '8px',
          }}
        >
          <FieldComponent
            style={{ width: 'calc(50% - 10px)' }}
            name="vatNumber"
            control={control}
            size="small"
            label={t('common.vat_number')}
            placeholder={t('Enter Number')}
            disabled={false}
            variant="outlined"
            error={!vatNumber}
            helperText={!vatNumber ?`${t('common.vat_number')} ${t('is_required')} `: ''}
            required
            rules={{ required: true }}
            InputProps={{
              disabled: !canEdit,
            }}
          />
          <FieldComponent
            style={{ width: 'calc(50% - 10px)' }}
            name="apeCode"
            control={control}
            size="small"
            label={t('code_ape')}
            placeholder={t('Enter Code')}
            disabled={false}
            variant="outlined"
            error={!apeCode}
            helperText={!apeCode ?`${t('code_ape')} ${t('is_required')} `: ''}
            required
            rules={{ required: true }}
            InputProps={{
              disabled: !canEdit,
            }}
          />
        </Grid>
        <Typography
          fontWeight="500"
          sx={{
            mb: '10px',
          }}
        >
          {t('phone_book')}
        </Typography>

        {fullNames.length > 0 && (
          <div
            style={{
              fontFamily: 'Roboto',
              width: '678px',
            }}
          >
            <div>
              {Object.entries(groupedContacts).map(
                ([type, contacts]: any, index) => (
                  <div key={index}>
                    <h1 style={{ color: '#000000', fontSize: '14px' }}>
                      {t(`${type}`)}
                    </h1>
                    <table
                      style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        marginBottom: '10px',
                      }}
                    >
                      <tbody>
                        {contacts.map((contact, index) => (
                          <tr
                            key={index}
                            onMouseEnter={() => handleMouseEnter(contact)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <td
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                                color: '#000000',
                                border: '1px solid #E0E0E0',
                                padding: '3px',
                                width: '50%',
                              }}
                            >
                              {contact.name}
                              <span
                                style={{
                                  marginLeft: '10px',
                                  color: '#757575',
                                }}
                              >
                                {contact.role}
                              </span>
                            </td>
                            <td
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                                color: '#000000',
                                border: '1px solid #E0E0E0',
                                padding: '3px',
                                width: '50%',
                                paddingLeft: '15px',
                              }}
                            >
                              {contact.phone ? `+${contact.phone}` : ''}
                              <span
                                style={{
                                  marginLeft: '10px',
                                  color: '#000000',
                                  padding: '2px 4px',
                                  borderRadius: contact.main ? '5px' : 'none',
                                  backgroundColor: contact.main
                                    ? '#f3f4f5'
                                    : 'transparent',
                                  border: contact.main
                                    ? '1px solid #eeeeee'
                                    : 'none',
                                }}
                              >
                                {contact.main ? 'Main' : null}
                              </span>
                              {!!canEdit && hoveredIndex === contact && (
                                <div style={{ float: 'right' }}>
                                  <Tooltip title={t('edit')}>
                                    <PencilSimpleLine
                                      onClick={() => handleEditContact(contact)}
                                      size={15}
                                      color="#757575"
                                      style={{
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      contact.main
                                        ? 'Remove main'
                                        : t('customer.field.markAsMain')
                                    }
                                  >
                                    <Flag
                                      size={15}
                                      color={
                                        contact.main ? '#FBC02D' : '#757575'
                                      }
                                      style={{
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleClickOnMainFlag(contact)
                                      }
                                    />
                                  </Tooltip>
                                  <Tooltip
                                    title={t('supply_units.menu_item.delete')}
                                  >
                                    <Trash
                                      size={15}
                                      onClick={() =>
                                        handleOpenDeleteContact(contact)
                                      }
                                      color="#F44336"
                                      style={{
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ),
              )}
            </div>
          </div>
        )}
        <Button
          onClick={() => {
            !!canEdit && handleOpenAddNew();
          }}
          sx={{
            textTransform: 'none',
          }}
          disabled={!canEdit}
        >
          <Stack direction="row" gap="5px" alignItems="center">
            <Plus size="16px" />
            <Typography
              fontSize="1rem"
              fontWeight={700}
              sx={{
                textDecoration: 'underline',
              }}
            >
              {t('common.addNew')}
            </Typography>
          </Stack>
        </Button>
        <Typography
          fontWeight="500"
          sx={{
            mt: '24px',
            mb: '5px',
            color: (deliveryTypes.length === 0 && !thirdPartyCollector) ? 'error.main' : 'themes.black',
          }}
        >
          {t('clients.orderDetails.delivery_type.mandatory')}
        </Typography>
        <div style={{ display: 'flex' }}>
          <Controller
            name="deliveryTypes"
            control={control}
            rules={{ validate: validateAtLeastOneSelected }}
            render={() => (
              <FormGroup
                style={{
                  marginBottom: '24px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: 'calc(3% - 10px)',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deliveryTypes.includes('collection')}
                      value="collection"
                      onChange={() => handleCheckboxChange('collection')}
                      disabled={!canEdit}
                    />
                  }
                  label={t('common.collection')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deliveryTypes.includes('delivery')}
                      value="delivery"
                      onChange={() => handleCheckboxChange('delivery')}
                      disabled={!canEdit}
                    />
                  }
                  label={t('delivery')}
                />
              </FormGroup>
            )}
          />
          <FormGroup>
            <Controller
              name="thirdPartyCollector"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      defaultChecked={
                        selectedBranch?.thirdPartyCollector || false
                      }
                      checked={Boolean(field.value)}
                      // onChange={e => field.onChange(e.target.checked)}
                      disabled={!canEdit}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        field.onChange(isChecked);
                        setIsThirdPartyCollectorChecked(isChecked);
                        if (!isChecked) {
                          setCarrierValue(null);
                          setCollectionDaysValue([]);
                        }
                      }}
                    />
                  }
                  label={t('customer.addBranch.field.thirdPartyCollector')}
                />
              )}
            />
          </FormGroup>
        </div>
        <Grid container alignItems="center" marginLeft="calc(3% - 10px)">
          <Autocomplete
            options={carriers}
            style={{ width: 'calc(40% - 10px)' }}
            value={carrierValue}
            renderInput={(params) => (
              <TextField
                {...params}
                name="carrier"
                label={t('supply_units.carrier')}
                size="small"
                placeholder={t('Select Carrier')}
                variant="outlined"
              />
            )}
            onChange={(event, newValue: any) => {
              setCarrierValue(newValue);
            }}
            disabled={!canEdit || !isThirdPartyCollectorChecked}
          />

          <MultiSelectAutocomplete
            filterItems={Days}
            selectedOptions={collectionDaysValue}
            placeholder={t('customer.addBranch.fields.collectionDays')}
            handleFilterChange={(event: any) => {
              setCollectionDaysValue(event);
            }}
            textFieldProps={{
              disabled: !canEdit || !isThirdPartyCollectorChecked,
              sx: {
                minWidth: '310px',
                marginLeft: '10px',
                maxHeight: '37.12px',
              },
            }}
            autoCompleteProps={{
              disabled: !canEdit || !isThirdPartyCollectorChecked,
            }}
          />
        </Grid>

        <Typography
          fontWeight="500"
          sx={{
            mb: '5px',
            color: (selectedSupportUnit.length === 0) ? 'error.main' : 'themes.black',
          }}
        >
          {t('customer.field.supportUnitPreferred.mandatory')}
        </Typography>

        <div style={{ display: 'flex' }}>
          {prefferedSupportUnit?.map((preffered, i) => {
            return (
              <FormGroup
                style={
                  i === 0
                    ? {
                        marginLeft: 'calc(3% - 10px)',
                        display: 'flex',
                      }
                    : {}
                }
              >
                <Controller
                  control={control}
                  name={preffered as any}
                  rules={{ validate: validateAtLeastOneSelected }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={selectedSupportUnit?.includes(preffered)}
                          onChange={(e) => {
                            handlePrefferedSupportUnitChange(
                              preffered,
                              e.target.checked,
                            );
                          }}
                          disabled={!canEdit}
                        />
                      }
                      label={capitalizeFirstLetter(preffered)}
                    />
                  )}
                />
              </FormGroup>
            );
          })}
        </div>

        <Typography
          fontWeight="500"
          sx={{
            mt: '24px',
            mb: '5px',
            color: (selectedVehicleCondition.length === 0) ? 'error.main' : 'themes.black',
          }}
        >
          {t('customer.field.accessConditions.mandatory')}
        </Typography>

        <div style={{ display: 'flex', marginLeft: 'calc(3% - 10px)' }}>
          <FormGroup style={{ marginRight: '16px' }}>
            <Controller
              name="pl"
              control={control}
              rules={{ validate: validateAtLeastOneSelected }}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                      disabled={!canEdit}
                    />
                  }
                  label="PL"
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name="vl"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                      disabled={!canEdit}
                    />
                  }
                  label="VL"
                />
              )}
            />
          </FormGroup>
        </div>
        <Typography
          fontWeight="500"
          sx={{
            mt: '24px',
            mb: '12px',
            color: cellValues.every((value) => !value) ? 'error.main' : 'themes.black',
          }}
        >
          {t('customer.field.deliveryDays.mandatory')}
        </Typography>
        <div>
          <TableContainer
            sx={{
              border: `1px solid ${themes?.default?.gainsboro2}`,
            }}
          >
            <Table
              size="small"
              style={{ border: `1px solid ${themes?.default?.gainsboro2}` }}
            >
              <TableHead>
                <StyledTableRow>
                  {daysOfWeek.map((day, index) => (
                    <StyledTableCell
                      key={index}
                      style={{
                        padding: '2px 4px',
                        backgroundColor: cellValues[index]
                          ? '#E5EBFF'
                          : 'transparent',
                        color: cellValues[index] ? '#37383D' : '#A9A9A9',
                      }}
                    >
                      {day.day}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  {daysOfWeek.map((day, index) => (
                    <StyledTableCell
                      key={index}
                      id={`${index}`}
                      style={{
                        padding: '2px 4px',
                        border:
                          openPopover && popoverPosition.left === index * 100
                            ? '2px solid blue'
                            : `1px solid ${themes?.default?.gainsboro2}`,
                        boxSizing: 'border-box',
                      }}
                      onClick={(e) => {
                        if (cellValues[index] === '') {
                          !!canEdit && handleClick(e, index);
                        }
                      }}
                    >
                      <Grid container alignItems="center">
                        <span
                          style={{
                            color: cellValues[index]
                              ? themes?.default?.primaryActiveColor
                              : themes?.default?.textColorDashboard,
                          }}
                        >
                          {cellValues[index] || '-'}
                        </span>
                        <If condition={cellValues[index] && !!canEdit}>
                          <IconButton
                            onClick={() =>
                              handleDeleteCellValue(index, 'roundtrip')
                            }
                            sx={{
                              p: 0,
                              ml: 'auto',
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </If>
                      </Grid>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Popover
            open={!!canEdit && openPopover}
            onClose={handleClosePop}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: popoverPosition.top,
              left: popoverPosition.left,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                width: '266.33px',
                height: 'fit-content',
              },
            }}
          >
            <Grid
              container
              alignItems="center"
              sx={{
                p: '10px 8px',
                borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
              }}
            >
              <Typography>
                {t('roundtrips.edit_roundtrip.add_roundtrip')}
              </Typography>
              <IconButton
                onClick={handleClosePop}
                sx={{
                  marginLeft: 'auto',
                  padding: '2px',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid
              sx={{
                borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
                p: '12px 8px 0px 8px ',
              }}
            >
              <Autocomplete
                onChange={(event, newValue) =>
                  setSelectedValue(newValue || null)
                }
                options={roundTripCode}
                sx={{
                  width: '100%',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="addRoundTrip"
                    style={{
                      width: '100%',
                    }}
                    size="small"
                    variant="outlined"
                    placeholder={t('Select Roundtrip')}
                  />
                )}
              />
            </Grid>
            <Button
              onClick={handleAdd}
              style={{
                float: 'right',
                margin: '12px 8px',
              }}
              variant="contained"
            >
              {t('common.buttons.add')}
            </Button>
          </Popover>
        </div>

        <Typography
          fontWeight="500"
          sx={{
            mt: '24px',
            mb: '12px',
            color: cellCallValues.every((value) => !value) ? 'error.main' : 'themes.black',
          }}
        >
          {t('customer.field.callDays.mandatory')}
        </Typography>
        <div>
          <TableContainer
            sx={{
              border: `1px solid ${themes?.default?.gainsboro2}`,
            }}
          >
            <Table
              size="small"
              style={{ border: `1px solid ${themes?.default?.gainsboro2}` }}
            >
              <TableHead>
                <StyledTableRow>
                  {daysOfWeekPhone.map((day, index) => (
                    <StyledTableCell
                      key={index}
                      style={{
                        padding: '2px 4px',
                        backgroundColor: cellCallValues[index]
                          ? '#E5EBFF'
                          : 'transparent',
                        color: cellCallValues[index] ? '#37383D' : '#A9A9A9',
                      }}
                    >
                      {day.day}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  {daysOfWeekPhone.map((day, index) => (
                    <StyledTableCell
                      key={index}
                      id={`${index}`}
                      style={{
                        padding: '2px 4px',
                        border:
                          openCallPopover &&
                          popoverPosition.left === index * 100
                            ? '2px solid blue'
                            : `1px solid ${themes?.default?.gainsboro2}`,
                        boxSizing: 'border-box',
                      }}
                      onClick={(e) => {
                        if (cellCallValues[index] === '') {
                          !!canEdit && handleCallClick(e, index);
                        }
                      }}
                    >
                      <Grid container alignItems="center">
                        <Typography
                          fontSize="0.75rem"
                          fontWeight={400}
                          sx={{
                            color: cellCallValues[index]
                              ? themes?.default?.primaryActiveColor
                              : themes?.default?.textColorDashboard,
                          }}
                        >
                          {cellCallValues[index] || '-'}
                        </Typography>
                        <If condition={cellCallValues[index] && !!canEdit}>
                          <IconButton
                            onClick={() =>
                              handleDeleteCellValue(index, 'timeslot')
                            }
                            sx={{
                              p: 0,
                              ml: 'auto',
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </If>
                      </Grid>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Popover
            open={!!canEdit && openCallPopover}
            onClose={handleCallClosePop}
            anchorReference="anchorPosition"
            anchorPosition={{
              top: popoverPosition.top,
              left: popoverPosition.left,
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                width: '266.33px',
                height: 'fit-content',
              },
            }}
          >
            <Grid
              container
              alignItems="center"
              sx={{
                p: '10px 8px',
                borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
              }}
            >
              <Typography>{t('customer.addBranch.popover.title')}</Typography>
              <IconButton
                onClick={handleCallClosePop}
                sx={{
                  marginLeft: 'auto',
                  padding: '2px',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid
              container
              alignItems="center"
              sx={{
                borderBottom: `1px solid ${themes?.default?.gainsboro2}`,
                p: '12px 8px 0px 8px ',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={t('common.from')}
                  value={selectedCallValue?.first}
                  onChange={(newValue) => {
                    setSelectedCallValue((prevState) => ({
                      ...prevState,
                      first: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: '50%',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label={t('common.to')}
                  value={selectedCallValue?.second}
                  onChange={(newValue) => {
                    setSelectedCallValue((prevState) => ({
                      ...prevState,
                      second: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={selectedCallValue.first > selectedCallValue.second}
                      sx={{
                        width: '50%',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Button
              onClick={handleAddCall}
              style={{ float: 'right', margin: '12px 8px' }}
              variant="contained"
              disabled={
                selectedCallValue.first === null ||
                selectedCallValue.second === null ||
                selectedCallValue.first > selectedCallValue.second
              }
            >
              {t('common.buttons.add')}
            </Button>
          </Popover>
        </div>

        <FormGroup style={{ marginRight: '16px', marginTop: '12px' }}>
          <Controller
            name="showClientOnCallList"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={Boolean(field.value)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={!canEdit}
                  />
                }
                label={t('customer.field.showClientCallLst')}
              />
            )}
          />
        </FormGroup>

        <Typography fontWeight="500" sx={{ mt: '24px' }}>
          {t('customer.field.timeslot')}
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '275px',
          }}
        >
          <Typography fontWeight="500" sx={{ mt: '15px', mb: '12px' }}>
            {t('customer.field.availableTimeslot')}
          </Typography>
          <Typography fontWeight="500" sx={{ mt: '15px', mb: '12px' }}>
            {t('customer.field.preferredTimeslot')}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <FormSection style={{ display: 'flex' }}>
            <form style={{ width: '160px', display: 'flex' }}>
              <TimePickerForm
                control={control}
                name="availableTimeSlotFrom"
                label={t('common.from')}
                inputProps={{
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
                disabled={!canEdit}
              />
            </form>
            <div style={{ width: '160px', height: '40px' }}>
              <TimePickerForm
                control={control}
                name="availableTimeSlotTo"
                label={t('common.to')}
                inputProps={{
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                }}
                disabled={!canEdit}
              />
            </div>
          </FormSection>
          <FormSection style={{ display: 'flex', marginLeft: '70px' }}>
            <form
              style={{
                width: '160px',
                display: 'flex',
              }}
            >
              <TimePickerForm
                control={control}
                name="preferredTimeSlotFrom"
                label={t('common.from')}
                inputProps={{
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',
                }}
                disabled={!canEdit}
              />
            </form>
            <div style={{ width: '160px', height: '40px' }}>
              <TimePickerForm
                control={control}
                name="preferredTimeSlotTo"
                label={t('common.to')}
                inputProps={{
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                }}
                disabled={!canEdit}
              />
            </div>
          </FormSection>
        </div>
        <Typography fontWeight="500" sx={{ mt: '8px', mb: '12px' }}>
          {t('customer.field.minimumToOrder')}
        </Typography>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ width: '250.33px', height: '40px' }}>
            <FormTextField
              control={control}
              name="minimumWeight"
              type="number"
              label={t('customer.field.minimumWeight')}
              disabled={!canEdit}
              inputProps={{
                disabled: !canEdit,
                endAdornment: (
                  <InputAdornment position="end">
                    <p>Kg</p>
                  </InputAdornment>
                ),
              }}
              num
            />
          </div>
          <div style={{ width: '250.33px', height: '40px' }}>
            <FormTextField
              control={control}
              name="minOrderPrice"
              type="number"
              label={t('customer.field.minimumPrice')}
              disabled={!canEdit}
              inputProps={{
                disabled: !canEdit,
                endAdornment: (
                  <InputAdornment position="end">
                    <p>EUR</p>
                  </InputAdornment>
                ),
              }}
              num
            />
          </div>
          <div style={{ width: '250.33px', height: '40px' }}>
            <FormTextField
              control={control}
              name="minOrderBoxes"
              type="number"
              label={t('customer.field.minimumBoxes')}
              disabled={!canEdit}
              inputProps={{
                disabled: !canEdit,
                endAdornment: (
                  <InputAdornment position="end">
                    <p>Boxes</p>
                  </InputAdornment>
                ),
              }}
              num
            />
          </div>
        </div>
      </FormWrapper>
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60 }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <If
          condition={selectedBranchId === ''}
          otherwise={
            <Button
              variant="contained"
              onClick={handleEdit}
              sx={{
                ml: '16px',
              }}
              disabled={!canEdit || disableSaveBtn}
            >
              {t('common.buttons.save')}
            </Button>
          }
        >
          <BranchButton
            loading={false}
            onSubmit={onSubmit}
            disabled={disableBtn}
            isEdit={false}
            withOptions={true}
          />
        </If>
      </FormActions>
    </Form>
  );
};
