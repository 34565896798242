import { Chip } from '@mui/material';
import { themes } from 'styles/theme/themes';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';

interface VariationChipProps {
  value: number;
  tooltipTitle?: string;
  variant?: 'filled' | 'text';
}

export const VariationChip = ({
  value,
  tooltipTitle,
  variant = 'text',
}: VariationChipProps) => {
  const displayedValue = Math.round(Math.abs(value));
  const iconSx = { width: '14px', height: '14px' };
  const type = value === 0 ? 'stable' : value > 0 ? 'increasing' : 'decreasing';

  const typeOptions = {
    increasing: {
      color: themes?.default?.green3,
      backgroundColor: themes?.default?.chipGreenFont,
      icon: (
        <ArrowUpwardIcon
          sx={{ ...iconSx, color: `${themes?.default?.green3} !important` }}
        />
      ),
    },
    decreasing: {
      color: themes?.default?.chipRedBg,
      backgroundColor: themes?.default?.chipRedFont,
      icon: (
        <ArrowDownwardIcon
          sx={{ ...iconSx, color: `${themes?.default?.chipRedBg} !important` }}
        />
      ),
    },
    stable: {
      color: themes?.default?.blue600,
      backgroundColor: themes?.default?.blue50,
      icon: undefined,
    },
  };

  const color = typeOptions[type].color;
  const backgroundColor = typeOptions[type].backgroundColor;
  const label = `${displayedValue}%`;

  return (
    <ManagedTooltip title={tooltipTitle} disabled={!tooltipTitle}>
      <span>
        <Chip
          icon={typeOptions[type]?.icon}
          size="small"
          label={label}
          sx={{
            color: color,
            background: variant === 'text' ? 'none' : backgroundColor,
            border: variant === 'text' ? 'none' : `1px solid ${color}`,
          }}
        />
      </span>
    </ManagedTooltip>
  );
};
