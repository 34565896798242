import {
  amber,
  cyan,
  deepPurple,
  indigo,
  lightBlue,
  pink,
  teal,
  yellow,
  orange,
} from '@mui/material/colors';

const commonTheme = {
  settingsNavWidth: '223px',
  topBarHeight: '50px',
  mainNavWidth: 'fit-content',
  mainNavWidthMin: '98px',
  blackPrimary: '#5D5971',
  primaryLight: 'rgba(42, 89, 255, 0.12)',
  primaryLightO: '#EFF2FF',
  primaryLight1: '#E5EBFF',
  smokeGray: '#D4D4D4',
  mapPink: '#FF4D4D',
  // primaryLightO: 'rgba(42, 89, 255, 0.08)',
  // primaryActive: 'rgba(42, 89, 255, 0.04)',
  primaryActive: 'rgba(42, 89, 255, 0.04)',
  primaryActiveColor: '#2A59FF',
  grayLight: '#cfcfcf',
  // primaryActiveO: 'rgba(42, 89, 255, 0.5)',
  primaryActiveO: '#95ACFF',
  lightGrey: '#FAFAFA',
  borderGrey: 'rgba(0, 0, 0, 0.23)',
  greyDisabled: '#ABB4D4',
  greenStatus: '#04BC85',
  textDelivered: '#1B5E20',
  bgDelivered: '#EAF2EA',
  bgChecked: '#E4EDFA',
  checkedBorder: '#3B8CE2',
  green: '#2E7D32',
  lightSuccessShade: '#2E7D3280',
  success: '#EAF2EA',
  cultured: '#F4F4F4',
  redStatus: '#DF0000',
  textColorSecondary: '#949494',
  errorBackground: '#FBEAEA',
  errorMain: '#D32F2F',
  warningBackground: '#FDF0E5',
  warningMain: '#ED6C02',
  orangePre: '#E65100',
  orange600: '#FB8C00',
  orange50: '#FFF3E0',
  greenMain: '#1B5E20',
  mediumDarkGrey: '#555555',
  lightOrange: 'rgba(237, 108, 2, 0.04)',
  iconColor: '#757575',
  secondaryIconColor: '#000000CC',
  grey: '#f5f5f5',
  textColorPrimary: '#2A2D33',
  tableBorder: 'rgba(224, 224, 224, 1)',
  shadowGrayWhite: 'rgba(139, 139, 139, 0.25)',
  topShadow: '0px -1px lightgrey',
  lightFill: 'rgba(0, 0, 0, 0.12)',
  outlinedBorder: '#F9FAFF',
  lightError50p: 'rgba(211, 47, 47, 0.5)',
  lightSuccess50p: 'rgba(46, 125, 50, 0.5)',
  successDark: '#123214',
  lightTextPrimary: 'rgba(0, 0, 0, 0.87)',
  lightInfoMain: '#0288D1',
  greyBgInput: '#F0F1F7',
  tabsBarHeight: '40px',
  columnsBarHeight: '64px',
  addRoundtripTopBarHeight: '40px',
  addRoundtripBottomBarHeight: '40px',
  toolBarHeight: '42px',
  toolBarContainerHeight: '38px',
  scrollBarColor: '#babac0',
  roundtripGreyBg: '#fafbfc',
  clientMarkerBg: '#616161',
  clientMarkerBgSelected: '#FFFFFF',
  clientMarkerBorder: '#FAFBFC',
  clientMarkerBorderSelected: '#2A59FF',
  accordionWhiteBg: '#ffffff',
  dataTableHeight: 'calc(100vh - 228px)',
  regularDataTableHeight: 'calc(100vh - 134px)',
  regularDataTableHeightFiltered: 'calc(100vh - 178px)',
  roundtripsGrayBackGround: '#fafbfc',
  black: '#000000',
  green30: '#18ad1e',
  purple: '#673AB7',
  purple50: '#EDE7F6',
  darkOrchid: '#9C27B0',
  lasPalmas: '#BBD645',
  lightGreen600: '#7CB342',
  lightGreen55: '#F1F8E9',
  lightblue900: '#E1F5FE',
  lightblue50: '#01579B',
  summerSky: '#29B6F6',
  ghostWhite: '#FBFCFF',
  egyptianBlue: '#1C2499',
  brown: '#5F2B01',
  safetyOrange: '#FF6F00',
  Saffron: '#FBC02D',
  tapa: '#757573',
  grey2: '#757575',
  zircon: '#E7EDED',
  gainsboro: '#D9D9D9',
  carmine: '#9E161B',
  sunflower: '#E5B811',
  solitude: '#EEF2FF',
  suvaGrey: '#8B8B8B',
  dodgerBlue: '#0089FF',
  whiteSmoke: '#F3F3F3',
  darkGray: '#ABABAB',
  darkGray2: '#A3A3A3',
  echoBlue: '#9FA8DA',
  darkOrange: '#FB8C00',
  cornflowerBlue: '#6B8BFE',
  orange: '#FAAD07',
  markerWarning: '#FDD835',
  markerWarning2: '#F57F17',
  textBlack: '#000000DE',
  red: '#E01310',
  atlantis: '#9DC356',
  green2: '#05B005',
  lightGrey2: '#D7D7D7',
  lightGrey3: '#0000001F',
  beige: '#FFF3E0',
  perano: '#AFBCFD',
  veryLightGrey: '#CCCCCC',
  veryLightGrey2: '#CECCCC',
  darkTangerine: '#FDB913',
  lightBlackBackground: '#292727',
  gainsboro2: '#E0E0E0',
  lightGrayStroke: '#0000003B',
  Cinnabar: '#E53935',
  fruitSaladGreen: '#43A047',
  orangePeel: '#FF9800',
  deepOrange: '#FF5722',
  greenLaurel: '#388E3C',
  madison: '#2C3E50',
  dimGrey: '#666666',
  whisper: '#EEEEEE',
  chipTextColor: '#212121',
  rawUmber: '#773601',
  silver: '#BDBDBD',
  linkWater: '#CBD5E1',
  aliceBlue: '#F8FAFC',
  linen: '#FDF0E6',
  denim: '#2261C9',
  nobel: '#9E9E9E',
  strokePrimary: '#829DFF',
  green3: '#4CAF50',
  blue50: '#E3F2FD',
  blue900: '#0d47a1',
  blue700: '#0057B2',
  teal800: '#00695C',
  teal50: '#E0F2F1',
  textPrimary: '#1F1F1F',
  textPending: '#BF360C',
  bgPending: '#FDF0E5',
  textPendingCustomer: '#EF6C00',
  bgPendingCustomer: '#FFF9C4',
  black54: 'rgba(0, 0, 0, 0.54)',
  black60: 'rgba(0, 0, 0, 0.60)',
  lightBlueActive: '#F7F8FF',
  badgeRed: 'rgb(229, 57, 53)',
  blueToggledButton: '#C7D4ED',
  blueGradiant: 'linear-gradient(167deg, #29B6F6 -0.09%, #2A59FF 105.26%)',
  seperatorBg: '#D4DDE4',
  regularTableBg: 'rgba(250,251,252,1)',
  stickyTableCelTranslucent: 'rgba(255, 255, 255, 0.9)',
  successStatusBg: 'rgba(46, 125, 50, 0.04)',
  iconGrey: '#C0C5DD',
  scrollbarColor: '#babac080',
  popoverElementHeight: '37px',
  popoverLabelPadding: '16px',
  subTitleGrey: '#B0B0B0',
  lightBoxShadow: 'rgba(139, 139, 139, 0.10)',
  menuBoxShadow: '0px 5px 30px 0px rgba(139, 139, 139, 0.25)',
  gridStickyBackGround: 'rgba(250, 251, 252, 0.9)',
  whiteStickyBackGround: 'rgba(255, 255, 255, 0.9)',
  greyStickyBackGround: 'rgba(245, 245, 245, 0.9)',
  addIconSize: '24px',
  iconSize: '16px',
  iconSizeMedium: '20px',
  dotsIconSize: '18px',
  chipRedBg: '#F44336',
  chipRedFont: '#FEEBEE',
  chipGreenFont: '#E8F5E9',
  lightBlue: '#5E81FF',
  fadedBlue: '#A7BAFF',
  fadedGrey: '#F2F4F7',
  sharpGray: '#475467',
  buttonGreyText: '#424242',
  graphGrey: '#EAECF0',
  dashboardSmallCardWidth: '250px',
  dashboardMediumCardWidth: '514px',
  dashboardLargeCardWidth: '1042px',
  dashboardStickySectionWidth: '215px',
  portalStickySectionWidth: '300px',
  dashboardStickySectionHeight: 'calc(50vh - 120px)',
  dashboardTopSection: '106px',
  lightBlack: '#101828',
  pageGreyBg: '#fafbfc',
  snackBarLeftPosition: 'calc(50% + 140px)',
  snackBarLeftPositionMin: 'calc(50% + 60px)',
  blue600: '#1E88E5',
  deepPurple: '#9575CD',
  teal300: '#4DB6AC',
  grey3: '#ADB9BE',
  redA700: '#D50000',
  redA400: '#FF1744',
  tableNavHeight: '40px',
  grey4: 'rgba(0, 0, 0, 0.04)',
  grey5: '#DCDCDC',
  deepOrange700: '#E64A19',
  greyLight: 'rgb(250, 251, 252)',
  tablePaginationHeight: '42px',
  supportStatCardsHeight: '70px',
  tableFilterHeight: '24px',
  lightBlueBg: '#F6F8FF',
  buttonLightGreen: 'rgba(67, 160, 71, 0.05)',
  buttonDarkGreen: 'rgba(76, 175, 80, 0.60)',
  buttonLightRed: 'rgba(244, 67, 54, 0.05)',
  buttonDarkRed: 'rgba(244, 67, 54, 0.60)',
  redLinearGradiant:
    'linear-gradient(90deg, rgba(244, 235, 235, 0) 0%, #f4ebeb 61%)',
  greenLinearGradiant:
    'linear-gradient(90deg, rgba(235, 241, 236, 0) 0%, #ebf1ec 61%)',
  purpleLinearGradiant: `linear-gradient(
    261deg,
    rgba(213, 173, 250, 0.6) 3.97%,
    rgba(207, 199, 255, 0.65) 45.32%,
    rgba(149, 196, 239, 0.7) 104.7%
  )`,
  grey400: '#BDBDBD',
  blueA400: '#2979FF',
  deepPurple300: '#9575CD',
  lightBlue300: '#4FC3F7',
  lightGreen400: '#8BC34A',
  orangeA200: '#FFAB40',
  green800: '#2E7D32',
  primaryBackgroundColor: '#E3F2FD',
  actionButtonColor: '#819198',
  darkBlue: '#0128B4',
  progressGreen: '#81C784',
  progressOrange: '#FFA726',
  progressRed: '#EF5350',
  yellow100: '#FFF9C4',
  yellow800: '#F9A825',
  cyan50: '#E0F7FA',
  cyan600: '#00ACC1',
  pink600: '#D81B60',
  pink50: '#FCE4EC',
  teal600: '#00897B',
  blue800: '#1565C0',
  blueA700: '#2962FF',
  grayBorder: 'rgb(224, 224, 224)',
  redRail: '#FECDD2',
  green100: '#C8E6C9',
  orange100: '#FFE0B2',
  indigo50: '#E8EAF6',
  indigo100: '#C5CAE9',
  purple100: '#D1C4E9',
  purple400: '#AB47BC',
  CRMSPrimary: '#439BA7',
  noteAlert: '#04BC85',
  callButton: '#009688',
  ordoriaLight: '#AD87F7',
  grey6: '#F9F9F9',
  lightYellow: '#FFECB3',
  borderSilver: '#EFEFEF',
  borderSilverLight: '#EBEEF1',
  borderLight: '#D2D2D2',
  iconColorDark: '#343330',
  backgroundSilver: '#F2F5F7',
  skyBlue: '#81D4FA',
  darkRed: '#B71C1C',
  ordoriaLightBackground: '#FAF6FF',
  ordoriaLightBorder: '#B682FF',
  grayBg: '#37474F',
  ordoriaLightIconButton: '#F0E5FF',
  lightGrayBorder: '#E1E1E133',
  gray500: '#506772',
  ordoriaLightActionsBg: '#832AFF0A',
  tooltipReport: '#616161E5',
  textColorDashboard: '#00000099',
  textColorDashboardNoOpacity: '#808080',
  tealA700: '#00BFA5',
  orange700: '#F57C00',
  purpleA700: '#AA00FF',
  lime: '#AFB42B',
  lime100: '#F0F4C3',
  cyan: '#00B8D4',
  darkPurple: '#AA00FF',
  darkPurple50: '#F3E5F5',
  purble600: '#8E24AA',
  lightGrayLabel: 'rgba(0, 0, 0, 0.6)',
  darkGrayBg: '#ebebeb',
  red800: '#C62828',
  gray50: '#F7F7F7',
  AcidGreen: '#AFB42B',
  Chiffon: '#F0F4C3',
  TurquoiseSurf: '#00B8D4',
  lightgraymagenta: '#F3E5F5',
  chipsBorderGrey: '#D5D5D5',
  whiteSmokeBg: '#ffffffdb',
  errorRed: '#FF0101',
  chatMessageSent: '#5f5f5f',
  chatMessageRecieved: '#5c5e66',
  notificationBoxShadow: 'rgba(16, 24, 40, 0.18)',
  saddlebrown: '#69380d',
  chiplinen: '#F0EBE6',
  darkslateblue: '#3949AB',
  muiBoxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
  gradiantBlueBg: 'linear-gradient(166.61deg, #29b6f6 -0.09%, #2a59ff 105.26%)',
  greyColorLabel: '#6D6B7A',
  iconGreyColor: '#0000008A',
  primaryDark: '#1843DC',
  strokePrimaryOpaque: '#829DFF33',
  blueGray400: '#78909C',
  blueGray400Opaque: '#78909C26',
  greenHighlightBg: '#E0F2F180',
  teal100: '#B2DFDB',
  teal700: '#00796B',
  gridCheckboxWidth: 53,
  yellowA700: '#FFD600',
  bgTextLightGreen: 'rgba(0, 172, 193)',
  baseBlack: '#000',
  baseWhite: '#fff',
  plannedStatusColor: '#AD1457',
  unloadedStatusColor: '#3F51B5',
  greenA700: '#64DD17',
  backdropColor: 'rgba(0, 0, 0, 0.12)',
  hoverLightGray: '#f0f1f3',
  iconGrayColor: '#4f5762',
  backgroundGreyColor: '#0000000A',
  modalPurpleShadow: '-24px 24px 48px -12px #674daf57',
  cardBoxShadow:
    '0px 8px 28px -6px rgba(24, 39, 75, 0.06), 0px 18px 88px -4px rgba(24, 39, 75, 0.06)',
  cardBoxShadowAlternate: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  blurShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 2px 0 8px 0 rgba(0, 0, 0, 0.1)',
  hoverWhite: '#F8F8F8',
  chipLightRed: 'rgba(211, 47, 47, 0.1)',
  textLightGray: 'rgba(117, 117, 117, 1)',
  smokeGray2: '#F8F8F9',
  grayA700: '#303030',
  grey7: '#b8b8b8',
  lightCoral: 'rgba(255, 173, 167, 0.77)',
  maroon: '#730901',
  redShadow: 'rgba(223, 0, 0, 0.15)',
  lightBlue100: '#B3E5FC',
  lightBlue200: '#81D4FA',
  yellow200: '#FFF59D',
  lime200: '#E6EE9C',
  lightGreen200: '#C5E1A5',
  greyMetrics: '#e0e0e040',
  grey8: '#EDF0F0',
  cardBorderGradiant:
    'linear-gradient(white, white), linear-gradient(to left, rgba(255, 0, 255, 0.06) 14.39%, rgba(91, 38, 234, 0.3) 84.86%)',
  ashGray: '#808785',
  blue: '#246BF3',
  pink: '#ED3C91',
  calmPurple: '#742774',
  backgroundLightPurple: 'rgba(149,101,222,0.07)',
  backgroundLighterPurple: 'rgba(149,101,222,0.03)',
  backgroundDarkPurple: 'rgba(149,101,222,0.1)',
  purpleBgGradiant: `linear-gradient(
    55deg,
    rgba(255, 0, 255, 0.4) -14.9%,
    rgba(176, 30, 255, 0.4) 10.07%,
    rgba(17, 89, 255, 0.4) 93.5%
  )`,
  lightPurpleBgGradiant: `
  linear-gradient(
    55deg,
    rgba(255, 0, 255, 0.05) -14.9%,
    rgba(176, 30, 255, 0.05) 10.07%,
    rgba(17, 89, 255, 0.05) 93.5%
  )`,
  purpleBlueBorderGradiant: `
  linear-gradient(white, white), linear-gradient(to right, rgb(173, 31, 255) 0%, rgb(16, 100, 247) 50%, rgb(3, 176, 188) 100%)`,
  rippleGrey: '#F0F0F0',
  deeperPurple: '#602BEB',
  lightPink: '#ED3D91F',
  deepPink: '#ED3D91',
  cleanPurple: '#5C27EA',
  lightPurple: '#EEE7FF',
  lighterPink: '#FFE0EF',
  deepOrangeA700: '#DD2C00',
  iconGrey2: '#B1B6B5',
  pink400: '#EC407A',
  pink700: '#C2185B',
  backgroundBlue: '#E6F3FA',
  blueTitleColor: '#013654',
  blueGrey500: '#607D8B',

  // apps primary colors
  biantaPrimary: '#2A59FF',
  ordoriaPrimary: '#5B26EA',
  stockeePrimary: '#854985',
  iamPrimary: '#009DA9',
  lightGrayBg: '#F3F4F5',
  orange1000: '#BF4300',
  amber100: '#FFF2D8',
  amber1000: '#895C00',
  metricsBlueThumb: '#42A5F5',
  blue200: '#90CAF9',
  green200: '#A5D6A7',
  blueGrey50: '#ECEFF1',
  orange300: '#FFB74D',
  red300: '#E57373',
};

const lightTheme = {
  ...commonTheme,
  primary: '#2A59FF',
  secondary: commonTheme.purpleA700,
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  lightIconButton: '#F6F8FF',
  lightBackground: '#F6F8FF',
  darkBackground: '#1843DC',
};

const darkTheme: Theme = {
  ...commonTheme,
  primary: '#2A59FF',
  secondary: commonTheme.purpleA700,
  text: 'rgba(241,233,231,1)',
  textSecondary: 'rgba(241,233,231,0.6)',
  background: 'rgba(0,0,0,1)',
  backgroundVariant: 'rgba(28,26,26,1)',
  border: 'rgba(241,233,231,0.15)',
  borderLight: 'rgba(241,233,231,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  lightIconButton: '#F6F8FF',
  lightBackground: '#F6F8FF',
  darkBackground: '#1843DC',
};

const ordoriaTheme: Theme = {
  ...commonTheme,
  primary: '#6600F4',
  primaryDark: '#4c02b3',
  strokePrimaryOpaque: '#dac1fa33',
  primaryActiveColor: '#700ef8',
  primaryLight1: '#F5EEFF',
  secondary: '#2A59FF',
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  primaryBackgroundColor: '#EEE5FD',
  actionButtonColor: '#819198',
  callButton: '#009688',
  strokePrimary: '#dac1fa',
  lightIconButton: '#F0E5FF',
  lightBackground: '#f4f0fa',
  darkBackground: '#481CBA',
};

const stockeeTheme: Theme = {
  ...commonTheme,
  primary: '#854985',
  primaryDark: '#4c02b3',
  strokePrimaryOpaque: '#dac1fa33',
  primaryActiveColor: '#854985',
  secondary: '#2A59FF',
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: '#5F345F',
  borderLight: '#BD87BD',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  primaryBackgroundColor: '#E7D3E7',
  primaryLight1: '#F3E5F5',
  actionButtonColor: '#819198',
  callButton: '#009688',
  strokePrimary: '#dac1fa',
  lightIconButton: '#F0E5FF',
  lightBackground: '#fcf5fc',
  darkBackground: '#784278',
};

const iamTheme: Theme = {
  ...commonTheme,
  primary: '#009DA9',
  primaryDark: '#4c02b3',
  strokePrimaryOpaque: '#dac1fa33',
  primaryActiveColor: '#009DA9',
  secondary: '#2A59FF',
  text: 'rgb(58,57,56)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  textLight: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.54)',
  primaryBackgroundColor: '#EEE5FD',
  primaryLight1: '#E8F5E9',
  actionButtonColor: '#819198',
  callButton: '#009688',
  strokePrimary: '#dac1fa',
  lightIconButton: '#F0E5FF',
  lightBackground: '#f5fcfc',
  darkBackground: '#FFFFFFB2',
  accordionWhiteBg: '#000000DE',
};

export const avatarColors = [
  indigo['A200'],
  deepPurple[400],
  amber[900],
  pink[700],
  teal[600],
  yellow[800],
  lightBlue[200],
  indigo[300],
  cyan[600],
  orange[700],
  amber[500],
  '#555',
  '#819198',
  '#EF5350',
];

export function getColorsFromString(id: string): string {
  const idLength = id?.length;

  if (!id) {
    return avatarColors[0];
  }

  if (idLength < 3) {
    const index = id.charCodeAt(idLength - 1) % 10;

    return avatarColors[index % avatarColors.length];
  }

  const colorIndex =
    (id.charCodeAt(idLength - 1) +
      id.charCodeAt(idLength - 2) +
      id.charCodeAt(idLength - 3)) %
    avatarColors.length;

  return avatarColors[colorIndex];
}

export type Theme = typeof lightTheme;

export const themes = {
  bianta: lightTheme,
  ordoria: ordoriaTheme,
  stockee: stockeeTheme,
  iam: iamTheme,
  light: lightTheme,
  default: lightTheme,
  dark: darkTheme,
  gescom: lightTheme,
};
