import { RootState } from '../../types/State';
import { initialState } from '.';
import { createSelector } from '@reduxjs/toolkit';

const sliceState = (state: RootState) => state.cart || initialState;

export const getProductSelectionIds = createSelector([sliceState], state => {
  const selectedProductIds = Object.keys(
    state.selectedProducts.products,
  ).filter(productId => state.selectedProducts.products[productId] === true);
  return selectedProductIds;
});

export const getProductSelectionState = createSelector(
  [sliceState],
  state => state.selectedProducts,
);

export const getCartVisibility = createSelector(
  [sliceState],
  state => state.cartVisibilityState,
);

export const getCountOfSelectedProducts = createSelector(
  [sliceState],
  state =>
    Object.values(state.selectedProducts.products).filter(
      isSelected => isSelected,
    ).length,
);
