import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ChipRadio } from 'app/components/Form/ChipRadio';
import { SectionTitle } from 'app/components/Form/styles';
import { useCheckSiren } from 'hooks/Customers/useCheckSiren';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../context';
import If from 'app/components/If';

export const MainDetails = ({ control, errors }) => {
  const { t } = useTranslation();
  const { customer, canEdit, isCustomer } = useContext(CustomerFormcontext);
  const { formatError } = useErrorFormatter();
  const { validate } = useCheckSiren();

  return (
    <Stack gap="16px">
      <If
        condition={!isCustomer}
        otherwise={<SectionTitle>{t('identification')}</SectionTitle>}
      >
        <FormControl sx={{ marginBottom: '0 !important' }}>
          <FormLabel component="label">
            <SectionTitle>{t('info-main-details')}</SectionTitle>
          </FormLabel>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row" gap="10px">
                  <ChipRadio
                    {...field}
                    value="client"
                    disabled={!canEdit}
                    label="common.customer"
                  />
                  <ChipRadio
                    {...field}
                    value="prospect"
                    disabled={!canEdit}
                    label="potential_client"
                  />
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </If>

      <Controller
        name="legalIdAlt"
        control={control}
        rules={{
          required: {
            value: true,
            message: `${t('Add_Client_SIREN.number')} ${t('is_required')}`,
          },
          validate(value) {
            console.log('legalIdAltlegalIdAlt', value);
            return validate({
              siren: value?.toString() || '',
              id: customer?._id,
            });
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            type="text"
            label={`${t('Add_Client_SIREN.number')}`}
            variant="outlined"
            error={!!errors?.legalIdAlt}
            helperText={formatError(
              errors?.legalIdAlt,
              t('Add_Client_SIREN.number'),
            )}
            fullWidth
            disabled={!canEdit || isCustomer}
            onChange={(value) => field.onChange(value)}
            required
          />
        )}
      />
    </Stack>
  );
};
