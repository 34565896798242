import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSubscribeObject } from 'common/hooks/ws';
import { useLazyGetAuthActivitiesStatsQuery } from 'common/services/authApi';
import { appActions } from 'common/store/app';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { USERINFO } from 'utils/routes';

export const useUserNotifications = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const userMessage = useSubscribeObject(
    'user-notification',
    authUser?._id || '',
  );

  const [getActivitiesStats] = useLazyGetAuthActivitiesStatsQuery();

  const message = useMemo(() => {
    if (!userMessage) return null;
    const message = JSON.parse(userMessage);
    if (!message) return null;
    getActivitiesStats({});

    if (message.data?.action?.includes('leave-request')) {
      if (message.data?.action === 'new-leave-request') {
        message.key = 'new-leave-request';
        message.content = {
          userName: message.data.user?.name,
          leaveRequestId: message.data.user.leaveRequestId,
        };
      } else if (message.data?.action === 'manager-updated-leave-request') {
        message.key = 'manager-updated-leave-request';
        message.content = {
          action: message.data.status,
          username: message.data.user?.name,
          byManager: true,
        };
      } else if (message.data?.action === 'employee-updated-leave-request') {
        message.key = 'employee-updated-leave-request';

        message.content = {
          action: message.data.status,
          username: message.data.user?.name,
          byManager: false,
        };
      }

      const path = generatePath(USERINFO, { id: message.data.user?.id });
      message.url = { path, options: { state: { tab: 'leaveRequests' } } };
      if (message.data?.action === 'new-leave-request') {
        message.url = null;
      }
    } else if (
      message?.data?.action?.includes('notification-activity') &&
      message?.data?.activity?.data?.i18n
    ) {
      if (
        message?.data?.activity?.name === 'order-status-updated' ||
        message?.data?.activity?.name === 'roundtrip-status'
      ) {
        message.key = 'status-updated';
        message.content = {
          content: message?.data?.activity?.data?.i18n,
          status: message?.data?.activity?.data?.status,
        };
      } else if (message?.data?.activity?.name === 'roundtrip-stop-updated') {
        message.key = 'roundtrip-stop-updated';
        message.content = {
          content: message?.data?.activity?.data?.i18n,
        };
      }
    }

    return message;
  }, [userMessage]);

  useEffect(() => {
    if (!!message?.content) {
      dispatch(
        appActions.setLastUserNotification({
          duration: 10000,
          content: message.content || null,
          url: message.url || undefined,
          activityName: message?.data?.activity?.name,
          activityKey: message.key || '',
          objectId: message?.data?.activity?.data?.objectId || '',
        }),
      );
    }
  }, [message?.data?.postaction]);
};
