import * as React from 'react';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { Controller, useForm } from 'react-hook-form';

import styled from 'styled-components/macro';
import { TextField } from '@mui/material';
import { useUpdateMeMutation } from 'common/services/authApi';

export function NameForm({ authUser, onOk }) {
  const { t } = useTranslation();
  const [patchMe] = useUpdateMeMutation();
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: authUser?.firstName === 'customer' ? '' : authUser?.firstName,
      lastName: authUser?.lastName === 'customer' ? '' : authUser?.lastName,
    },
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const onSubmit = async () => {
    setLoading(true);

    try {
      if (!isEmpty(firstName) && !isEmpty(lastName)) {
        await patchMe({
          firstName,
          lastName,
        }).unwrap();

        setLoading(false);
        onOk();
      }
    } catch (e) {
      console.log('e', e);
      setLoading(false);
      setDisplayError(true);
    }
  };

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: true, minLength: 1 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('common.firstName')}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          rules={{ required: true, minLength: 1 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('common.lastName')}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          )}
        />

        <FormActions>
          <Button
            disableElevation
            size="large"
            type="submit"
            onClick={() => onOk()}
          >
            {t('common.buttons.cancel')}
          </Button>

          {loading ? (
            <LoadingButton
              variant="contained"
              disableElevation
              loading
              loadingPosition="start"
              size="large"
            >
              {t('common.buttons.submit')}
            </LoadingButton>
          ) : (
            <Button
              disabled={!isEmpty(errors)}
              variant="contained"
              disableElevation
              size="large"
              type="submit"
              style={{ marginLeft: 16 }}
            >
              {t('common.buttons.submit')}
            </Button>
          )}
        </FormActions>
      </form>

      <Snackbar
        open={displayError}
        autoHideDuration={6000}
        onClose={() => setDisplayError(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          onClose={() => setDisplayError(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {t('auth.page.login.error')}
        </Alert>
      </Snackbar>
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  width: 100%;
  padding: 0 32px;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

const FormActions = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
`;
