import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useUpdateRoundtripMappingMutation } from '../../../../../../common/services/roundtripMappings';
import { IRoundtripMappingsContext } from '../../index';

function VehiclesAutoComplete({
  field,
  warehouseId,
  roundtrip,
  index,
  disabled,
}) {
  const { filteredVehicles, updateDataWithFunction, roundtripMappings } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  const [updateRoundtripMappings] = useUpdateRoundtripMappingMutation();

  const handleBlur = (selection) => {
    try {
      if (roundtrip.vehicle !== selection?.id && roundtrip.roundtripCode) {
        updateDataWithFunction((prev) => {
          prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
            if (warehouse.id === warehouseId) warehouse.loading = true;
            return warehouse;
          });
        });
        updateDataWithFunction((prev) => {
          const indexe = roundtripMappings.findIndex(
            (item) => item?.id === warehouseId,
          );
          prev.roundtripMappings[indexe].roundtrips[index].vehicle =
            selection?.id || null;
        });
        updateRoundtripMappings({
          id: roundtrip?.id,
          body: {
            _roundtripGescom: roundtrip.roundtripCode,
            _vehicle: selection?.id || null,
            _deck: warehouseId,
          },
        }).then(() => {
          updateDataWithFunction((prev) => {
            prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
              if (warehouse.id === warehouseId) warehouse.loading = false;
              return warehouse;
            });
          });
        });
      } else {
        updateDataWithFunction((prev) => {
          const indexe = roundtripMappings.findIndex(
            (item) => item?.id === warehouseId,
          );
          prev.roundtripMappings[indexe].roundtrips[index].vehicle =
            selection?.id || null;
        });
      }
    } catch (e) {
      console.error('Error updating roundtrip mappings:', e);
      updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
          if (warehouse.id === warehouseId) warehouse.loading = false;
          return warehouse;
        });
      });
    }
  };
  return (
    <Stack>
      <Autocomplete
        disabled={disabled}
        // open={open}
        // onOpen={() => {
        //   setOpen(true);
        //   getAvailableInternalCodes('671f2beb9485bed5a39f5014');
        // }}
        // onClose={() => setOpen(false)}
        // loading={isLoading}
        {...field}
        options={filteredVehicles || []}
        getOptionLabel={(option: { fullName: string; id: string }) =>
          option.fullName
        }
        value={
          field.value || {
            fullName: '',
            id: '',
          }
        }
        onChange={(event, newValue) => {
          event.stopPropagation();
          field.onChange(newValue);
          handleBlur(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Vehicles"
            margin="normal"
            value={field.value?.id}
            size="small"
            variant="outlined"
            label="Vehicles"
            // onBlur={handleBlur}
            onClick={(e) => e.stopPropagation()}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <>
              //     {isLoading ? (
              //       <CircularProgress color="inherit" size={20} />
              //     ) : null}
              //     {params.InputProps.endAdornment}
              //   </>
              // ),
            }}
          />
        )}
      />
    </Stack>
  );
}
export default VehiclesAutoComplete;
