import { Box, IconButton } from '@mui/material';
import { useToaster } from 'hooks/useToaster';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  useAddNewProductToCartMutation,
  useChangeProductQuantityMutation,
  useGetCartQuery,
} from 'common/services/cartApi';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { themes } from 'styles/theme/themes';
import { hashObject } from 'common/utils/hashObject';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeCartVisibilityState } from 'common/store/cart';

export const QuantityInputCell = (params) => {
  const { data: cart } = useGetCartQuery();
  const [changeProductQuantity] = useChangeProductQuantityMutation();
  const [addNewProductToCart] = useAddNewProductToCartMutation();
  const dispatch = useDispatch();

  const toaster = useToaster();
  const cartHash = hashObject(cart || {});
  const { t } = useTranslation();

  // Get initial product quantity
  const quantity = useMemo(() => {
    const product = cart?.products.find(
      (item) => item?._id._id === params?.value?.cellData?.id,
    );
    return product ? product.quantity : 0;
  }, [cartHash]);

  const [localQuantity, setLocalQuantity] = useState(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const updateQuantity = async (newQuantity: number) => {
    try {
      if (quantity === 0) {
        await addNewProductToCart({
          productId: params?.value?.cellData?.id,
          quantity: newQuantity,
        });
        toaster(2000, 'success', 'Product added to Cart Successfully');
      } else {
        await changeProductQuantity({
          productId: params?.value?.cellData?.id,
          quantity: newQuantity,
        });
      }
    } catch (error) {
      toaster(2000, 'error', 'Failed to update quantity');
      setLocalQuantity(quantity); // Revert to previous quantity on error
    }
  };

  const debouncedUpdateQuantity = useCallback(
    debounce((qty: number) => updateQuantity(qty), 500),
    [quantity],
  );

  const handleQuantityChange = (newQuantity: number) => {
    setLocalQuantity(newQuantity);
    debouncedUpdateQuantity(newQuantity);
  };

  const handleOpenCart = () => {
    dispatch(changeCartVisibilityState(true));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <QuantityBox>
        <IconButton
          disabled={localQuantity < 1}
          sx={{
            padding: '0px',
            color: themes.ordoria.primary,
          }}
          onClick={() => handleQuantityChange(localQuantity - 1)}
        >
          <RemoveIcon sx={{ fontSize: '11px' }} />
        </IconButton>

        <QuantityInput
          type="text"
          value={localQuantity}
          onChange={(e) => {
            const newValue = +e.target.value;
            setLocalQuantity(newValue);
            debouncedUpdateQuantity(newValue);
          }}
        />

        <IconButton
          sx={{ padding: '0px', color: themes.ordoria.grey2 }}
          onClick={() => {
            handleQuantityChange(localQuantity + 1);
            handleOpenCart();
          }}
        >
          <AddIcon sx={{ fontSize: '11px' }} />
        </IconButton>
      </QuantityBox>

      <StyledLabel>{t('box')}</StyledLabel>
    </Box>
  );
};

const QuantityBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  max-width: 57px;
  border-radius: 4px;
  border: 1px solid ${themes.ordoria.lightGrayStroke};
  padding: 0px 2px;
  margin: auto;
`;

const QuantityInput = styled.input`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.174px;
  text-align: center;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const StyledLabel = styled.div`
  min-width: 28px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid ${themes?.default?.lightGrayStroke};
  background: ${themes?.default?.ordoriaLightIconButton};
  display: flex;
  align-items: center;
  padding: 4px;

  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 0.98rem;
  letter-spacing: 0.17px;
  text-align: center;
  color: ${themes?.default?.textBlack};
  text-transform: uppercase;
`;
