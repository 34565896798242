import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';

import { Tooltip } from '@mui/material';
import { StatusChip } from 'app/components/StatusChip';
import useFormatCurrency from 'common/hooks/useFormatCurrency';

interface AccountingBalanceValueProps {
  value: number;
  isOverdue?: boolean;
  forceColor?: string | null;
}

const getColor = (value, isOverdue = false) => {
  if (isOverdue && value > 0) {
    return 'danger';
  } else if (isOverdue) {
    return 'default';
  }

  if (value > 0) {
    return 'error';
  }

  return 'default';
};

export function AccountingBalanceValue({
  value,
  isOverdue = false,
  forceColor = null,
}: AccountingBalanceValueProps) {
  const formatCurrency = useFormatCurrency();

  if (!isNumber(value)) {
    return null;
  }

  if (forceColor) {
    return <StatusChip text={formatCurrency(value || 0)} status={forceColor} />;
  }

  return (
    <StatusChip
      text={formatCurrency(value || 0)}
      status={getColor(value, isOverdue)}
    />
  );
}

export default function AccountingBalance({ total, due, overdue }) {
  const { t } = useTranslation();
  const intlFormat = Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <Tooltip arrow placement="top" title={t('customer.accountingBalanceHelp')}>
      <div style={{ display: 'flex', gap: 1 }}>
        <StatusChip text={intlFormat.format(total)} status={getColor(total)} />
        <StatusChip
          text={intlFormat.format(overdue)}
          status={getColor(overdue)}
        />
        <StatusChip text={intlFormat.format(due)} status={getColor(due)} />
      </div>
    </Tooltip>
  );
}
