import api from './api';
import {
  AuthResponse,
  LoginRequest,
  LogoutRequest,
  CheckCodeProps,
  ChangePasswords,
  SignUpProps,
  AccessToken,
} from '../types/Auth';
import { User } from '../types/User';

export const authApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    check: builder.query<AuthResponse, void>({
      query: () => ({
        url: '/auth/checkSessionToken',
      }),
      extraOptions: {
        refetchOnFocus: true,
        maxRetries: 0,
      },
      providesTags: ['AuthToken'],
    }),
    me: builder.query<User, void>({
      query: () => ({
        url: '/auth/me',
      }),
      extraOptions: {
        refetchOnFocus: true,
        maxRetries: 0,
      },
      providesTags: ['AuthUser'],
    }),
    meCustomer: builder.query<any, string>({
      query: params => ({
        url: `/auth/me-customer?${params}`,
      }),
      extraOptions: {
        refetchOnFocus: true,
        maxRetries: 0,
      },
      providesTags: ['AuthCustomer'],
    }),
    tokenuser: builder.mutation<any, { token: string; action: string }>({
      query: data => ({
        url: '/auth/check-token',
        method: 'POST',
        body: {
          token: data.token,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: credentials => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    logout: builder.mutation<AuthResponse, LogoutRequest>({
      query: credentials => ({
        url: '/auth/logout',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    requestcode: builder.mutation<string, { username: string; action: string }>(
      {
        query: username => ({
          url: '/auth/request-code',
          method: 'POST',
          body: username,
        }),
      },
    ),
    checkcode: builder.mutation<any, CheckCodeProps>({
      query: username => ({
        url: '/auth/check-code',
        method: 'POST',
        body: {
          username: username.userName,
          code: username.code,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    changepassword: builder.mutation<any, ChangePasswords>({
      query: body => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: {
          username: body.userName,
          code: body.code,
          resetToken: body.resetToken,
          newPassword: body.newPassword,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    logingoogle: builder.mutation<any, AccessToken>({
      query: username => ({
        url: '/auth/oauth/google',
        method: 'POST',
        body: {
          accessToken: username.access_token,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    signup: builder.mutation<any, SignUpProps>({
      query: data => ({
        url: '/auth/confirm-invitation',
        method: 'POST',
        body: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
          invitationToken: data.invitationToken,
        },
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    updateMeLocation: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/me/location',
        method: 'POST',
        body: payload.coords,
      }),
    }),
    updateMe: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/me',
        method: 'PATCH',
        body: payload,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    switchDefaultOrganization: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/switch-default-organization',
        method: 'post',
        body: payload,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    switchOrganization: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/switch-organization',
        method: 'post',
        body: payload,
      }),
      extraOptions: {
        maxRetries: 0,
      },
      invalidatesTags: [
        'OrganizationConfiguration',
        'Organization',
        'Orders',
        'Users',
        'Stats',
        'Customers',
        'Vehicles',
        'Roundtrips',
        'Accessories',
        'AccessoryEvents',
        'RolePermissions',
      ],
    }),
    switchAgent: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/switch-agent',
        method: 'post',
        body: payload,
      }),
      extraOptions: {
        maxRetries: 0,
      },
      invalidatesTags: [
        'OrganizationConfiguration',
        'Organization',
        'Orders',
        'Users',
        'Stats',
        'Customers',
        'Vehicles',
        'Roundtrips',
      ],
    }),
    updatePassword: builder.mutation<any, any>({
      query: payload => ({
        url: '/auth/me/password',
        method: 'patch',
        body: payload,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    getAuthActivitiesStats: builder.query({
      query: () => ({
        url: '/activities/stats',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyCheckQuery,
  useCheckQuery,
  useMeQuery,
  useLazyMeQuery,
  useRequestcodeMutation,
  useCheckcodeMutation,
  useChangepasswordMutation,
  useLogingoogleMutation,
  useLogoutMutation,
  useTokenuserMutation,
  useSignupMutation,
  useUpdateMeMutation,
  useUpdateMeLocationMutation,
  useSwitchOrganizationMutation,
  useSwitchDefaultOrganizationMutation,
  useSwitchAgentMutation,
  useUpdatePasswordMutation,
  useGetAuthActivitiesStatsQuery,
  useLazyGetAuthActivitiesStatsQuery,
  useMeCustomerQuery,
} = authApi;

export default authApi;
