import { Middleware } from '@reduxjs/toolkit';
import api, { appInvalidateTags } from 'common/services/api';
import { appActions } from 'common/store/app';

export const authApplicationMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();

    const previousAuthApplication = state.app?.previousApplication;
    const currentAuthApplication = state.app?.currentApplication;

    if (
      currentAuthApplication &&
      previousAuthApplication?.id !== currentAuthApplication?.id
    ) {
      store.dispatch(
        appActions.setPreviousApplication({
          application: currentAuthApplication,
        }),
      );

      store.dispatch(api.util.invalidateTags(appInvalidateTags));
    }

    return result;
  };
