import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
  Stack,
  InputAdornment,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState, useRef, useMemo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useTranslation } from 'react-i18next';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useGetCustomerQuery } from 'common/services/customerApi';
import { useNavigate } from 'react-router-dom';
import {
  useCreateOrderMutation,
  useLazyGetOrdersQuery,
  useUpdateCustomerOrderMutation,
} from 'common/services/orderApi';
import { TemplatesCards } from 'app/components/TemplatesCards';
import { filterItems } from 'functions/searchItemInList';
import moment from 'moment';
import { useLazyGetTemplatesOrderQuery } from 'common/services/templates';
import _without from 'lodash/without';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { useGetOrdersQuery } from 'common/services/orderApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { ChipWithRadio } from 'app/pages/Customers/components/ChipWithRadio';
import { UpdateOrderFormData } from 'app/pages/Ordoria/SalesOrderDetail';
import { themes } from 'styles/theme/themes';
import { CalendarBlank, Info, User } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import {
  ContentWrapper,
  InputsContainer,
  NotTypicalDateBadge,
  SalesPersonBadge,
} from '../CreateOrderDrawerSales';
import { toTitleCase } from 'common/helpers/stringFormatter';
import { generateParamsString } from 'app/pages/Ordoria/cart/components/DeleteBtn';
import { deleteProductsFromSelectedObject } from 'common/store/cart';
import { useDispatch } from 'react-redux';
import { useDeleteProductsFromCartMutation } from 'common/services/cartApi';
interface CreateOrderDrawerProps {
  open: boolean;
  closeDrawer: () => void;
  selectedProducts?: any[];
}

export function CreateOrderDrawer({
  open,
  closeDrawer,
  selectedProducts,
}: CreateOrderDrawerProps) {
  const authUser = useSelector(selectAuthUser);
  const contentWrapperRef = useRef(null);
  const { t } = useTranslation();
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const customerId = authUser?.currentAgent?._customer?.id || null;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [orderOnSameDate, setOrderOnSameDate] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [isNotTypicalDate, setIsNotTypicalDate] = useState(false);
  const [radioValue, setRadioValue] = useState<string>('delivery');
  const [dateValue, setDateValue] = useState<any>(null);

  const handleTemplateClick = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const handleSearchTextChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
  };

  const { data } = useGetCustomerQuery(customerId);
  const decks = data?.decks;

  const decksId = decks?.map((deck) => deck._id) || [];

  const [triggerGetOrder, { data: orders }] = useLazyGetOrdersQuery();

  useEffect(() => {
    if (!selectedBranch?._id) return;
    const formattedDateValue = moment(dateValue?.$d).format('YYYY/MM/DD');

    const orderQuery = `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&ordoria=true&status=${[
      'pending',
      'pending-updates',
      'pending-customer',
      'validated',
      'confirmed',
      'prepared',
    ]}&customerDeck=${selectedBranch?._id}`;

    triggerGetOrder(orderQuery);
  }, [dateValue, selectedBranch, triggerGetOrder]);

  //accessing the actual dom is needed since
  //material ui doesn't support sub labels or label as
  //html in datepicker
  useEffect(() => {
    if (selectedBranch && dateValue) {
      const weekDayName = new Date(dateValue).toLocaleDateString('en-US', {
        weekday: 'long',
      });
      if (orders?.docs.length > 0) {
        if (contentWrapperRef?.current) {
          setOrderOnSameDate(true);
          const formControls = (
            contentWrapperRef.current as HTMLElement
          ).querySelectorAll('.MuiFormControl-root');
          formControls[0]
            .querySelector('.MuiInputBase-root')
            ?.querySelector('.not-typical-date')
            ?.remove();
          if (
            formControls.length > 0 &&
            formControls[0]
              .querySelector('.MuiInputBase-root')
              ?.querySelectorAll('.not-typical-date').length === 0
          ) {
            formControls[0]
              .querySelector('.MuiInputBase-root')
              ?.insertAdjacentHTML(
                'beforeend',
                `<span class='not-typical-date' style='color: #d32f2f'>${t(
                  'orders.create_orders.same_date_orders',
                )}</span>`,
              );
          }
        }
      } else if (
        !selectedBranch?.deliveryDays
          .map((e: string) => e.toLowerCase())
          .includes(weekDayName.toLowerCase())
      ) {
        if (contentWrapperRef?.current) {
          setOrderOnSameDate(false);
          const formControls = (
            contentWrapperRef.current as HTMLElement
          ).querySelectorAll('.MuiFormControl-root');
          formControls[0]
            .querySelector('.MuiInputBase-root')
            ?.querySelector('.not-typical-date')
            ?.remove();
          if (
            formControls.length > 0 &&
            formControls[0]
              .querySelector('.MuiInputBase-root')
              ?.querySelectorAll('.not-typical-date').length === 0
          ) {
            formControls[0]
              .querySelector('.MuiInputBase-root')
              ?.insertAdjacentHTML(
                'beforeend',
                `<span class='not-typical-date'>${t(
                  'orders.create_orders.not_typical_date',
                )}</span>`,
              );
          }
        }
      } else if (contentWrapperRef?.current && orders?.docs?.length) {
        console.log('here');
        setOrderOnSameDate(false);
        const formControls = (
          contentWrapperRef.current as HTMLElement
        ).querySelectorAll('.MuiFormControl-root');
        if (formControls.length > 0) {
          formControls[0]
            .querySelector('.MuiInputBase-root')
            ?.querySelector('.not-typical-date')
            ?.remove();
        }
      }
    }
    if (!selectedBranch) {
      if (contentWrapperRef?.current) {
        setOrderOnSameDate(false);
        const formControls = (
          contentWrapperRef.current as HTMLElement
        ).querySelectorAll('.MuiFormControl-root');
        if (formControls.length > 0) {
          formControls[0]
            .querySelector('.MuiInputBase-root')
            ?.querySelector('.not-typical-date')
            ?.remove();
        }
      }
    }
  }, [dateValue, t, orders, selectedBranch]);

  const handleDateValueChange = (newValue) => {
    setDateValue(newValue);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleOrderDeckChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  let formattedDate: any;

  const [createOrderBtnDisabled, setCreateOrderBtnDisabled] =
    useState<boolean>(true);

  const handlecreateOrderBtnDisable = () => {
    if (dateValue === null || radioValue === '' || !selectedBranch) {
      setCreateOrderBtnDisabled(true);
    } else {
      setCreateOrderBtnDisabled(false);
    }
  };

  useEffect(() => {
    handlecreateOrderBtnDisable();
  }, [dateValue, radioValue, selectedBranch]);

  const [createOrder] = useCreateOrderMutation();
  const [updateOrder] = useUpdateCustomerOrderMutation();
  const [deleteProductsFromCart] = useDeleteProductsFromCartMutation();
  const dispatch = useDispatch();

  const handleCreateOrder = async () => {
    if (formattedDate === null || radioValue === '') {
      setOpenAlert(true);
    } else {
      try {
        const result = await createOrder({
          deliveryDate: formattedDate,
          type: radioValue,
          deck: selectedBranch._id,
          templateId: selectedTemplate === null ? null : selectedTemplate,
          products: [],
          linkedDecks: [selectedBranch._id],
          customerId: customerId,
        }).unwrap();

        if (selectedProducts) {
          const formData: UpdateOrderFormData = {
            products: selectedProducts,
            discount: 0,
            customerId: customerId,
          };

          await updateOrder({
            id: result.createdOrder.id,
            formData,
          });

          const selectedProductIds = selectedProducts.map(
            (prod) => prod._product,
          );

          const paramsString = generateParamsString(selectedProductIds);
          dispatch(deleteProductsFromSelectedObject(selectedProductIds));

          await deleteProductsFromCart(paramsString);
        }

        navigate(`${result.createdOrder.id}`);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const [trigger, { data: templates }] = useLazyGetTemplatesOrderQuery();

  useEffect(() => {
    trigger('');
  }, []);

  const filteredTemplates = filterItems(
    templates?.templates,
    searchText.trim(),
    ['name'],
  );

  ///Multiples Branches
  const [triggerActiveOrder] = useLazyGetOrdersQuery();

  const [orderStatusArray, setOrderStatusArray] = useState<
    { branchId: string; isActive: boolean }[]
  >([]);

  const filters = {
    end: null,
  };

  const debouncedFilters = useDebounce(filters, 1000);

  const commonQueryParams = {
    end: debouncedFilters.end || '',
  };

  const fetchQueryParams = useQueryParams({
    ...commonQueryParams,
  });

  const order_ = useGetOrders(fetchQueryParams, !open);

  const [deliveryDates, updateDeliveryDates] = useState<string[]>([]);
  const orderDocs = order_?.docs || [];

  useEffect(() => {
    Promise.all(
      orderDocs.map(async (orderPromise) => {
        const order = await orderPromise;
        return order?.deliveryDate;
      }),
    )
      .then((newDeliveryDates) => {
        const reformattedDates = newDeliveryDates.map((date) =>
          date.substr(0, 10),
        );
        updateDeliveryDates((prevDeliveryDates) => [
          ...prevDeliveryDates,
          ...reformattedDates,
        ]);
      })
      .catch((error) => {
        console.error('Error fetching delivery dates:', error);
      });
  }, [order_, dateValue]);

  useEffect(() => {
    const formattedDateValue = moment(dateValue?.$d).format('YYYY/MM/DD');

    if (dateValue) {
      const updatedOrderStatusArray: { branchId: string; isActive: boolean }[] =
        [];

      decksId.forEach((id) => {
        if (!id) return;
        triggerActiveOrder(
          `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&ordoria=true&status=${[
            'pending',
            'pending-updates',
            'pending-customer',
            'validated',
            'confirmed',
            'prepared',
          ]}&customerDeck=${id}`,
        )
          .then((res) => {
            updatedOrderStatusArray.push({
              branchId: id,
              isActive: res.data.docs.length > 0,
            });

            setOrderStatusArray(updatedOrderStatusArray);
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
          });
      });
    }
  }, [dateValue]);

  // get selected date formatted like: YYYY MM DD
  formattedDate = `${dateValue?.$d?.getFullYear()} ${String(
    dateValue?.$d?.getMonth() + 1,
  ).padStart(2, '0')} ${String(dateValue?.$d?.getDate()).padStart(2, '0')}`;

  function handleBranches(event) {
    setSelectedBranch(event.target.value);
  }

  // get selected date formatted like: YYYY MM DD
  formattedDate = `${dateValue?.$d?.getFullYear()} ${String(
    dateValue?.$d?.getMonth() + 1,
  ).padStart(2, '0')} ${String(dateValue?.$d?.getDate()).padStart(2, '0')}`;

  function handleDeleteBranch(e) {
    e.preventDefault();
    setSelectedBranch(null);
  }

  useEffect(() => {
    if (decks?.length === 1) {
      setSelectedBranch(decks[0]);
    }
  }, [decks]);

  useEffect(() => {
    if (selectedBranch?._id && dateValue) {
      const weekDayName = new Date(dateValue).toLocaleDateString('en-US', {
        weekday: 'long',
      });

      const deliveryDaysLower = selectedBranch?.deliveryDays
        ? selectedBranch.deliveryDays.map((e: string) => e.toLowerCase())
        : [];

      setIsNotTypicalDate(
        !deliveryDaysLower.includes(weekDayName.toLowerCase()),
      );
    } else {
      setIsNotTypicalDate(false);
    }
  }, [dateValue, selectedBranch]);

  const allDeliveryDays = useMemo(() => {
    const allDays: string[] = selectedBranch?.deliveryDays || [];
    const uniqueDaysArray = Array.from(new Set(allDays));
    const titleCasedDays = uniqueDaysArray.map((day) => toTitleCase(day));
    return titleCasedDays.join(', ');
  }, [selectedBranch]);

  return (
    <DrawerComponent
      open={open}
      handleClose={closeDrawer}
      title={t('orders.create_orders')}
    >
      <Wrapper>
        {openAlert ? (
          <Snackbar
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            autoHideDuration={9000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              boxShadow:
                '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
            }}
          >
            <Alert
              severity="warning"
              sx={{ width: '506px' }}
              onClose={() => setOpenAlert(false)}
            >
              <AlertTitle>{t('order.typicalDate.title')}</AlertTitle>
              {t('order.typicalDate.subtitle')}
            </Alert>
          </Snackbar>
        ) : null}

        <ContentWrapper ref={contentWrapperRef}>
          <div className="orderType" style={{ paddingRight: '24px' }}>
            <Typography fontWeight="500" fontSize="0.9375rem">
              {t('clients.orderDetails.orderType')}
            </Typography>
            <FormControl sx={{ margin: '0 !important' }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="delivery"
                onChange={handleChange}
              >
                <Grid
                  container
                  sx={{
                    rowGap: '12px',
                    padding: '8px 0px',
                  }}
                >
                  <FormControlLabel
                    value="delivery"
                    label=""
                    control={
                      <ChipWithRadio
                        value="delivery"
                        label={t('common.delivery')}
                        selectedValue={radioValue}
                      />
                    }
                  />
                  <FormControlLabel
                    value="collection"
                    label=""
                    control={
                      <ChipWithRadio
                        value="collection"
                        label={t('roundtrips.tooltip.collection')}
                        selectedValue={radioValue}
                      />
                    }
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </div>

          <Stack className="datebranch" gap="8px" paddingRight="24px">
            <Typography
              fontSize="0.9375rem"
              lineHeight="1.5rem"
              fontWeight="500"
              letterSpacing="0.15px"
              color={themes.default.textBlack}
            >
              {t('orders.title.dateBranch')}
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{
                marginBottom: '5px !important',
              }}
            >
              <Grid container flexDirection="column" gap="12px">
                <InputsContainer>
                  <DatePicker
                    label={t('clients.orderDetails.deliveryDate')}
                    inputFormat="MMM DD, YYYY"
                    minDate={moment().format('YYYY/MM/DD')}
                    components={{
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    PopperProps={{
                      sx: {
                        width: '400px',
                        paddingLeft: '47px',
                        paddingTop: '10px',
                      },
                    }}
                    value={dateValue}
                    open={datePickerOpen}
                    onClose={() => setDatePickerOpen(false)}
                    onOpen={() => setDatePickerOpen(true)}
                    onChange={handleDateValueChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon icon={<CalendarBlank />} size={24} />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        label={t('clients.orderDetails.deliveryDate')}
                        onClick={() => setDatePickerOpen(true)}
                        focused={datePickerOpen}
                        sx={{
                          flex: '1',
                        }}
                        {...params}
                      />
                    )}
                    renderDay={(date, _, DayComponentProps) => {
                      let date_: any;
                      const selectedDate = dateValue?.$d?.getTime();
                      date_ = date;
                      const formattedDate_ = `${date_?.$y}-${(date_?.$M + 1)
                        .toString()
                        .padStart(2, '0')}-${date_?.$D
                        .toString()
                        .padStart(2, '0')}`;
                      date_ = date_?.$d?.getTime();

                      const datesAreMatching = date_ === selectedDate;
                      const isDateInDeliveryDates =
                        deliveryDates.includes(formattedDate_);

                      return (
                        <CalendarDayContainer>
                          <PickersDay
                            {...DayComponentProps}
                            outsideCurrentMonth={false}
                            selected={datesAreMatching}
                          />
                          {isDateInDeliveryDates ? (
                            <CalenderDayMark
                              color={datesAreMatching ? 'white' : ''}
                            />
                          ) : (
                            ''
                          )}
                        </CalendarDayContainer>
                      );
                    }}
                  />
                  <FormControl
                    sx={{
                      flex: '1',
                    }}
                  >
                    <InputLabel id="branch-select-label">
                      {t('orders.branch')}
                    </InputLabel>
                    <Select
                      label={t('orders.branch')}
                      labelId="branch-select-label"
                      id="branch-select"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        handleBranches(e);
                        handleOrderDeckChange(e);
                      }}
                      value={selectedBranch}
                      renderValue={(selected: any) => (
                        <Grid container alignItems="center">
                          {selected?.location.shortAddress ||
                            selected?.location.name}
                        </Grid>
                      )}
                    >
                      {decks?.map((deck) => (
                        <MenuItem
                          key={deck._id}
                          value={deck}
                          disabled={orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          )}
                        >
                          <Typography
                            fontSize="1rem"
                            fontWeight="400"
                            sx={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {deck?.location.shortAddress || deck?.location.name}
                          </Typography>
                          {selectedBranch?._id === deck._id &&
                          !orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          ) ? (
                            <IconButton
                              key={selectedBranch?._id}
                              sx={{
                                p: 0,
                                ml: 'auto',
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : null}
                          {orderStatusArray.some(
                            (status) =>
                              status.branchId === deck._id && status.isActive,
                          ) && (
                            <Typography
                              fontSize="0.75rem"
                              fontWeight={400}
                              sx={{
                                color: '#E65100',
                                fontStyle: 'italic',
                                ml: 'auto',
                              }}
                            >
                              Has an active order
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputsContainer>
                <Stack flexDirection="row" gap="10px" flexWrap="wrap">
                  <SalesPersonBadge>
                    <Icon icon={<User />} size={14} />
                    <Typography
                      fontSize="0.75rem"
                      lineHeight="1.5rem"
                      letterSpacing="0.0094rem"
                      fontWeight="300"
                      color={themes.default.textBlack}
                    >
                      {t('sale_person')}{' '}
                      <span style={{ fontWeight: '500' }}>
                        {data?.cache?._salesman?.fullName || ''}
                      </span>
                    </Typography>
                  </SalesPersonBadge>
                  {isNotTypicalDate && !orderOnSameDate && (
                    <NotTypicalDateBadge>
                      <Icon
                        icon={<Info />}
                        size={14}
                        color={themes.default.warningMain}
                      />
                      <Typography
                        fontSize="0.75rem"
                        lineHeight="1.5rem"
                        fontWeight="500"
                        letterSpacing="0.025rem"
                        color={themes.default.warningMain}
                      >
                        {t('orders.create_order.typical_ordering_days', {
                          days: allDeliveryDays,
                        })}
                      </Typography>
                    </NotTypicalDateBadge>
                  )}
                </Stack>
              </Grid>
            </LocalizationProvider>
          </Stack>

          <Grid>
            <Chip
              label={
                selectedBranch?.location.shortAddress ||
                selectedBranch?.location.name
              }
              size="small"
              deleteIcon={
                <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
              }
              onDelete={handleDeleteBranch}
              sx={{
                mr: '8px',
                mt: '12px',
              }}
            />
          </Grid>

          <div className="template">
            <Grid container alignItems="center" sx={{ paddingRight: '24px' }}>
              <Typography
                fontWeight="500"
                fontSize="0.9375rem"
                sx={{
                  mb: 2,
                }}
              >
                {t('orders.title.chooseTemplate')}
              </Typography>
              <TextField
                size="small"
                label={t('orders.search_templates')}
                value={searchText}
                onChange={handleSearchTextChange}
                sx={{
                  width: '276px',
                  ml: 'auto',
                  backgroundColor: '#F5F6F7',
                }}
                InputProps={{
                  startAdornment: <SearchIcon color="action" />,
                }}
              />
            </Grid>
            <Grid
              container
              sx={{
                paddingRight: '24px',
                overflow: 'auto',
                maxHeight: 'calc(100% - 55px)',
              }}
            >
              <Card
                sx={{
                  width: '170px',
                  mr: '13px',
                  height: '208px',
                  border:
                    selectedTemplate === null
                      ? '1px solid #B682FF'
                      : '1px solid transparent',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleTemplateClick(null)}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F5EEFF',
                    height: '77px',
                    margin: '0',
                    padding: '0',
                  }}
                >
                  <AddIcon
                    sx={{
                      height: '46px',
                      width: '46px',
                      color: '#6600F4',
                    }}
                  />
                </CardContent>
                <CardContent>
                  <Typography fontWeight="500" fontSize="0.9375rem">
                    {t('orders.blank')}
                  </Typography>
                  <Typography
                    fontSize="0.857rem"
                    fontWeight="400"
                    sx={{
                      color: 'grey',
                      mt: '1px',
                    }}
                  >
                    {t('orders.subtitle.create_order_groundUp')}
                  </Typography>
                </CardContent>
              </Card>

              {filteredTemplates?.map((template) => (
                <div key={template?._id}>
                  <TemplatesCards
                    name={template?.name}
                    description={template?.description}
                    weekDays={template?.weekDays}
                    productsNumber={template?.products.length}
                    isSelected={selectedTemplate === template?._id}
                    select={() => handleTemplateClick(template?._id)}
                    createdBy={template?._createdBy?.fullName}
                    type="orders"
                  />
                </div>
              ))}
            </Grid>
          </div>
        </ContentWrapper>
        <FooterWrapper>
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '16px',
              float: 'right',
              zIndex: '99',
            }}
          >
            <Button sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={closeDrawer}>
              {t('common.buttons.cancel')}
            </Button>
            {orderOnSameDate ? (
              <Button
                color="primary"
                disabled
                variant="contained"
                sx={{
                  borderRadius: '42px',
                  ml: '12px',
                }}
              >
                {t('common.buttons.proceed')}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disabled={createOrderBtnDisabled}
                onClick={handleCreateOrder}
                sx={{
                  borderRadius: '42px',
                  ml: '12px',
                }}
              >
                {t('common.buttons.proceed')}
              </Button>
            )}
          </Grid>
        </FooterWrapper>
      </Wrapper>
    </DrawerComponent>
  );
}

function useGetOrders(queryParams, skip = false) {
  const { data } = useGetOrdersQuery(`${queryParams}&page=${1}&limit=${360}`, {
    skip,
  });
  return data;
}

const Wrapper = styled.div`
  overflow: hidden !important;
  height: 100%;
`;

const FooterWrapper = styled.div`
  height: 62px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: solid 1px rgba(1, 1, 1, 0.12);
  background: white;
`;
const CalendarDayContainer = styled.div`
  display: flex;
  position: relative;
`;
const CalenderDayMark = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) =>
    props.color ? props.color : props.theme.primary};
`;
