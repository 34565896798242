import { Modal, Grow } from '@mui/material';
import { ChatToolbar } from '../Chat/components/ChatToolbar';
import styled, { CSSObject } from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDrawerPreferences } from 'common/store/app/selectors';
import { useDispatch } from 'react-redux';
import { appActions } from 'common/store/app';

interface Props {
  name: string; // used to grab saved viewmode from redux store
  open: boolean;
  onClose: () => void;
  toolbarTitle: string;
  defaultViewMode?: 'modal' | 'sidebar';
  sx?: CSSObject;
  children: JSX.Element;
}

export const AppDrawer = ({
  name,
  open,
  onClose,
  toolbarTitle,
  defaultViewMode,
  sx,
  children,
}: Props) => {
  const [viewMode, setViewMode] = useState<'modal' | 'sidebar'>(
    defaultViewMode || 'sidebar',
  );
  const drawerPreferences = useSelector(selectDrawerPreferences);

  const dispatch = useDispatch();

  /**
   * if a preference in store is saved
   * update ViewMode with saved preference
   */
  useEffect(() => {
    if (!drawerPreferences) return;
    const result = drawerPreferences.find((obj) => obj.key === name);
    if (result && result.prefferedViewMode !== viewMode) {
      setViewMode(result.prefferedViewMode);
    }
  }, [drawerPreferences]);

  /**
   * save ViewMode to store when changed
   */
  useEffect(() => {
    if (!name) return;
    let changed = false;

    const newDrawerPreferences = drawerPreferences?.map((pref) => {
      if (pref.key === name && pref.prefferedViewMode !== viewMode) {
        changed = true;
        return { key: name, prefferedViewMode: viewMode };
      }
      return pref;
    });

    if (!changed) return;

    dispatch(appActions.setDrawerPreferences(newDrawerPreferences));
  }, [viewMode]);

  return (
    <Modal open={open} onClose={onClose} style={{ zIndex: 999 }}>
      <Grow in={open} timeout={{ enter: 500, exit: 200 }}>
        <Wrapper isOnSide={viewMode === 'sidebar'} style={sx}>
          <SectionWrapper isOnSide={viewMode === 'sidebar'}>
            <ChatToolbar
              title={toolbarTitle}
              handleCloseChat={onClose}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
            <Section isOnSide={viewMode === 'sidebar'}>{children}</Section>
          </SectionWrapper>
        </Wrapper>
      </Grow>
    </Modal>
  );
};

export const Wrapper = styled.div<{ isOnSide?: boolean }>`
  display: flex;
  height: ${(props) =>
    props.isOnSide ? '100vh' : 'calc(100vh - 39px - 39px)'};
  max-width: 1040px;
  width: 100%;
  gap: 12px;
  position: ${(props) => (props.isOnSide ? 'absolute' : 'relative')};
  margin: ${(props) => (props.isOnSide ? '0' : '39px auto')};
  right: ${(props) => (props.isOnSide ? '0' : 'none')};
  transition: all 0.3s ease-in-out !important;
`;

export const SectionWrapper = styled.div<{ isOnSide?: boolean }>`
  flex: 1;
  background: linear-gradient(
    55deg,
    rgba(255, 0, 255, 0.3) -14.9%,
    rgba(176, 30, 255, 0.3) 10.07%,
    rgba(17, 89, 255, 0.3) 93.5%
  );
  padding: 2px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -24px 24px 48px -12px #674daf57;
  border-radius: ${(props) => (props.isOnSide ? '20px 0 0 0' : '20px')};
`;

const Section = styled.div<{ isOnSide?: boolean }>`
  flex: 1;
  background: ${themes?.default?.accordionWhiteBg};
  border-radius: ${(props) => (props.isOnSide ? '0' : '0 0 18px 18px')};
  display: flex;
  height: calc(100% - 50px);
  overflow: hidden;
`;
