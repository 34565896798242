import { createContext } from 'react';

interface ContextProps {
  customer: any;
  canEdit?: boolean;
  canExport?: boolean;
  isCustomer?: boolean;
}

export const CustomerFormcontext = createContext<ContextProps>({
  customer: null,
  canEdit: false,
  canExport: false,
  isCustomer: false,
});
