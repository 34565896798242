import { Box, Grid, Typography } from '@mui/material';
import { Check } from '@phosphor-icons/react';
import If from 'app/components/If';
import { SwipeableDrowerOrder } from 'app/pages/AddRoundTrips/components/SwipeableDrowerOrder';
import { useLazyGetOrdersIdQuery } from 'common/services/orderApi';
import { useLazyGetRoundTripDetailsQuery } from 'common/services/roundtripApi';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { NotificationType } from '../data';
import { isEmpty } from 'lodash';
import { RoundtripDrawer } from 'app/pages/Dashboard/components/RoundtripList/components/RoundtripDrawer';

export function ActionCard({ isReaden, activity, onCardClick }) {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [orderDetailsDrawer, setOrderDetailsDrawer] = useState<any>({});
  const [roundtripId, setRoundtripId] = useState<any>('');
  const [openOrderDetailsDrawer, setOpenOrderDetailsDrawer] =
    useState<boolean>(false);
  const [triggerGetOrder] = useLazyGetOrdersIdQuery();
  const [triggerGetRoundtrip] = useLazyGetRoundTripDetailsQuery();

  const openOrderDetail = () => {
    triggerGetOrder(activity?.data?.objectId).then((res) => {
      setOrderDetailsDrawer(res?.data);
    });
    setOpenOrderDetailsDrawer(true);
  };

  const openRoundtripMap = async () => {
    try {
      await triggerGetRoundtrip({ roundtripId: activity?.data?.objectId }).then(
        (res) => {
          setRoundtripId(res?.data?.id);
        },
      );
    } catch (err: any) {}
  };

  return (
    <>
      {openOrderDetailsDrawer && (
        <SwipeableDrowerOrder
          selectedOrder={orderDetailsDrawer}
          handleCloseOrderDrawer={() => {
            setOrderDetailsDrawer({});
            setOpenOrderDetailsDrawer(false);
          }}
          viewMode={false}
        />
      )}
      <RoundtripDrawer
        roundtripId={roundtripId}
        isOpen={!isEmpty(roundtripId)}
        handleClose={() => setRoundtripId('')}
      />
      <Box
        width="100%"
        height="fit-content"
        display="flex"
        flexDirection="column"
        padding="8px 12px"
        borderRadius="4px"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          backgroundColor: isReaden
            ? themes?.default?.lightGrey
            : themes?.default?.lightBlueBg,
          border: isReaden
            ? `1px solid ${themes?.default?.gainsboro2}`
            : `1px solid transparent`,
          backgroundImage: isReaden
            ? 'none'
            : `linear-gradient(${themes?.default?.lightBlueBg}, ${themes?.default?.lightBlueBg}), linear-gradient(180deg, #29b6f6 -0.09%, #2a59ff 105.26%)`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
          cursor: isReaden ? 'default' : 'pointer',
        }}
      >
        <Grid container alignItems="center">
          <Grid
            container
            alignItems="center"
            onClick={() => {
              onCardClick(activity?._id, activity?.read);
              if (activity?.data?.objectType === NotificationType.ORDER) {
                openOrderDetail();
              } else if (
                activity?.data?.objectType === NotificationType.ROUNDTRIP
              ) {
                openRoundtripMap();
              }
            }}
            sx={{
              cursor: 'pointer',
              width: isReaden
                ? 'calc(100% - 110px)'
                : isHovered
                ? 'calc(100% - 24px)'
                : 'calc(100% - 110px)',
            }}
          >
            <Grid item>
              <Typography fontWeight={700} fontSize="12px">
                {activity?.data?.action}
              </Typography>
              <Typography fontWeight={700} fontSize="12px">
                {activity?.data?.i18n?.[locale].title}
              </Typography>
              <Typography fontWeight={400} fontSize="12px">
                {activity?.data?.i18n?.[locale].body}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            onClick={() => {
              onCardClick(activity?._id, activity?.read);
            }}
            width="fit-content"
            ml="auto"
          >
            <If
              condition={isHovered && !activity?.read}
              otherwise={
                <Typography
                  fontWeight={400}
                  fontSize="12px"
                  color={themes.default.textColorSecondary}
                >
                  {activity?.createdAt
                    ? moment(activity?.createdAt).calendar({
                        sameDay: `[${t('notification.today')}] HH:mm`,
                        lastDay: `[${t('notification.yesterday')}] HH:mm`,
                        sameElse: 'DD MMM HH:mm',
                        lastWeek: 'DD MMM HH:mm',
                      })
                    : '-:-'}
                </Typography>
              }
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                gap="5px"
                sx={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '6px',
                  backgroundColor: themes?.default?.whiteSmokeBg,
                  border: `1px solid ${themes?.default?.gainsboro2}`,
                }}
              >
                <Check size={16} color={themes?.default?.grey2} />
              </Grid>
            </If>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
