import { Pagination, Stack, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo } from 'react';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from '../types';

export function ManagedGridPagination<T>() {
  const { t } = useTranslation();
  const {
    queryParams: { page, rowsPerPage, search, filters },
    totalRows,
    updateDataWithFunction,
  } = useManagedContext<IGridContext<T>>('grid');

  useEffect(() => {
    updateDataWithFunction((prev) => {
      prev.queryParams.page = 1;
    });
  }, [search, filters]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    updateDataWithFunction((prev) => {
      prev.queryParams.page = newPage + 1;
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateDataWithFunction((prev) => {
      prev.queryParams.rowsPerPage = parseInt(event.target.value, 10);
      prev.queryParams.page = 1;
    });
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalRows / rowsPerPage);
  }, [totalRows, rowsPerPage]);

  if (!totalRows) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      columnGap="20px"
    >
      <TablePagination
        labelRowsPerPage={t('rows_per_page')}
        rowsPerPageOptions={[10, 20, 30, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          position: 'sticky',
          bottom: 0,
          background: 'white',
          overflow: 'hidden',
          '.MuiTablePagination-select': { paddingTop: '5px' },
        }}
        ActionsComponent={() => {
          return null;
        }}
      />
      <Pagination
        color="primary"
        onChange={(_e, newPage) => {
          updateDataWithFunction((prev) => {
            prev.queryParams.page = newPage;
          });
        }}
        page={page}
        count={totalPages}
        shape="rounded"
      />
    </Stack>
  );
}
