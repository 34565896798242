import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';
import { Check, PaperPlaneTilt, X } from '@phosphor-icons/react';

import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import { AgentStatuses } from 'app/pages/Users/data/types';
import { useCancelInviteMutation } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { themes } from 'styles/theme/themes';
import { useChangeUserStatusMutation } from 'common/services/userApi';
import { CustomerFormcontext } from '../../../context';

interface EmailStatusProps {
  agent?: {
    _user: string;
    status: AgentStatuses;
    isLocked: boolean;
    lastViewAt: string;
  };
}

const ActionWrapper = styled.div<{ color?: string }>`
  color: ${(props) => props.color || props.theme.textLightGray};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${(props) => (props.color ? 'pointer' : '')};
  text-decoration: ${(props) => (props.color ? 'underline' : '')};
  font-size: 0.625rem;
  line-height: 175%;
  width: 120px;
`;

export const EmailStatus: React.FC<EmailStatusProps> = ({ agent }) => {
  const { customer, canEdit } = useContext(CustomerFormcontext);
  const { openModal, closeModal, setActionDisabled } = useModal();
  const { t } = useTranslation();
  const toast = useToaster();

  const [cancelInvite, { isLoading: isCancelling }] = useCancelInviteMutation();
  const [updateUserStatus, { isLoading: isUpdatingStatus }] =
    useChangeUserStatusMutation();

  const [isHovering, setIsHovering] = useState(false);

  const isUpdating = useMemo(() => {
    return isCancelling || isUpdatingStatus;
  }, [isCancelling, isUpdatingStatus]);

  const handleCancelInvitation = async (userId: string) => {
    try {
      setActionDisabled(true);
      await cancelInvite({
        id: customer?._id,
        userId,
      }).unwrap();
      setActionDisabled(true);

      toast(3000, 'success', t('user.invite.cancel.success'));
    } catch (error) {
      setActionDisabled(false);
    }
    closeModal();
  };

  const showCancelModal = (userId: string) => {
    openModal({
      title: t('user.invite.cancel.title'),
      content: t('user.invite.cancel.message'),
      cancel: true,
      action: {
        actionCallback: () => handleCancelInvitation(userId),
        actionText: t('common.buttons.ok'),
      },
    });
  };

  const toggleIsLocked = async (isLocked: boolean) => {
    try {
      await updateUserStatus({
        userIds: [agent?._user],
        isLocked,
        customerId: customer?._id,
      }).unwrap();
      toast(
        3000,
        'success',
        isLocked ? 'user.disabled_succcess' : 'user.enabled_succcess',
      );
    } catch (error) {}
  };

  const NotInvitedStatus = () => (
    <ActionWrapper>
      <X size={10} />
      {t('client.emails.status.notInvited')}
    </ActionWrapper>
  );

  const InvitationSentStatus = () => (
    <ActionWrapper>
      <PaperPlaneTilt size={10} />
      {t('info-invitation-sent')}
    </ActionWrapper>
  );

  const CancelInvitationButton = () => (
    <ActionWrapper
      color={themes.default.chipRedBg}
      onClick={() =>
        !!agent?._user && !isUpdating && showCancelModal(agent?._user)
      }
    >
      <X size={10} />
      {t('common.cancel_invitation')}
    </ActionWrapper>
  );

  const ActiveUserStatus = () => (
    <If condition={!isHovering} otherwise={<DisableUserButton />}>
      <Typography fontSize="0.625rem" color={themes.default.fruitSaladGreen}>
        <Stack direction="row" gap="4px" alignItems="center">
          <Check size={10} />
          <span>{t('last_active')}</span>
          <span>{moment(agent?.lastViewAt).format('DD/MM/YYYY')}</span>
        </Stack>
      </Typography>
    </If>
  );

  const EnableUserButton = () => {
    return (
      <ActionWrapper
        color={themes.default.primaryActiveColor}
        onClick={() => {
          toggleIsLocked(false);
        }}
      >
        <Check size={10} />
        {t('user.enable')}
      </ActionWrapper>
    );
  };

  const DisableUserButton = () => {
    return (
      <ActionWrapper
        color={themes.default.chipRedBg}
        onClick={() => {
          toggleIsLocked(true);
        }}
      >
        <X size={10} />
        {t('user.disable')}
      </ActionWrapper>
    );
  };

  return (
    <Box
      onMouseEnter={() => !!canEdit && setIsHovering(true)}
      onMouseLeave={() => !!canEdit && setIsHovering(false)}
    >
      <If
        condition={!agent || agent?.status === AgentStatuses.Inactive}
        otherwise={
          <If
            condition={agent?.status === AgentStatuses.Active}
            otherwise={
              <If condition={!!isHovering} otherwise={<InvitationSentStatus />}>
                <CancelInvitationButton />
              </If>
            }
          >
            <If condition={!!agent?.isLocked} otherwise={<ActiveUserStatus />}>
              <EnableUserButton />
            </If>
          </If>
        }
      >
        <NotInvitedStatus />
      </If>
    </Box>
  );
};
