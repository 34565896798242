import api from './api';

export const chatApis = api.injectEndpoints({
  endpoints: builder => ({
    getAllChats: builder.query<
      any,
      { cursor: string; text: string; limit?: number; offset?: number }
    >({
      query: ({ cursor = '', text = '', limit = 15, offset = 0 }) => ({
        url: `/chat?text=${text}&cursor=${cursor}&limit=${limit}&offset=${offset}`,
      }),
      providesTags: ['Chats'],
    }),
    getAllContactsList: builder.query<any, any>({
      query: params => ({
        url: `/users/chat/list${params}`,
      }),
    }),
    getChatCount: builder.query<any, {}>({
      query: () => ({
        url: `/chat/unreadCount`,
      }),
      providesTags: ['Chats'],
    }),
    getChatMessages: builder.query<
      any,
      {
        messageId: string;
        chatId: string;
        cursor: any;
        text: string;
        limit?: number;
      }
    >({
      query: ({ messageId = '', chatId, cursor, text, limit }) => ({
        url: `/chat/messages?id=${messageId}&chatId=${chatId}&cursor=${cursor}&text=${text}&limit=${
          limit || ''
        }`,
      }),
      providesTags: ['Chats'],
    }),
    postStartChat: builder.mutation<any, any>({
      query: userId => ({
        url: `/chat/${userId.userId}`,
        method: 'POST',
      }),
    }),
    getUnreadMessagesCount: builder.query<any, { userId: string }>({
      query: ({ userId }) => ({
        url: `/chat/unread-messages-count/${userId}`,
      }),
      providesTags: ['Chats'],
    }),

    deleteChat: builder.mutation<any, any>({
      query: ({ ids, clearChat = false }: any) => {
        return {
          url: `/chat/delete-chats`,
          method: 'PATCH',
          body: { ids, clearChat },
        };
      },
    }),
    togglePinChat: builder.mutation<any, any>({
      query: ({ id }: any) => {
        return {
          url: `/chat/${id}/toggle-pin`,
          method: 'PATCH',
        };
      },
    }),
    togglePinChats: builder.mutation<any, any>({
      query: ({ ids }: any) => {
        return {
          url: `/chat/toggle-pin`,
          method: 'PATCH',
          body: { ids },
        };
      },
    }),
    editChatMessage: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => {
        return {
          url: `/chat/${id}/edit-message`,
          method: 'PATCH',
          body,
        };
      },
    }),
    markReadUnread: builder.mutation<any, { body: any }>({
      query: ({ body }) => {
        return {
          url: `/chat/mark-unread`,
          method: 'PATCH',
          body,
        };
      },
    }),
    deleteChatMessage: builder.mutation<
      any,
      { body: { uuids: string[]; deleteForBoth: boolean } }
    >({
      query: ({ body }) => {
        return {
          url: '/chat/messages',
          method: 'PATCH',
          body: body,
        };
      },
    }),
    clearChatHistory: builder.mutation<any, { body: { ids: string[] } }>({
      query: ({ body }) => {
        return {
          url: '/chat/clear-chats',
          method: 'PATCH',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetAllChatsQuery,
  useGetAllContactsListQuery,
  useLazyGetAllContactsListQuery,
  useGetChatCountQuery,
  useLazyGetAllChatsQuery,
  useGetChatMessagesQuery,
  usePostStartChatMutation,
  useDeleteChatMutation,
  useTogglePinChatMutation,
  useEditChatMessageMutation,
  useTogglePinChatsMutation,
  useMarkReadUnreadMutation,
  useDeleteChatMessageMutation,
  useClearChatHistoryMutation,
  useGetUnreadMessagesCountQuery,
} = chatApis;

export default chatApis;
