import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IOrderDetailsContext } from '../..';
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { InfoCard } from 'app/components/InfoCard';
import dayjs from 'dayjs';
import { CalendarBlank, Check, Checks, User, X } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';
import React, { useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import If from 'app/components/If';
import { SupportUnitsTable } from 'app/components/OrderDetail/tabs/OrderInformation/components/SupportUnitsTable';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { useToaster } from 'hooks/useToaster';
import { OutlinedChip } from 'app/components/OutlinedChip';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import { OrderStatus } from 'types';

export const OrderInfo = () => {
  const { t } = useTranslation();
  const toast = useToaster();
  const { order, canEdit, refetch, height } =
    useManagedContext<IOrderDetailsContext>('orderDetails');
  const [updateOrder] = useUpdateOrderMutation();
  const { preferredAgent } = usePreferredAgent({ user: order?._createdBy });
  const [toCollectValue, setToCollectValue] = useState(
    order?.amountRequested || 0,
  );

  const formatCurrency = useFormatCurrency();

  const handlePaymentCollectionChange = async (newValue) => {
    if (order) {
      try {
        const resp = await updateOrder({
          id: order?._id,
          amountRequested: newValue,
        }).unwrap();

        if (resp) {
          toast(
            5000,
            'success',
            'clients.orderDetails.paymentCollectionSuccess',
          );
          refetch(order?._id);
        }
      } catch (e: any) {
        if (e.status === 401) {
          console.log(e);
        }
      }
    }
  };

  function handleSaveNewAmout() {
    const newValue = parseFloat(toCollectValue);
    handlePaymentCollectionChange(newValue);
  }

  const DeliveryRow = ({
    label,
    value,
    marginButton = 'auto',
  }: {
    label: string;
    value: React.ReactNode;
    marginButton?: number | string;
  }) => {
    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        marginBottom={marginButton}
        gap="12px"
      >
        <Typography
          fontSize="0.75rem"
          color={themes.default.nobel}
          width="50%"
          lineHeight="normal"
        >
          {t(label)}
        </Typography>
        <Typography fontSize="0.75rem" lineHeight="normal">
          {value}
        </Typography>
      </Stack>
    );
  };

  const rountripPosition = useMemo(() => {
    if (isEmpty(order?.roundtripCode)) {
      return 'N/A';
    }
    return `${order?.roundtripCode} / ${
      order?.roundtripPosition ? order?.roundtripPosition : 'No Position'
    }`;
  }, [order?.roundtripCode, order?.roundtripPosition]);

  const showSupportUnits = useMemo(() => {
    return (
      order?.supportUnitsList &&
      order?.status !== OrderStatus.Validated &&
      order?.status !== OrderStatus.Confirmed &&
      order?.status !== OrderStatus.Prepared
    );
  }, [order?.status, order?.supportUnitsList]);

  return (
    <Stack height="100%" maxHeight={`calc(${height}px - 102px)`}>
      <Box position="sticky" top="0" zIndex={2}>
        <Stack
          direction="row"
          gap="8px"
          padding="8px 24px"
          borderBottom={`1px solid ${themes.default.gainsboro2}`}
          height="40px"
          alignItems="center"
          sx={{
            background: 'white',
          }}
        >
          <Typography>{t('order')}</Typography>
          <Typography fontWeight="600">#{order?.code}</Typography>

          {order?.status === OrderStatus.Delivered && (
            <OutlinedChip
              content={
                <>
                  {order?.paymentCollection >= order?.amountRequested ? (
                    <Icon
                      icon={<Checks />}
                      tooltip={t('tooltip.payment_fully_collected')}
                      color={themes?.default?.fruitSaladGreen}
                    />
                  ) : order?.paymentCollection < order?.amountRequested &&
                    order?.paymentCollection !== 0 ? (
                    <Icon
                      icon={<Check />}
                      tooltip={t('tooltip.payment_partially_collected')}
                      color={themes?.default?.fruitSaladGreen}
                    />
                  ) : (
                    <Icon
                      icon={<X />}
                      tooltip={t('tooltip.payment_not_collected')}
                      color={themes?.default?.redA700}
                    />
                  )}
                </>
              }
            />
          )}
        </Stack>
      </Box>

      <Stack overflow="auto">
        <Box>
          <Stack
            gap="12px"
            position="static"
            top="12px"
            padding="12px 24px"
            sx={{ background: 'white' }}
          >
            <Stack direction="row" gap="12px">
              <InfoCard
                title={t('created_on')}
                value={dayjs(order?.createdAt)?.format('D MMM YYYY')}
                icon={
                  <Icon
                    icon={<CalendarBlank />}
                    color={themes.default.primaryActiveColor}
                  />
                }
                iconBg={themes.default.primaryLight1}
              />
              <InfoCard
                title={t('clients.orderDetails.deliveryDate')}
                value={dayjs(order?.deliveryDate)?.format('D MMM YYYY')}
                icon={
                  <Icon
                    icon={<CalendarBlank />}
                    color={themes.default.primaryActiveColor}
                  />
                }
                iconBg={themes.default.primaryLight1}
              />
            </Stack>
            <Stack direction="row" gap="12px">
              <InfoCard
                title={t('created_by')}
                value={preferredAgent?.fullName || '-'}
                icon={
                  <Icon
                    icon={<User />}
                    color={themes.default.primaryActiveColor}
                  />
                }
                iconBg={themes.default.primaryLight1}
              />
              <InfoCard
                title={t('clients.orderDetails.validatedBy')}
                value={order?._validatedBy?.fullName || '-'}
                icon={
                  <Icon
                    icon={<User />}
                    color={themes.default.primaryActiveColor}
                  />
                }
                iconBg={themes.default.primaryLight1}
              />
            </Stack>
          </Stack>
        </Box>

        <Box>
          <Stack gap="24px" padding="12px 24px">
            <Stack gap="12px">
              <Typography fontWeight="600" fontSize="0.875rem">
                {t('delivery')}
              </Typography>
              <DeliveryRow
                label="clients.orderDetails.numberLines"
                value={order?.countLines}
              />
              <DeliveryRow
                label="clients.orderDetails.numberPackages"
                value={order?.countPackages}
              />
              <DeliveryRow
                label="clients.orderDetails.weightOnVolume"
                value={`${order?.totalWeight?.toFixed(2)}kg / 0m³`}
              />
              <DeliveryRow
                label="ca_kg"
                value={order?.cache?.stats?.amountPerWeight?.toFixed(2)}
              />
              <DeliveryRow
                label="clients.orderDetails.RoundtripOnPosition"
                value={rountripPosition}
              />
              <If condition={!!order?.deliveryComment}>
                <DeliveryRow
                  label="clients.orderDetails.deliveryComment"
                  value={order?.deliveryComment}
                />
              </If>
            </Stack>

            <Stack gap="12px">
              <Typography fontWeight="600" fontSize="0.875rem">
                {t('clients.orderDetails.supportUnit')}
              </Typography>

              <If
                condition={showSupportUnits}
                otherwise={
                  <If condition={!!order?.supportUnitsList}>
                    <DeliveryRow
                      label="storage.capacity"
                      marginButton={-3}
                      value={
                        <Stack
                          direction="row-reverse"
                          alignItems="center"
                          gap={0.5}
                          marginTop={-1}
                        >
                          {order?.supportUnitsRequestedCapacity}
                          <SupportUnitsPopover
                            order={order}
                            updateOrderFunction={updateOrderFunction}
                            canEdit={canEdit}
                            widthButton={37}
                            supportUnits={{
                              ...order?.supportUnitsList,
                              loaded: order?.supportUnitListLocations,
                            }}
                            refreshData={() => {
                              refetch(order?._id);
                            }}
                          />
                        </Stack>
                      }
                    />
                  </If>
                }
              >
                <SupportUnitsTable
                  supportUnits={{
                    ...order?.supportUnitsList,
                    delivered:
                      order?.status === OrderStatus.Delivered
                        ? order?.supportUnitsList?.delivered
                        : [],
                    loaded: order?.supportUnitListLocations,
                  }}
                />
              </If>
            </Stack>

            <Stack gap="12px">
              <DeliveryRow
                label="order.itemsList.subtotal"
                value={formatCurrency((order?.totalAmount || 0)?.toFixed(2))}
              />
              <DeliveryRow
                label="order.itemsList.shipping"
                value={formatCurrency(order?.deliveryCost || 0)}
              />
              <DeliveryRow
                label={`${t('order.itemsList.tax')} 5%`}
                value={`${formatCurrency(
                  (order?.totalAmountTax || 0).toFixed(2) -
                    (order?.totalAmount || 0).toFixed(2),
                )}`}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ backgroundColor: themes.default.lightGrey }}
            padding="8px 24px"
          >
            <Typography fontSize="0.75rem">{t('total_ttc')}</Typography>
            <Typography fontSize="0.875rem">
              {formatCurrency((order?.totalAmountTax || 0).toFixed(2) || 0)}
            </Typography>
          </Stack>
        </Box>
      </Stack>

      <Stack
        position="sticky"
        bottom="0px"
        flex="1"
        justifyContent="end"
        gap="12px"
        paddingTop="12px"
        sx={{ background: 'white' }}
      >
        <Typography
          fontWeight="600"
          fontSize="0.875rem"
          paddingX="24px"
          boxShadow={themes.default.topShadow}
        >
          <p>{t('common.collection')}</p>
        </Typography>
        <Box
          padding="8px 24px"
          sx={{ backgroundColor: `${themes.default.green}0A` }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY="8px"
          >
            <Typography fontSize="0.75rem">
              {t('payment_to_collect')}
            </Typography>
            {order?.status !== OrderStatus.Delivered && !!canEdit ? (
              <TextField
                variant="outlined"
                size="small"
                value={toCollectValue}
                sx={{
                  width: '76px',
                  margin: '0 !important',
                  '& input': {
                    padding: '4px 12px !important',
                    height: '24px',
                    fontSize: '0.8125rem',
                    letterSpacing: '0.17',
                  },
                  '& fieldset': {
                    border: `1px solid ${themes?.default?.gainsboro2}`,
                    transitionDuration: '0.15s',
                  },
                  '& fieldset:hover': {
                    border: `1px solid ${themes?.default?.iconColor} !important`,
                  },
                }}
                onKeyDown={(e) => {
                  const isNumberKey = e.key.match(/[0-9.,€$]/);
                  const isNavigationKey = [
                    'Backspace',
                    'Delete',
                    'ArrowLeft',
                    'ArrowRight',
                    'Tab',
                  ].includes(e.key);
                  if (!isNumberKey && !isNavigationKey) {
                    if (!e?.ctrlKey) {
                      e?.preventDefault();
                    }
                  }
                }}
                onChange={(e) => {
                  const value = e?.target?.value;
                  setToCollectValue(value);
                }}
                onBlur={() => {
                  handleSaveNewAmout();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            ) : (
              <Typography fontSize="0.75rem">
                {formatCurrency(toCollectValue || 0)}
              </Typography>
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY="8px"
          >
            <Typography fontSize="0.75rem">{t('amount_collected')}</Typography>
            <Typography
              fontWeight="500"
              fontSize="0.875rem"
              color={themes.default.greenMain}
            >
              {formatCurrency(order?.paymentCollection || 0)}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
