import { Grid, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import {
  useActivitiesReadMutation,
  useGetActivitiesQuery,
} from 'common/services/activityApi';
import { ActionCard } from './components/ActionCard';
import { UnderToolbarNotification } from './components/UnderToolbarNotification';
import If from '../If';
import { GroupedVirtuoso } from 'react-virtuoso';
import { groupBy } from 'lodash';
import EmptyState from '../../../assets/img/notifications/EmptyState.svg';
import { useTranslation } from 'react-i18next';
import { DateButton } from '../Chat/components/ChatWindow/components/DateButton';
import { scrollToBeginning } from './helpers';
import dayjs from 'dayjs';
import { useGetAuthActivitiesStatsQuery } from 'common/services/authApi';
import { AppDrawer } from '../AppDrawer';

export function Notifications({ open, onClose }) {
  const [limit, setLimit] = useState(30);
  const [selectedUnreadIds, setSelectedUnreadIds] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [fetchedActivites, setFetchedActivities] = useState<any>([]);

  const { t } = useTranslation();

  /* --------- API CALLS ---------- */

  const {
    data: activities,
    isLoading,
    isFetching,
    refetch,
  } = useGetActivitiesQuery(`limit=${limit}&important=${tabIndex === 0}`);

  const { refetch: refetchStat, data: activityStats } =
    useGetAuthActivitiesStatsQuery('');

  const { totalImportant, totalOther } = activityStats || {};

  const [readActivities] = useActivitiesReadMutation();

  /* --------- USE EFFECTS ---------- */

  useEffect(() => {
    if (open && !isFetching) {
      refetch();
      refetchStat();
    }
  }, [open, tabIndex]);

  useEffect(() => {
    if (activities) {
      setFetchedActivities(activities?.docs);
    }
  }, [activities]);

  useEffect(() => {
    if (fetchedActivites.length > 0 && open) {
      const unreaded = fetchedActivites?.filter(
        (activity) =>
          activity?.read === false &&
          !selectedUnreadIds.includes(activity?._id),
      );

      setSelectedUnreadIds((prevIds) => [
        ...prevIds,
        ...unreaded.map((activity) => activity?._id),
      ]);
    }
  }, [fetchedActivites, open, activities]);

  /* --------- HANDLERS ---------- */

  function readNotifications() {
    if (selectedUnreadIds.length > 0) {
      const updatedActivities = fetchedActivites.map((activity) => ({
        ...activity,
        read: true,
      }));

      setFetchedActivities(updatedActivities);
      readActivities({ activityIds: selectedUnreadIds }).then(() => {
        refetchStat();
      });
      setSelectedUnreadIds([]);
    }
  }

  function handleReadNotification(id: string, isRead) {
    if (!isRead) {
      const updatedNotifications = fetchedActivites.map((notification) =>
        notification._id === id
          ? { ...notification, read: true }
          : notification,
      );

      setFetchedActivities(updatedNotifications);

      readActivities({ activityIds: [id] }).then(() => {
        refetchStat();
      });
      setSelectedUnreadIds([]);
    }
  }

  function handleTabChange(newValue) {
    setTabIndex(newValue);
  }

  function loadMore() {
    if (activities?.nextPage) {
      setLimit((prevLimit) => prevLimit + 5);
    }
  }

  /* --------- Activities Sorting By Date ---------- */

  const groupedActivitiesByDate = useMemo(() => {
    return groupBy(fetchedActivites, (activity) => {
      const activityDate = dayjs(activity?.date);
      const today = dayjs().startOf('day');
      const yesterday = dayjs().subtract(1, 'days').startOf('day');

      if (activityDate.isSame(today, 'day')) {
        return t('calendar.today');
      } else if (activityDate.isSame(yesterday, 'day')) {
        return t('yesterday');
      } else {
        return activityDate.format('dddd, MMMM Do');
      }
    });
  }, [fetchedActivites]);

  const groupHeaders = Object.keys(groupedActivitiesByDate);

  const groupCounts = Object.values(groupedActivitiesByDate).map(
    (group) => group.length,
  );

  const scrollRef: any = useRef();

  function handleScrollToGroup(formattedDate) {
    const index = fetchedActivites.findIndex((activity) => {
      const activityDate = dayjs(activity?.date);
      const today = dayjs().startOf('day');
      const yesterday = dayjs().subtract(1, 'days').startOf('day');

      let formattedActivityDate;
      if (activityDate.isSame(today, 'day')) {
        formattedActivityDate = t('calendar.today');
      } else if (activityDate.isSame(yesterday, 'day')) {
        formattedActivityDate = t('yesterday');
      } else {
        formattedActivityDate = activityDate.format('dddd, MMMM Do');
      }

      return formattedActivityDate === formattedDate;
    });

    if (index !== -1) {
      scrollRef.current?.scrollToIndex({ index: index, behavior: 'smooth' });
    }
  }

  return (
    <AppDrawer
      name="notifications"
      open={open}
      onClose={onClose}
      toolbarTitle={t('modules.notifications')}
    >
      <Stack width="100%">
        <UnderToolbarNotification
          readNotifications={readNotifications}
          totalImportant={totalImportant}
          totalOther={totalOther}
          tabIndex={tabIndex}
          onTabChange={(newValue) => handleTabChange(newValue)}
        />
        <ChatSection>
          <If condition={!isLoading}>
            <Grid
              container
              sx={{
                overflow: 'auto',
                height: 'calc(100% - 20px)',
              }}
            >
              <Grid container flexDirection="column">
                <If
                  condition={fetchedActivites.length > 0}
                  otherwise={
                    <Grid
                      container
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ height: '100%' }}
                    >
                      <img src={EmptyState} alt="Empty State" />
                      <Typography>{t('notifications.empty-state')}</Typography>
                    </Grid>
                  }
                >
                  <GroupedVirtuoso
                    ref={scrollRef}
                    style={{ width: '100%', paddingTop: '6px' }}
                    groupContent={(groupIndex) => (
                      <>
                        <DateButton
                          date={groupHeaders[groupIndex]}
                          key={groupIndex}
                          handleScrollToBeginning={() =>
                            scrollToBeginning(scrollRef)
                          }
                          handleScrollToGroup={(formattedDate) =>
                            handleScrollToGroup(formattedDate)
                          }
                        />
                        <HorizentalLineCentered top={true} />
                      </>
                    )}
                    groupCounts={groupCounts}
                    itemContent={(index) => {
                      const activity = fetchedActivites[index];

                      return (
                        <If
                          condition={index === 0}
                          otherwise={
                            <div
                              style={{
                                marginBottom: '12px',
                                padding: '0px 20px 0px 20px',
                              }}
                            >
                              <ActionCard
                                key={activity?._id}
                                activity={activity}
                                onCardClick={() =>
                                  handleReadNotification(
                                    activity?._id,
                                    activity?.read,
                                  )
                                }
                                isReaden={activity?.read}
                              />
                            </div>
                          }
                        >
                          <div
                            style={{
                              marginBottom: '12px',
                              padding: '0px 20px 0px 20px',
                              marginTop: '26px',
                            }}
                          >
                            <ActionCard
                              key={activity?._id}
                              activity={activity}
                              onCardClick={() =>
                                handleReadNotification(
                                  activity?._id,
                                  activity?.read,
                                )
                              }
                              isReaden={activity?.read}
                            />
                          </div>
                        </If>
                      );
                    }}
                    endReached={loadMore}
                  />
                </If>
              </Grid>
            </Grid>
          </If>
        </ChatSection>
      </Stack>
    </AppDrawer>
  );
}

const ChatSection = styled.div<{ isOnSide?: boolean }>`
  flex: 1;
  background: ${themes?.default?.accordionWhiteBg};
  border-radius: ${(props) => (props.isOnSide ? '0' : '0 0 18px 18px')};
  display: flex;
  height: calc(100% - 50px);
`;

export const HorizentalLineCentered = styled.div<{ top?: boolean }>`
  width: 100%;
  height: 0.5px;
  background: ${themes?.default?.gainsboro2};
  position: absolute;
  bottom: ${(props) => (props.top ? '' : '-25px')};
  top: ${(props) => (props.top ? '12px' : '')};
  z-index: 1;
  box-shadow: 7px 0 0 ${themes?.default?.gainsboro2};
`;
