import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { themes } from 'styles/theme/themes';
import If from '../If';

export const CardSkeleton = ({
  withCircle = true,
}: {
  withCircle?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '8px 16px',
        alignItems: 'flex-start',
        gap: '12px',
        flex: '1 0 0',
        borderRadius: '8px',
        border: `1px solid ${themes.default.gainsboro2}`,
        background: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            gap: '4px',
          }}
        >
          <Skeleton
            variant="rectangular"
            width={120}
            height={14}
            sx={{
              borderRadius: '4px',
              backgroundColor: `${themes.default.gainsboro2}`,
            }}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            width={80}
            height={20}
            sx={{
              borderRadius: '4px',
              backgroundColor: `${themes.default.gainsboro2}`,
            }}
            animation="wave"
          />
        </Box>
        <If condition={!!withCircle}>
          <Skeleton
            variant="circular"
            width={51.617}
            height={51.617}
            sx={{
              backgroundColor: `${themes.default.gainsboro2}`,
            }}
            animation="wave"
          />
        </If>
      </Box>
    </Box>
  );
};
