import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useModal } from 'app/components/Dialog/hooks';
import If from 'app/components/If';
import { StatusCustomer } from 'app/components/StatusCustomer';
import { useDebounce } from 'common/hooks/useDebounce';
import { useChangeCustomerStatusMutation } from 'common/services/customerApi';
import { CustomerStatus } from 'common/types/Customer';
import { clientStatusReasons } from 'common/utils/clientStatusReasons';
import { useToaster } from 'hooks/useToaster';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

export const useChangeStatus = () => {
  const { t } = useTranslation();
  const toast = useToaster();
  const theme = useSelector(selectTheme);
  const [updateStatus] = useChangeCustomerStatusMutation();

  const {
    openModal: openConfirmation,
    closeModal,
    setActionDisabled,
    setActionButton,
  } = useModal();

  const updateCustomer = async (
    id: string,
    status: CustomerStatus,
    reason: string,
  ) => {
    try {
      setActionDisabled(true);
      await updateStatus({
        customerIds: [id],
        status,
        reason,
      }).unwrap();
      toast(3000, 'success', t('client_status_update_success'));
      closeModal();
    } catch (error) {
    } finally {
      setActionDisabled(false);
    }
  };

  const ModalContent = ({ customer, status }) => {
    const [showText, setShowText] = useState(false);
    const [text, setText] = useState('');

    const textValue = useDebounce(text, 300);

    useEffect(() => {
      setActionButton({
        actionText: t('confirm'),
        actionCallback: () => updateCustomer(customer._id, status, textValue),
        buttonProps: { disabled: !textValue },
      });
    }, [customer?._id, textValue, status]);

    return (
      <Stack gap="10px">
        <Stack direction="row" gap="5px" alignItems="center" fontSize="1rem">
          <Typography sx={{ textWrap: 'nowrap' }}>
            {t('customer.updating_status', { code: `#${customer.code}` })}
          </Typography>
          <Stack direction="row" gap="5px" alignItems="center">
            <Typography>{t('common.from')}</Typography>
            <StatusCustomer status={customer.status} />
          </Stack>
          <Stack direction="row" gap="5px" alignItems="center">
            <Typography>{t('common.to')}</Typography>
            <StatusCustomer status={status} />
          </Stack>
        </Stack>

        <Stack gap="14px">
          <Typography fontSize="0.75rem" fontWeight="400" color={theme.nobel}>
            {t('customer.status_update_reason')}
          </Typography>
          <FormControl>
            <InputLabel size="small" id="reason-label">
              {t('common.reason')}
            </InputLabel>
            <Select
              size="small"
              labelId="reason-label"
              onChange={(e) => {
                if (e.target.value === 'other') {
                  setShowText(true);
                  setActionDisabled(true);
                } else {
                  setShowText(false);
                  setActionButton({
                    actionText: t('confirm'),
                    actionCallback: () =>
                      updateCustomer(customer?._id, status, e.target.value),
                    buttonProps: { disabled: !e.target.value },
                  });
                }
              }}
              placeholder={t('common.reason')}
              label={t('common.reason')}
              defaultValue=""
            >
              {clientStatusReasons.map((reason) => (
                <MenuItem
                  key={reason}
                  value={reason}
                  sx={{
                    color: reason === 'other' ? theme.primaryActiveColor : '',
                  }}
                >
                  {t(reason)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <If condition={!!showText}>
            <TextField
              label={t('common.specify_reason')}
              size="small"
              fullWidth
              rows="3"
              multiline={true}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </If>
        </Stack>
      </Stack>
    );
  };

  const openModal = ({ customer, status }) => {
    openConfirmation({
      title: t('justify_client_status_update'),
      content: <ModalContent customer={customer} status={status} />,
      sx: {
        '& .MuiPaper-root': {
          maxWidth: 'none',
        },
      },
      action: {
        actionText: t('confirm'),
        actionCallback: () => updateCustomer(customer?._id, status, ''),
        buttonProps: {
          disabled: true,
        },
      },
      cancel: true,
    });
  };

  return {
    openModal,
  };
};
