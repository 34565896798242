import { useModal } from 'app/components/Dialog/hooks';
import { ImageUpload } from 'app/components/Form/ImageUpload';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import { UploadedFile } from 'app/components/UploadComponent';
import { useUpdateCustomerMutation } from 'common/services/customerApi';
import { useUploadMutation } from 'common/services/files';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { CustomerFormcontext } from '../../context';

export const ClientLogo = () => {
  const { customer, canEdit } = useContext(CustomerFormcontext);
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const [uploadFile] = useUploadMutation();
  const [updateCustomer] = useUpdateCustomerMutation();

  const [logo, setLogo] = useState(customer?.logo?.[0] || null);

  const uploadImage = async (image: UploadedFile | null) => {
    if (image) {
      try {
        const formData = new FormData();
        formData.append('file', image);

        const response = await uploadFile({
          formData,
          preserveName: false,
          persist: false,
          objectType: 'customers',
          objectId: customer?._id,
        }).unwrap();
        updateCustomer({
          id: customer?._id,
          logo: response,
        }).unwrap();
      } catch (e) {
        console.error('Error uploading images:', e);
      }
    }
  };

  const deleteImage = async () => {
    openModal({
      title: t(`accessories.delete_image`),
      content: t(`accessories.delete_image_confirmation`),
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          updateCustomer({
            id: customer?._id,
            logo: [],
          }).unwrap();
          setLogo(null);
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  };

  return (
    <FormSection>
      <SectionTitle>{t('client_logo')}</SectionTitle>
      <ImageUpload
        imageUrl={logo?.url || ''}
        description={t('add-client-logo-description')}
        onUpload={(files) => {
          const file = files?.[0];
          if (!file) return;
          setLogo({ url: URL.createObjectURL(file), size: file.size });
          uploadImage(file);
        }}
        onDelete={() => {
          deleteImage();
        }}
        disabled={!canEdit}
      />
    </FormSection>
  );
};
