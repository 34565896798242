import { ReportsToType } from 'app/pages/Settings/Users/types';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { selectOrganization } from 'common/store/organization/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const usePreferredAgent = ({ user }) => {
  const authOrganization = useSelector(selectOrganization);
  const authUser = useSelector(selectAuthUser);

  const preferredAgent = useMemo(() => {
    if (!user?.cache?.agents && !user?.agents && user?._currentAgent) {
      return user._currentAgent;
    }

    return (
      user?.agents?.find((a) => a._organization === authOrganization?._id) ||
      user?.cache?.agents?.[authOrganization?._id]
    );
  }, [user, authOrganization]);

  const fullNameGridFormat = () => {
    if (preferredAgent?.firstName && preferredAgent?.lastName) {
      return `${preferredAgent.lastName}, ${preferredAgent.firstName}`;
    }

    return user?.companyEmail || '';
  };

  const fullName = () => {
    if (preferredAgent?.firstName && preferredAgent?.lastName) {
      return `${preferredAgent.firstName} ${preferredAgent.lastName}`;
    }

    return user?.companyEmail || '';
  };

  const reportsToAuthUser = () => {
    if (user?.managers?.length) {
      return (
        preferredAgent?.reportsToType === ReportsToType.users &&
        user.managers?.find((u) => u === authUser?._id)
      );
    }
    return (
      preferredAgent?.reportsToType === ReportsToType.users &&
      preferredAgent?._reportedTo?.find((u) => u._id === authUser?._id)
    );
  };

  const isAuthUser = () => {
    return user?._id === authUser?._id;
  };

  return {
    preferredAgent: {
      ...preferredAgent,
      fullNameGridFormat: fullNameGridFormat(),
      fullName: fullName(),
      reportsToAuthUser: reportsToAuthUser(),
      isAuthUser: isAuthUser(),
      onlineStatus: user?.onlineStatus,
    },
  };
};
