import React, {
  useState,
  useMemo,
  createContext,
  useEffect,
  useRef,
} from 'react';
import { ContactsList } from './components/ContactsList';
import { ChatWindow } from './components/ChatWindow';
import {
  useGetAllChatsQuery,
  useLazyGetAllContactsListQuery,
  useLazyGetAllChatsQuery,
  useGetChatMessagesQuery,
  usePostStartChatMutation,
  useGetChatCountQuery,
} from 'common/services/chatApi';
import { StateSetter } from 'types';
import { useDebounce } from 'common/hooks/useDebounce';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSubscribeObject } from 'common/hooks/ws';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import BellNotificationSound from 'assets/sounds/chat/BellNotificationSound.webm';
import inChatNotificationSound from 'assets/sounds/chat/inChatNotificationSound.webm';
import { NotificationsToast } from './components/NotificationsToast';
import { useQueryParams } from 'common/hooks/useQueryParams';
import { useUserInteracted } from 'hooks/userInteracted';
import { selectOrganization } from 'common/store/organization/selectors';
import If from '../If';
import { AppDrawer } from '../AppDrawer';
import { useTranslation } from 'react-i18next';

interface ChatContextProps {
  contacts: any[];
  search: string;
  setSearch: StateSetter<string>;
  selectedUser: any;
  setSelectedUser: StateSetter<any>;
  isFetchingContacts: boolean;
  contactsLimit: number;
  setContactsLimit: StateSetter<number>;
  initialItemsCount: number;
  contactsOffset: number;
  setContactsOffset: StateSetter<number>;
  totalDocs: number;
  chats: any;
  currentUserId: string;
  lastListMessageCopy: any;
  selectedChatId: string;
  setSelectedChatId: StateSetter<string>;
  isLastMessageReached: boolean;
  setIsLastMessageReached: StateSetter<boolean>;
  refetchChats: any;
  totalChats: number;
  triggerRefetchChats: Function;
  setFilteredMessages: StateSetter<any[]>;
  setChatMessagesLimit: StateSetter<number>;
}

export const ChatContext = createContext<ChatContextProps>({
  contacts: [],
  search: '',
  setSearch: () => {},
  selectedUser: '',
  setSelectedUser: () => {},
  isFetchingContacts: false,
  contactsLimit: 15,
  setContactsLimit: () => {},
  initialItemsCount: 15,
  contactsOffset: 0,
  setContactsOffset: () => {},
  totalDocs: 0,
  chats: null,
  currentUserId: '',
  lastListMessageCopy: null,
  selectedChatId: '',
  setSelectedChatId: () => {},
  isLastMessageReached: false,
  setIsLastMessageReached: () => {},
  refetchChats: null,
  totalChats: 0,
  triggerRefetchChats: () => {},
  setFilteredMessages: () => {},
  setChatMessagesLimit: () => {},
});

export enum ChatComponents {
  Both = 'both',
  Window = 'window',
  List = 'list',
}

interface Props {
  open: boolean;
  setChatOpen: StateSetter<boolean>;
  unreadMessagesCount: number;
  setUnreadMessagesCount: StateSetter<number>;
}

export const Chat: React.FC<Props> = ({
  open,
  setChatOpen,
  unreadMessagesCount,
  setUnreadMessagesCount,
}) => {
  /* ------------------------------- useStates ------------------------------- */
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedChatId, setSelectedChatId] = useState('');
  const initialItemsCount = 15;
  const [contactsLimit, setContactsLimit] = useState(initialItemsCount);
  const [contactsOffset, setContactsOffset] = useState(0);
  const chatsLimit = initialItemsCount;
  const [chatsOffset, setChatsOffset] = useState(0);
  const [filteredChatsList, setFilteredChatsList] = useState<any[]>([]);
  const [filteredMessages, setFilteredMessages] = useState<any[]>([]);
  const [isLastMessageReached, setIsLastMessageReached] = useState(false);
  const [chatMessagesLimit, setChatMessagesLimit] = useState(30);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [lastRecievedMessage, setLastRecievedMessage] = useState<any>(null);
  const [newMessageToastOpen, setNewMessageToastOpen] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [hasMoreChats, setHasMoreChats] = useState(false);
  const [totalChats, setTotalChats] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lastListMessageCopy, setLastListMessageCopy] = useState<any>(null);

  /* ------------------------------- Variables ------------------------------- */
  const authUser: any = useSelector(selectAuthUser);
  const listCursor = useRef('');
  const chatCursor = useRef('');
  const audioRefOut: any = useRef(null); // notification sound when no chat selected
  const audioRefIn: any = useRef(null); // in chat notification sound
  const debouncedSearch = useDebounce(search, 500);
  const usersQureyParams = useQueryParams({
    limit: contactsLimit,
    offset: contactsOffset,
    text: debouncedSearch,
    sort: '-lastName',
  });
  const userInteracted = useUserInteracted();
  const organization: any = useSelector(selectOrganization);
  const { t } = useTranslation();

  /* ------------------------------- Api Calls ------------------------------- */
  const [
    triggerGetContacts,
    { data: contacts, isFetching: isFetchingContacts },
  ] = useLazyGetAllContactsListQuery();

  const {
    data: chats,
    isFetching: isFetchingChats,
    isLoading: isLoadingChats_,
    refetch: refetchChats,
    isUninitialized,
  } = useGetAllChatsQuery(
    {
      text: '',
      cursor: listCursor.current === '-1' ? '' : listCursor.current,
      limit: chatsLimit,
      offset: chatsOffset,
    },
    { refetchOnMountOrArgChange: true, skip: !open },
  );

  const {
    data: messages,
    isFetching: isFetchingMessages,
    isLoading: isLoadingMessages,
  } = useGetChatMessagesQuery(
    {
      messageId: '',
      cursor: chatCursor.current === '-1' ? '' : chatCursor.current,
      chatId: selectedChatId,
      text: '',
      limit: chatMessagesLimit,
    },
    { refetchOnMountOrArgChange: true, skip: !selectedChatId },
  );

  const [handleStartConversation, { isLoading: isLoadingStartChat }] =
    usePostStartChatMutation();

  const [triggerChats, { data: newChats }] = useLazyGetAllChatsQuery();

  const { data: unreadChatCount, refetch: refetchChatCount } =
    useGetChatCountQuery({});

  /* ------------------------------- Use Memos ------------------------------- */
  const totalDocs = useMemo(() => {
    return contacts?.totalDocs || 0;
  }, [contacts]);

  const currentUserId = useMemo(() => {
    if (authUser) {
      return authUser?._id;
    } else {
      return '';
    }
  }, [authUser]);

  const visibleElements = useMemo(() => {
    if (!windowWidth) return ChatComponents.Both;
    if (windowWidth > 1200) {
      return ChatComponents.Both;
    } else if (windowWidth <= 1200 && selectedUser) {
      return ChatComponents.Window;
    } else {
      return ChatComponents.List;
    }
  }, [windowWidth, selectedUser]);

  const currentOrganization = useMemo(() => {
    return organization?._id || '';
  }, [organization]);

  /* ------------------------------ Web Sockets ------------------------------ */
  const lastListMessage = useSubscribeObject('user-chats', `${authUser?._id}`, {
    data: chats?.data,
  });

  const lastChatMessages = useSubscribeObject(
    'channel-message',
    `${selectedChatId}`,
  );

  const deletedMessages = useSubscribeObject('deleteMessages', selectedChatId);

  const editedMessages = useSubscribeObject('updateMessage', selectedChatId);

  /* ------------------------------ Use Effects ------------------------------ */
  useEffect(() => {
    if (!lastListMessage) return;
    setLastListMessageCopy(JSON.parse(lastListMessage)?.data?.data);
  }, [lastListMessage]);

  useEffect(() => {
    if (!lastListMessageCopy) return;
    triggerRefetchChats();

    if (lastListMessageCopy?.lastMessage?.sender === currentUserId) return;

    const sentBy = lastListMessageCopy?.participantsDetails?.filter(
      (participant) =>
        participant?._id === lastListMessageCopy?.lastMessage?.sender,
    )[0];
    const sender = sentBy?.agents?.filter(
      (agent) => agent?._organization === authUser?._currentOrganization?._id,
    )[0];
    if (
      lastListMessageCopy?.messages[0]?.lastMessage?._id !==
      lastRecievedMessage?.message?._id
    ) {
      setLastRecievedMessage({
        message: lastListMessageCopy?.messages[0]?.lastMessage,
        sender: sender,
        unreadCount: lastListMessageCopy?.messages[0]?.unreadCount,
      });
    }
  }, [lastListMessageCopy]);

  useEffect(() => {
    if (!lastRecievedMessage) {
      return;
    }

    // recieved message from selected contact
    if (lastRecievedMessage?.message?.sender === selectedUser?._id) {
      audioRefIn?.current?.pause();
      audioRefIn.current.currentTime = 0;
      audioRefIn?.current?.play();
      setNewMessageToastOpen(false);
      return;
    }

    setNewMessageToastOpen(true);
    if (userInteracted) {
      audioRefOut?.current?.pause();
      audioRefOut.current.currentTime = 0;
      audioRefOut?.current?.play();
    }

    const timeoutId = setTimeout(() => {
      setNewMessageToastOpen(false);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [lastRecievedMessage]);

  useEffect(() => {
    if (isFetchingChats || !chats || !refetchChats) {
      return;
    }
    setHasMoreChats(chats?.hasMore);

    setTotalChats(chats?.facets?.totalChats);

    if (chats?.data?.length === 0) {
      return;
    } else {
      setFilteredChatsList((prevChats) => {
        const newData = chats?.data?.filter(
          (item) => !prevChats?.some((prevItem) => prevItem?._id === item?._id),
        );
        return [...prevChats, ...newData];
      });
    }
  }, [chats, isFetchingChats, refetchChats]);

  useEffect(() => {
    if (!selectedUser) {
      triggerRefetchChats();
      return;
    }
    handleStartConversation({ userId: selectedUser?._id })
      .then((res: any) => {
        setSelectedChatId(res?.data?.chat?._id || res?.data?._id);
        triggerRefetchChats();
      })
      .catch((e) => {
        console.log(e);
        setSelectedChatId('');
      });
  }, [selectedUser]);

  useEffect(() => {
    if (lastChatMessages) {
      const message = JSON.parse(lastChatMessages);

      const newMessage = {
        name: message?.data?.data?.name,
        description: message?.data?.data?.description,
        content: message?.data?.data?.content,
        contentType: message?.data?.data?.type,
        createdAt: message?.data?.data?.createdAt,
        reply: message?.data?.data?.reply,
        _id: message?.objectId,
        messageUuid: message?.data?.data?.messageUuid,
        sender: {
          id: message?.data?.data?.sender,
          pictureUrl: message?.data?.data?.pictureUrl,
          firstName: message?.data?.data?.firstName,
          lastName: message?.data?.data?.lastName,
        },
      };
      setFilteredMessages((prev) => [newMessage, ...prev]);
    }
  }, [lastChatMessages]);

  useEffect(() => {
    if (chats || lastChatMessages || lastListMessageCopy) {
      refetchChatCount && refetchChatCount();
    }
  }, [chats, lastChatMessages, lastListMessageCopy]);

  useEffect(() => {
    if (unreadChatCount?.unreadChats >= 0) {
      setUnreadMessagesCount(unreadChatCount.unreadChats);
    }
  }, [unreadChatCount]);

  useEffect(() => {
    if (messages?.data) {
      setFilteredMessages(messages?.data);
    }
  }, [messages?.data]);

  useEffect(() => {
    setChatMessagesLimit(30);
  }, [selectedChatId, selectedUser]);

  useEffect(() => {
    if (!isLastMessageReached || !messages?.hasMore) return;
    setChatMessagesLimit(chatMessagesLimit + 30);
  }, [isLastMessageReached]);

  useEffect(() => {
    if (!isUninitialized || !open) return;
    setChatMessagesLimit(30);
    if (unreadMessagesCount > 0 && refetchChats) {
      refetchChats();
    }
    if (lastListMessageCopy) {
      if (
        lastListMessageCopy?._id === selectedChatId &&
        lastListMessageCopy?.messages?.length > 0
      ) {
        const newMessage = { ...lastListMessageCopy };
        newMessage.messages[0].unreadCount = 0;
        setLastListMessageCopy(newMessage);
      }
    }
  }, [selectedChatId, isUninitialized, open]);

  useEffect(() => {
    if (!isInitialRender) return;
    bindShortcut(['escape'], () => handleCloseChat());

    setIsInitialRender(false);
  }, []);

  useEffect(() => {
    setIsLoadingChats(isLoadingChats_);
  }, [isLoadingChats_]);

  useEffect(() => {
    if (!newChats?.data) return;
    setFilteredChatsList(newChats.data);
    setTotalChats(newChats?.facets?.totalChats);
  }, [newChats]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedUser?._id === currentUserId) return;

    const deletedMessagesParsed = JSON.parse(deletedMessages);
    const messageUuidsToDelete: string[] =
      deletedMessagesParsed?.data?.uuids || [];
    if (messageUuidsToDelete?.length > 0) {
      setFilteredMessages((prevState) =>
        prevState?.filter((message) => {
          return messageUuidsToDelete.some(
            (uuid) => uuid !== message?.messageUuid,
          );
        }),
      );
    }
  }, [deletedMessages]);

  useEffect(() => {
    if (!editedMessages) return;
    const parsedEditedMessage = JSON.parse(editedMessages);
    const messageUuid = parsedEditedMessage?.data?.message?.messageUuid;
    const newMessageContent = parsedEditedMessage?.data?.message?.content;
    setFilteredMessages((prevState) =>
      prevState?.map((message) => {
        if (message?.messageUuid === messageUuid) {
          return {
            ...message,
            content: newMessageContent,
            isEdited: true,
          };
        } else {
          return message;
        }
      }),
    );
  }, [editedMessages]);

  useEffect(() => {
    if (isUninitialized || !open) return;
    setLastRecievedMessage(null);
    setSelectedUser(null);
    setLastListMessageCopy(null);
    setFilteredChatsList([]);
    refetchChats();
  }, [currentOrganization, isUninitialized, open]);

  useEffect(() => {
    if (!open) return;
    triggerGetContacts(usersQureyParams);
  }, [open, usersQureyParams]);

  useEffect(() => {
    if (isUninitialized || !open) return;
    refetchChats();
  }, [isUninitialized, open]);

  /* -------------------------------- Functions -------------------------------- */
  const handleGetMoreChats = () => {
    if (chats.hasMore === true && !isLoadingChats && !isFetchingChats) {
      setChatsOffset((prevOffset) => prevOffset + chatsLimit);
    }
  };

  const handleCloseChat = () => {
    setSelectedUser(null);
    setSelectedChatId('');
    setChatOpen(false);
  };

  const triggerRefetchChats = async () => {
    await triggerChats({
      cursor: '',
      text: '',
      limit: filteredChatsList?.length || initialItemsCount,
      offset: 0,
    });
  };

  const handleNotificationsToastClick = () => {
    setChatOpen(true);
    setSelectedUser({
      ...lastRecievedMessage?.sender,
      _id: lastRecievedMessage?.message?.sender,
    });
    setNewMessageToastOpen(false);
  };

  /* ------------------------------------------------------------------------- */

  return (
    <ChatContext.Provider
      value={{
        contacts: contacts?.docs || [],
        search: search,
        setSearch: setSearch,
        selectedUser: selectedUser,
        setSelectedUser: setSelectedUser,
        isFetchingContacts: isFetchingContacts,
        contactsLimit: contactsLimit,
        setContactsLimit: setContactsLimit,
        initialItemsCount: initialItemsCount,
        contactsOffset: contactsOffset,
        setContactsOffset: setContactsOffset,
        totalDocs: totalDocs,
        chats: chats,
        currentUserId: currentUserId,
        lastListMessageCopy: lastListMessageCopy,
        selectedChatId: selectedChatId,
        setSelectedChatId: setSelectedChatId,
        isLastMessageReached: isLastMessageReached,
        setIsLastMessageReached: setIsLastMessageReached,
        refetchChats: refetchChats,
        totalChats: totalChats,
        triggerRefetchChats: triggerRefetchChats,
        setFilteredMessages: setFilteredMessages,
        setChatMessagesLimit: setChatMessagesLimit,
      }}
    >
      <AppDrawer
        name="chat"
        open={open}
        onClose={handleCloseChat}
        toolbarTitle={t('chat.lets_connect')}
      >
        <>
          <If
            condition={
              visibleElements === ChatComponents.Both ||
              visibleElements === ChatComponents.List
            }
          >
            <ContactsList
              filteredChatsList={filteredChatsList}
              setFilteredChatsList={setFilteredChatsList}
              handleGetMoreChats={handleGetMoreChats}
              isLoadingChats={isLoadingChats || isFetchingChats}
              hasMore={hasMoreChats}
              chatsOffset={chatsOffset}
            />
          </If>
          <If
            condition={
              visibleElements === ChatComponents.Both ||
              visibleElements === ChatComponents.Window
            }
          >
            <ChatWindow
              messages={filteredMessages}
              setMessages={setFilteredMessages}
              isFetching={isFetchingMessages || isLoadingMessages}
              isLoadingStartChat={isLoadingStartChat}
              hasMore={messages?.hasMore}
              totalMessages={messages?.totalMessages}
              visibleElements={visibleElements}
            />
          </If>
        </>
      </AppDrawer>
      {!open && (
        <NotificationsToast
          newMessageToastOpen={newMessageToastOpen}
          setNewMessageToastOpen={setNewMessageToastOpen}
          lastRecievedMessage={lastRecievedMessage}
          handleNotificationsToastClick={handleNotificationsToastClick}
        />
      )}
      <audio src={inChatNotificationSound} ref={audioRefIn} />
      <audio src={BellNotificationSound} ref={audioRefOut} />
    </ChatContext.Provider>
  );
};
