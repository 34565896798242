import { Collapse } from '@mui/material';
import * as React from 'react';
import Card from '@mui/material/Card';
import { themes } from '../../../../../../styles/theme/themes';
import WarehouseRoundtripsCardContent from './WarehouseRoundtripsCardContent';
import { IWarehouseMapping } from '../../index';
import { WarehouseCardHeader } from '../WarehouseCardHeader';
import AddRoundtripsWithTemplate from '../AddRoundtripsWithTemplate';

function WarehouseCollapseCard({
  warehouse,
  isFirst,
}: {
  warehouse: IWarehouseMapping;
  isFirst: boolean;
}) {
  const [expanded, setExpanded] = React.useState(isFirst);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (warehouse.nbOfRoundtrips === 0) {
      setExpanded(true);
    }
  }, [warehouse]);

  const handleCardClick = (e: React.MouseEvent) => {
    const targetElement = e.target as HTMLElement;
  
    if (
      targetElement.closest('.MuiTextField-root') || 
      targetElement.closest('button') || 
      targetElement.tagName === 'SVG' 
    ) {
      return;
    }
  
    setExpanded((prev) => !prev);
  };
  
  return (
    <Card
      onClick={handleCardClick}
      sx={{
        bgcolor: themes.default.lightGrey,
        boxShadow: 'none',
        border: `1px solid ${themes.default.gainsboro2}`,
        // handle loading state
        opacity: warehouse.loading ? 0.5 : 1,
        borderRadius: '4px !important',
        pointerEvents: warehouse.loading ? 'none' : 'auto',
        cursor: 'pointer',
      }}
    >
      <WarehouseCardHeader
        isExpanded={expanded}
        onExpandClick={handleExpandClick}
        warehouse={warehouse}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {warehouse.nbOfRoundtrips === 0 ? (
          <AddRoundtripsWithTemplate warehouseId={warehouse.id} />
        ) : (
          <WarehouseRoundtripsCardContent warehouse={warehouse} />
        )}
      </Collapse>
    </Card>
  );
}
export default WarehouseCollapseCard;
