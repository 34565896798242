import * as React from 'react';
import ManagedContext from '../../../../common/UtilityComponents/ManagedContext/ManagedContext';
import FunctionalRoundtripMappings from './components/FunctionalRoundtripMappings';
import { useSelector } from 'react-redux';
import { roundtripCodes } from '../../../../common/store/organization/selectors';

export interface IRoundtripInfo {
  id: string;
  area: string;
  roundtripCode: string;
  driver: string;
  vehicle: string;
}

export interface IWarehouseMapping {
  id: string;
  name: string;
  nbOfRoundtrips: number;
  color: string;
  status: string;
  shortName: string;
  loading: boolean;
  roundtrips: IRoundtripInfo[];
}

export interface IRoundtripMappingsContext {
  searchValue: string;
  drivers: any[];
  filteredDrivers: any[];
  filteredVehicles: any[];
  vehicles: any[];
  roundtripCodes: string[];
  roundtripMappings: IWarehouseMapping[];
  currentWarehouse: string;
}

function RoundtripMapping() {
  const codes = useSelector(roundtripCodes);
  return (
    <ManagedContext
      contextName="RoundtripMappingsContext"
      defaultValue={
        {
          searchValue: '',
          drivers: [],
          filteredDrivers: [],
          filteredVehicles: [],
          vehicles: [],
          currentWarehouse: '',
          roundtripCodes: codes,
          roundtripMappings: [
            {
              id: '',
              loading: false,
              name: '',
              nbOfRoundtrips: 0,
              color: '',
              status: '',
              shortName: '',
              roundtrips: [],
            },
          ],
        } as IRoundtripMappingsContext
      }
    >
      <FunctionalRoundtripMappings />
    </ManagedContext>
  );
}

export default RoundtripMapping;