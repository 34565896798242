import { Box, Stack } from '@mui/material';
import { themes } from '../../../../../styles/theme/themes';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
// import Avatar from '@mui/material/Avatar';
import { Icon } from '../../../../components/Icon';
import { TrashSimple, WarningCircle } from '@phosphor-icons/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '..';
import { useGetWarehousesQuery } from 'common/services/organizationApi';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const WarehouseStatusChip = ({ status }: { status: string }) => {
  if (!status) return null;
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        bgcolor: themes.default.chipRedFont,
        borderRadius: '16px',
        color: themes.default.chipRedBg,
        fontWeight: '400',
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        textAlign: 'left',
        padding: '4px 6px',
      }}
    >
      <Typography
        fontSize="11px"
        fontWeight="400"
        lineHeight="14.85px"
        textAlign="left"
        letterSpacing={0.4}
      >
        {status}
      </Typography>
    </Stack>
  );
};

const RoundtripsCountChip = ({ count }: { count: number }) => {
  if (!count) return null;
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        bgcolor: themes.default.whisper,
        borderRadius: '4px',
        color: themes.default.chipTextColor,
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '17.16px',
        letterSpacing: '0.17px',
        textAlign: 'left',
        padding: '2px 4px',
      }}
    >
      <Typography
        fontSize="10px"
        fontWeight="500"
        lineHeight="14px"
        textAlign="left"
      >
        {count + ' roundtrips'}
      </Typography>
    </Stack>
  );
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export const WarehouseCardHeader = ({
  onExpandClick,
  isExpanded,
  warehouse,
}) => {
  const { updateDataWithFunction } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  function handleDeleteWarehouse(id) {
    updateDataWithFunction((prev) => {
      prev.roundtripMappings = prev.roundtripMappings.filter(
        (warehouse) => warehouse.id !== id,
      );
    });
  }
  const { data: warehouseData } = useGetWarehousesQuery('');

  const selectedWarehouseId = warehouse?.id; 

  const currentwarehouse = warehouseData?.warehouses?.find(
    (warehouse) => warehouse._id === selectedWarehouseId,
  );

  // const filteredWarehouse = roundtripMappings.find(
  //   (mapping) => mapping.id === warehouseId,
  // );
  return (
    <Stack
      direction="row"
      // px={2}
      // py={1}
      justifyContent="space-between"
      // gap={2}
      alignItems="center"
      borderRadius="0px !important"
    >
      <Stack
        direction="row"
        px="16px"
        pt="12px" // justifyContent="space-between"
        pb="8px"
        gap={0.7}
        alignItems="center"
        borderRadius="0px !important"
      >
        {/* <Avatar
          sx={{ bgcolor: warehouse.color, width: '24px', height: '24px' }}
          aria-label="recipe"
        >
          {warehouse.name && warehouse.name.split(' ')[1]
            ? warehouse.name.split(' ')[1].charAt(0).toUpperCase()
            : warehouse.name
            ? warehouse.name.charAt(0).toUpperCase()
            : ''}{' '}
        </Avatar> */}
        <Stack direction="row" gap={1}>
          <Typography
            fontSize="12px"
            fontWeight="700"
            letterSpacing="0.15px"
            sx={{
              backgroundColor: `${warehouse.color}`,
              width: '45px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '20px',
            }}
          >
            {warehouse.shortName}
          </Typography>
          {warehouse.roundtrips?.some((r) => !r.roundtripCode) && (
            <Icon
              icon={<WarningCircle />}
              color={themes.default.errorMain}
              aria-label="warning"
            />
          )}
          <RoundtripsCountChip count={warehouse.nbOfRoundtrips} />
          {currentwarehouse?.status === 'inactive' && (
            <WarehouseStatusChip status={currentwarehouse?.status} />
          )}
        </Stack>
      </Stack>
      <Box>
        {warehouse.nbOfRoundtrips === 0 && (
          <TrashSimple
            onClick={() => handleDeleteWarehouse(warehouse.id)}
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#757575',
              padding: '2px',
              borderRadius: '4px',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#EEEEEE')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = 'transparent')
            }
          />
        )}
        <ExpandMore
          expand={isExpanded}
          onClick={onExpandClick}
          aria-expanded={isExpanded}
          disableRipple
          aria-label="show more"
          sx={{ fontWeight: '400', color: '#757575' }}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Box>
    </Stack>
  );
};
