import { Autocomplete, TextField, Typography } from '@mui/material';
import { InputBlur } from 'app/components/AccessBlur/InputBlur';
import { AssigneeType } from 'app/pages/Accessories/Types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import React from 'react';

interface Props {
  options: any[];
  isFetching: boolean;
  setSearch: StateSetter<string>;
  control?: any;
  disabled?: boolean;
  title: string;
  type: AssigneeType;
  defaultValue?: {
    value: string;
    label: string;
    subLabel: string;
  };
}

export const AssigneeAutoComplete = ({
  options,
  isFetching,
  setSearch,
  control,
  disabled,
  title,
  type,
  defaultValue,
}: Props) => {
  const { t } = useTranslation();

  const renderOption = (props, option) => {
    return (
      <OptionWrapper {...props} key={option.value}>
        <div>
          <Typography fontSize="0.75rem">{option.label}</Typography>
        </div>
        <div>
          <Typography fontSize="0.625rem" color={themes.default.grey2}>
            {option.subLabel}
          </Typography>
        </div>
      </OptionWrapper>
    );
  };

  return (
    <InputBlur subject={type === AssigneeType.vehicle ? 'vehicles' : 'users'}>
      <Controller
        name={type}
        control={control}
        render={({ field }) => (
          <Autocomplete
            size="small"
            sx={{ marginTop: 0, paddingTop: 0 }}
            options={options}
            filterOptions={(options) => {
              return options;
            }}
            getOptionLabel={(option) => option.label}
            onChange={(e, option) => field.onChange(option?.value)}
            onBlur={() => setSearch('')}
            loading={isFetching}
            defaultValue={defaultValue}
            renderInput={(params) => {
              if (
                params.inputProps?.value === 'undefined' ||
                !params.inputProps?.value
              ) {
                params.inputProps.value = '';
              }
              return (
                <TextField
                  {...params}
                  {...field}
                  placeholder={
                    type === AssigneeType.vehicle
                      ? t('modules.vehicles')
                      : t('search.employees')
                  }
                  sx={{
                    marginTop: 0,
                    '& .MuiFormControl-root': { marginTop: 0 }, // If nested inside FormControl
                  }}
                  size="small"
                  variant="outlined"
                  label={t(title)}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  disabled={disabled}
                  InputLabelProps={{ shrink: true }}
                />
              );
            }}
            renderOption={renderOption}
            disabled={disabled}
          />
        )}
      />
    </InputBlur>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`;
