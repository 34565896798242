import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { TextField, FormControl, FormHelperText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ColorSquare = styled.div<{ color: string; selected: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  margin: 5px;
  margin-inline: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CheckMark = styled(CheckIcon)<{ selected: boolean }>`
  color: white;
  visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  border-radius: 4px;
`;

interface ColorPickerProps {
  name: string;
  colors: string[];
  control: any;
  label: string;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  name,
  colors,
  control,
  label,
  error,
  helperText,
  required = false,
}) => {
  return (
    <FormControl
      error={error}
      fullWidth
      sx={{
        // remove the margin bottom
        marginBottom: '0 !important',
      }}
    >
      <TextField
        required={required}
        label={label}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: () => (
            <Controller
              control={control}
              name={name}
              render={({ field }) => (
                <ColorPickerWrapper>
                  {colors.map((color) => (
                    <ColorSquare
                      key={color}
                      color={color}
                      selected={field.value === color}
                      onClick={() => field.onChange(color)}
                    >
                      <CheckMark selected={field.value === color} />
                    </ColorSquare>
                  ))}
                </ColorPickerWrapper>
              )}
            />
          ),
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ColorPicker;
