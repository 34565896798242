import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import React, { useEffect, useMemo, useState } from 'react';

interface TruckIconProps {
  vehicle: boolean;
  driver: boolean;
  running: boolean;
  style?: React.CSSProperties;
  iconStyle?: {
    icon: React.CSSProperties;
    img: React.CSSProperties;
  };
  notTracked?: boolean;
  mapOpen?: boolean;
  status?: string;
  marker?: boolean;
}

const TruckIcon = ({
  vehicle,
  driver,
  running,
  style,
  iconStyle,
  notTracked,
  mapOpen,
  status,
  marker,
}: TruckIconProps) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const [showRedPulse, setShowRedPulse] = useState(false);

  useEffect(() => {
    setShowRedPulse(driver);
  }, [driver]);

  const arrowIconColors = useMemo(() => {
    const colorMap: {
      [key: string]: { iconColor: string; pulseColor?: string };
    } = {
      available: {
        iconColor: theme?.fruitSaladGreen,
        pulseColor: 'rgba(67, 160, 71, 0.4)',
      },
      active: {
        iconColor: theme?.primary,
        pulseColor: 'rgba(42, 89, 255, 0.4)',
      },
      emergency: {
        iconColor: theme?.Cinnabar,
        pulseColor: 'rgba(229, 57, 53, 0.4)',
      },
      returning: {
        iconColor: theme?.summerSky,
        pulseColor: 'rgba(41, 182, 246, 0.4)',
      },
      offline: { iconColor: theme?.nobel },
      'out-of-service': { iconColor: theme?.nobel },
      inactive: { iconColor: theme?.nobel },
    };
    return (
      colorMap[status || 'inactive'] || { iconColor: theme?.textColorPrimary }
    );
  }, [status, theme]);

  const tooltipTitle = useMemo(() => {
    let title = `${t('roundtrips.tooltip.truck_is')} ${t(
      `roundtrips.tooltip.truck.${status || 'inactive'}`,
    )}`;
    if (vehicle || showRedPulse) {
      title = t('roundtrips.tooltip.truck.warning');
    } else if (notTracked && !vehicle && !driver) {
      title += ` ${t('roundtrips.tooltip.truck.not_tracked').toLowerCase()}`;
    } else if (running) {
      title += ` - ${t('truck.moving')}`;
    }
    return title;
  }, [status, vehicle, showRedPulse, notTracked, driver, running, t]);

  return (
    <TruckIconWrapper mapOpen={mapOpen} style={style}>
      <ManagedTooltip title={tooltipTitle}>
        {vehicle || showRedPulse ? (
          <RedPulse style={iconStyle?.icon} marker={!!marker} />
        ) : (
          <PulseIcon
            style={iconStyle?.icon}
            marker={!!marker}
            fillColor={
              running
                ? arrowIconColors.pulseColor || 'transparent'
                : 'transparent'
            }
            strokeColor={running ? arrowIconColors.iconColor : 'transparent'}
          >
            <ArrowIcon
              color={arrowIconColors.iconColor}
              style={
                !!marker
                  ? { ...iconStyle?.img, height: '32px', width: '32px' }
                  : iconStyle?.img
              }
            />
          </PulseIcon>
        )}
      </ManagedTooltip>
      {notTracked && !(vehicle || driver) && (
        <PriorityHighIcon
          style={{
            position: 'absolute',
            right: '-9px',
            top: '5px',
            height: '14px',
          }}
        />
      )}
    </TruckIconWrapper>
  );
};

export default TruckIcon;

// Components
const ArrowIcon = ({
  color,
  style = { width: '19px', height: '19px' },
}: {
  color: string;
  style?: React.CSSProperties;
}) => (
  <svg
    width={style.width}
    height={style.height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect width="32" height="32" rx="16" fill="white" />
    <rect x="4" y="4" width="24" height="24" rx="12" fill={color} />
    <path
      d="M15.5075 7.84105L16.001 7.5L16.4932 7.84101L19.9504 14.6136L23.4075 21.3863C23.5384 21.6427 23.5228 21.9332 23.408 22.1581C23.2935 22.3824 23.1083 22.4983 22.9139 22.4983H22.1236L17.1354 20.6575L14.8928 20.6421L9.74316 22.5H9.08521C8.89203 22.5 8.70709 22.3845 8.59238 22.1598C8.47736 21.9345 8.46154 21.6438 8.59181 21.3875L12.0487 14.6154L15.5075 7.84105Z"
      fill="white"
      stroke={color}
    />
  </svg>
);

// Styled Components
const ExpandFade = keyframes`
  0% {
    width: 1px;
    height: 1px;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 32px;
    height: 32px;
    opacity: 0;
  }
`;

const PulseIcon = styled.div<{
  marker?: boolean;
  fillColor: string;
  strokeColor: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${(props) => props.strokeColor};
    background: ${(props) => props.fillColor};
    border-radius: 100%;
    animation: ${ExpandFade} 3s infinite ease-in-out;
  }

  width: ${(props) => (props.marker ? '24px' : '18px')};
  height: ${(props) => (props.marker ? '24px' : '18px')};
`;

const RedPulse = styled.div<{ marker: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.redStatus};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(2.5px, 0);
  animation: redPulse 1s infinite;

  @keyframes redPulse {
    0% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.redShadow};
    }
    50% {
      box-shadow: 0 0 0 5px ${(props) => props.theme.redShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${(props) => props.theme.redShadow};
    }
  }
`;

const TruckIconWrapper = styled.div<{ mapOpen?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  padding: 4px;
  outline: ${(props) =>
    props.mapOpen ? `2px solid ${props.theme.primaryActiveColor}` : 'none'};
`;
