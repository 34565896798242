import { createSlice } from '@reduxjs/toolkit';
import { CartState } from './types';

export const initialState: CartState = {
  selectedProducts: {
    allSelected: true,
    products: {},
  },
  cartVisibilityState: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setNewSelectedProduct: (state, action) => {
      state.selectedProducts.products[action.payload.productId] = true;

      const allSelected = Object.values(state.selectedProducts.products).every(
        selected => selected === true,
      );
      state.selectedProducts.allSelected = allSelected;
    },
    removeSelectedProduct: (state, action) => {
      if (!!state.selectedProducts.products[action.payload.productId]) {
        state.selectedProducts.products[action.payload.productId] = false;
      }

      state.selectedProducts.allSelected = false;
    },
    removeAllProductsFromCart: state => {
      state.selectedProducts.products = {};
      state.selectedProducts.allSelected = false;
    },
    changeAllProductSelection: state => {
      const newAllSelectedState = !state.selectedProducts.allSelected;
      state.selectedProducts.allSelected = newAllSelectedState;

      Object.keys(state.selectedProducts.products).forEach(id => {
        state.selectedProducts.products[id] = newAllSelectedState;
      });
    },
    changeCartVisibilityState: (
      state,
      action: { type: string; payload: boolean },
    ) => {
      state.cartVisibilityState = action.payload;
    },
    cartIsVisible: state => {
      state.cartVisibilityState = true;
    },
    deleteProductsFromSelectedObject: (
      state,
      action: { type: string; payload: string[] },
    ) => {
      const idsToDelete = new Set(action.payload);
      const filteredProducts = Object.entries(state.selectedProducts.products)
        .filter(([id]) => !idsToDelete.has(id))
        .reduce(
          (acc, [id, value]) => {
            acc[id] = value;
            return acc;
          },
          {} as Record<string, boolean>,
        );

      state.selectedProducts.products = filteredProducts;
    },
  },
});

export const {
  changeCartVisibilityState,
  cartIsVisible,
  setNewSelectedProduct,
  removeSelectedProduct,
  changeAllProductSelection,
  removeAllProductsFromCart,
  deleteProductsFromSelectedObject,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
