import React, { useState, useMemo, forwardRef } from 'react';
import { Modal, Slide } from '@mui/material';
import { ModalHeader } from './components/ModalHeader';
import styled from 'styled-components';
import If from './../If/index';
import { themes } from 'styles/theme/themes';

interface ModalProps {
  onClose?: () => void;
  onOpen: boolean;
  title?: string;
  width?: string;
  maxWidth?: string;
  height?: number;
  defaultMode?: 'sidebar' | 'floated';
  scrollY?: boolean;
  children: React.ReactNode;
  handleViewChange?: Function;
  showBordersBottom?: boolean;
  headerTitleProps?: any;
  hasArrowBack?: boolean;
  onBackClick?: (e?: any) => void;
  customHeaderMargin?: string;
  headerContent?: React.ReactNode;
  bodyPadding?: string;
  borderRadius?: string;
  handleNextPrev?: (destination: 'next' | 'prev') => void;
  hasToggleView?: boolean;
}

export const TwoViewModal = forwardRef(
  (
    {
      title,
      width = '473px',
      maxWidth = '',
      height = 80,
      onClose,
      onOpen,
      defaultMode = 'sidebar',
      scrollY = true,
      children,
      handleViewChange,
      showBordersBottom = true,
      headerTitleProps,
      hasArrowBack = false,
      onBackClick,
      customHeaderMargin = '',
      headerContent,
      bodyPadding = '30px',
      borderRadius = '5px',
      hasToggleView = true,
    }: ModalProps,
    ref: React.ForwardedRef<HTMLDivElement | null>,
  ) => {
    const [viewMode, setViewMode] = useState<'sidebar' | 'floated'>(
      defaultMode,
    );

    const [BodyChild, FooterChild] = React.Children.toArray(children);

    useMemo(() => {
      if (viewMode) {
        handleViewChange && handleViewChange(viewMode);
      }
    }, [viewMode]);

    return (
      <Modal
        open={onOpen}
        onClose={onClose}
        aria-labelledby={title}
        sx={{
          display: 'flex',
          justifyContent: viewMode === 'sidebar' ? 'end' : 'center',
          alignItems: 'center',
          zIndex: '1000',
        }}
      >
        <Slide
          direction={viewMode === 'sidebar' ? 'left' : 'up'}
          in={onOpen}
          mountOnEnter
          unmountOnExit
        >
          <div style={{ width: width }}>
            <ModalContentWrapper
              onCenter={viewMode === 'floated'}
              maxWidth={maxWidth}
              height={height}
              borderRadius={borderRadius}
              ref={ref}
            >
              <ModalHeaderWrapper
                customHeaderMargin={customHeaderMargin}
                showBordersBottom={showBordersBottom}
              >
                <ModalHeader
                  title={title}
                  onClose={onClose}
                  viewMode={viewMode}
                  setViewMode={setViewMode}
                  headerTitleProps={headerTitleProps}
                  hasArrowBack={hasArrowBack}
                  onBackClick={onBackClick}
                  content={headerContent}
                  showBordersBottom={showBordersBottom}
                  hasToggleView={hasToggleView}
                />
              </ModalHeaderWrapper>

              {/*  dynamic design acconrding to Modal children */}
              {/*  In case the footer passed to the modal with body */}
              <ModalBodyWrapper
                footerChildrenExists={!!FooterChild}
                scrollY={scrollY}
                bodyPadding={bodyPadding}
              >
                {BodyChild}
              </ModalBodyWrapper>

              <If condition={!!FooterChild} otherwise={null}>
                <ModalFooterWrapper>{FooterChild}</ModalFooterWrapper>
              </If>
            </ModalContentWrapper>
          </div>
        </Slide>
      </Modal>
    );
  },
);

const ModalContentWrapper = styled.div<{
  onCenter: boolean;
  maxWidth: string;
  height?: number;
  borderRadius: string;
}>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.onCenter ? props.height + 'vh' : '100vh')};
  margin: ${(props) => (props.onCenter ? 'auto' : '0')};
  background-color: ${(props) => props.theme.background};
  overflow: hidden;
  max-width: ${(props) => props.maxWidth};
  border-radius: ${(props) =>
    props.onCenter ? props.borderRadius || '5px' : '5px 0 0 0'};
  background-color: ${(props) => props.theme.background};
  transition: height 0.3s ease !important;
  position: relative;
  box-shadow: ${(props) => props.theme.modalPurpleShadow};
`;

const ModalHeaderWrapper = styled.div<{
  showBordersBottom: boolean;
  customHeaderMargin: string;
}>`
  /* flex: 0 0 10%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  background-color: ${themes.default.background};
  margin: ${(props) => props.customHeaderMargin};
  position: sticky;
  top: 0;
`;

const ModalBodyWrapper = styled.div<{
  footerChildrenExists: boolean;
  scrollY: boolean;
  bodyPadding: string;
}>`
  flex: 1;
  width: 100%;
  overflow-y: ${(props) => (props.scrollY ? 'auto' : 'none')};
  padding-bottom: ${(props) => props.bodyPadding};
`;

const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.lightFill};
  padding: 12px 24px;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: white;
`;
