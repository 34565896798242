import { Popover } from '@mui/material';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { FilterContainer } from 'app/components/FilterComponents/styles';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from '../types';

interface FilterProps {
  filterComponent: JSX.Element;
  disabled?: boolean;
}

export const ManagedGridFilters = ({
  filterComponent,
  disabled,
}: FilterProps) => {
  const {
    queryParams: { filters, filterInit },
  } = useManagedContext<IGridContext>('grid');

  const { filterOn, handleCloseFilter, handleOpenFilter, filtersCount } =
    useFilterState({ filters, filterInit });
  const openFilter = Boolean(filterOn);
  const id = openFilter ? 'filter' : undefined;
  return (
    <FilterIcon
      filterOn={filterOn}
      filtersCount={filtersCount}
      handleOpenFilter={handleOpenFilter}
      disabled={disabled}
      filterComponent={
        <Popover
          id={id}
          open={openFilter}
          anchorEl={filterOn}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <FilterContainer>{filterComponent}</FilterContainer>
        </Popover>
      }
    />
  );
};
