import React from 'react';
import { useSelector } from 'react-redux';
import {
  Badge,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import If from '../If';
import Message, { getIsMe } from '../Message';
import { selectContactAddresses } from 'common/store/organization/selectors';
import { themes } from 'styles/theme/themes';

interface ConversationProps {
  subject: string;
  isNew?: boolean;
  isArchived?: boolean;
  lastMessage?: any;
  onClick: Function;
  onArchive?: Function;
  onChangeContactAddress?: Function;
  date: Date;
  style?: any;
  authUser?: any;
  customer?: any;
  contactAddressId?: string;
}

const Conversation: React.FC<ConversationProps> = ({
  subject,
  lastMessage,
  date,
  style,
  onClick,
  authUser,
  customer,
  isNew,
  isArchived,
  onArchive,
  onChangeContactAddress,
  contactAddressId,
}) => {
  const contactAddresses = useSelector(selectContactAddresses);
  const address = contactAddresses.find((a) => a._id === contactAddressId);

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectContactAddress = (addressId) => {
    setAnchorEl(null);

    if (onChangeContactAddress && addressId !== contactAddressId) {
      onChangeContactAddress(addressId);
    }
  };

  return (
    <ConversationWrapper style={style} onClick={() => onClick()}>
      <Header>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: '400' }}>
          {isNew ? (
            <Badge color="primary" variant="dot" sx={{ marginRight: '8px' }} />
          ) : null}
          {customer?.fullName || ''}

          <div>
            <Typography
              sx={{ fontSize: '0.875rem', fontWeight: '700' }}
              color="text.primary"
            >
              {t('common.subject')}: {subject}
            </Typography>
          </div>
        </Typography>

        <Typography
          sx={{
            fontSize: '0.875rem',
            alignSelf: 'flex-start',
            justifySelf: 'end',
          }}
          color="text.secondary"
        >
          Créée le : {window.dayjs(date).format('D MMM YYYY')}
          {onArchive ? (
            <Tooltip
              title={
                isArchived ? t('messages.unarchive') : t('messages.archive')
              }
            >
              <IconButton
                onClick={() => onArchive()}
                aria-label="Archive"
                sx={{ marginLeft: '8px' }}
              >
                {!isArchived ? <ArchiveIcon /> : <UnarchiveIcon />}
              </IconButton>
            </Tooltip>
          ) : null}
          {authUser ? (
            <>
              <Chip
                label={address?.label}
                onClick={handleClick}
                variant="outlined"
                size="small"
                sx={{ marginLeft: '8px' }}
              />
              <Menu
                id="address-positioned-menu"
                aria-labelledby="address-positioned-button"
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                open={open}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {contactAddresses
                  .filter((a) => a._id !== contactAddressId)
                  .map((address: any) => (
                    <MenuItem
                      sx={{ minWidth: '9em' }}
                      key={address._id}
                      onClick={() => handleSelectContactAddress(address._id)}
                    >
                      {address.label}
                    </MenuItem>
                  ))}
              </Menu>
            </>
          ) : null}
          {!authUser && contactAddressId ? (
            <div style={{ textAlign: 'right' }}>
              <Chip
                label={address?.label}
                variant="outlined"
                size="small"
                sx={{ marginLeft: '8px' }}
              />
            </div>
          ) : null}
        </Typography>
      </Header>

      {lastMessage ? (
        <If condition={lastMessage}>
          <Divider />
          <Message
            key={lastMessage._id}
            isMe={getIsMe(authUser, customer, lastMessage._sender)}
            sender={lastMessage?._sender}
            message={lastMessage.content}
            mode="display"
            messageId={lastMessage._id}
            style={{ boxShadow: 'none' }}
            date={lastMessage.createdAt}
          />
        </If>
      ) : null}
    </ConversationWrapper>
  );
};

const ConversationWrapper = styled.div`
  width: 100%;
  box-shadow: 0px 3px 15px rgba(139, 139, 139, 0.1);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  background: ${themes?.default?.accordionWhiteBg};

  &:hover {
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
`;

export default Conversation;
