import { IColDef } from '../../../../../components/ManagedGrid/types';
import {
  WarehouseActionsCell,
  WarehouseCapacityCell,
  WarehouseInitialsCell,
  WarehouseLocationCell,
  WarehouseNameCell,
  WarehouseOperatingHoursCell,
  WarehouseStatusCell,
} from './components/Cells';

export const CUSTOM_WAREHOUSES_COLUMN_CONFIG: IColDef[] = [
  {
    cellRenderer: WarehouseNameCell,
    minWidth: 200,
    flex: 1,
    lockVisible: true,
    sortField: 'warehouse.heads.name',
    pinned: 'left',
  },
  {
    cellRenderer: WarehouseInitialsCell,
    minWidth: 120,
    flex: 1,
    sortField: 'warehouse.heads.initials',
  },
  {
    cellRenderer: WarehouseLocationCell,
    minWidth: 250,
    flex: 1,
    sortField: 'warehouse.heads.location',
  },
  {
    cellRenderer: WarehouseCapacityCell,
    minWidth: 140,
    flex: 1,
    sortField: 'warehouse.heads.capacity',
  },
  {
    minWidth: 120,
    flex: 1,
    sortField: 'cache._manager.name',
  },
  {
    minWidth: 120,
    flex: 1,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    sortField: 'warehouse.heads.contact',
    lockVisible: true,
  },
  {
    cellRenderer: WarehouseOperatingHoursCell,
    minWidth: 150,
    sortField: 'warehouse.heads.hours',
    flex: 1,
  },
  {
    cellRenderer: WarehouseStatusCell,
    cellStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    sortField: 'warehouse.heads.status',
    minWidth: 70,
    flex: 1,
  },
  {
    cellRenderer: WarehouseActionsCell,
    width: 50,
    resizable: false,
    lockPinned: true,
    lockPosition: 'right',
  },
];
