import React from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { useUpdateRoundtripMappingMutation } from '../../../../../../common/services/roundtripMappings';
import { useManagedContext } from '../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { IRoundtripMappingsContext } from '../../index';

function DriverAutoComplete({
  field,
  warehouseId,
  roundtrip,
  index,
  disabled,
}) {
  console.log('dsndsjnsjddnsj',field);
  const { filteredDrivers, updateDataWithFunction, roundtripMappings } =
    useManagedContext<IRoundtripMappingsContext>('RoundtripMappingsContext');
  const [updateRoundtripMappings] = useUpdateRoundtripMappingMutation();
  const handleBlur = async (selection) => {
    try {
      if (roundtrip.driver !== selection?.id && roundtrip.roundtripCode) {
        updateDataWithFunction((prev) => {
          prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
            if (warehouse.id === warehouseId) warehouse.loading = true;
            return warehouse;
          });
        });
        updateDataWithFunction((prev) => {
          const indexe = roundtripMappings.findIndex(
            (item) => item?.id === warehouseId,
          );
          prev.roundtripMappings[indexe].roundtrips[index].driver =
            selection?.id || null;
        });
        await updateRoundtripMappings({
          id: roundtrip.id,
          body: {
            _roundtripGescom: roundtrip.roundtripCode,
            _driver: selection?.id || null,
            _deck: warehouseId,
          },
        }).then(() => {
          updateDataWithFunction((prev) => {
            prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
              if (warehouse.id === warehouseId) warehouse.loading = false;
              return warehouse;
            });
          });
        });
      } else {
        updateDataWithFunction((prev) => {
          const indexe = roundtripMappings.findIndex(
            (item) => item?.id === warehouseId,
          );
          prev.roundtripMappings[indexe].roundtrips[index].driver =
            selection?.id || null;
        });
      }
    } catch (e) {
      console.error('Error updating roundtrip mappings:', e);
      updateDataWithFunction((prev) => {
        prev.roundtripMappings = prev.roundtripMappings.map((warehouse) => {
          if (warehouse.id === warehouseId) warehouse.loading = false;
          return warehouse;
        });
      });
    }
  };
  return (
    <Stack>
      <Autocomplete
        disabled={disabled}
        // open={open}
        // onOpen={() => {
        //   setOpen(true);
        //   getAvailableInternalCodes('671f2beb9485bed5a39f5014');
        // }}
        // onClose={() => setOpen(false)}
        // loading={isLoading}
        {...field}
        options={filteredDrivers || []}
        value={
          field?.value || {
            fullName: '',
            id: '',
          }
        }
        getOptionLabel={(option: { fullName: string; id: string }) =>
          option.fullName
        }
        onChange={(event, newValue) => {
          event.stopPropagation();
          field.onChange(newValue);
          handleBlur(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Drivers"
            margin="normal"
            size="small"
            variant="outlined"
            value={field.value?.id || ''}
            label="Drivers"
            onClick={(e) => e.stopPropagation()}
            // onBlur={handleBlur}
            InputProps={{
              ...params.InputProps,
              // endAdornment: (
              //   <>
              //     {isLoading ? (
              //       <CircularProgress color="inherit" size={20} />
              //     ) : null}
              //     {params.InputProps.endAdornment}
              //   </>
              // ),
            }}
          />
        )}
      />
    </Stack>
  );
}
export default DriverAutoComplete;
